import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import AdminHome from './components/admin/AdminHome';
import AdminLogin from './components/admin/AdminLogin';
import AdminSetUpPassword from './components/admin/AdminSetUpPassword';
import SetUpScratcherTickets from './components/admin/SetUpScratcherTickets';
import SetUpScratcherGame from './components/admin/SetUpScratcherGame';
import SetUpTeamVariables from './components/admin/SetUpTeamVariables';
import Tutorials from './components/admin/Tutorials';
import Loading from './components/utils/Loading';
import SetUpRulesAndRegs from './components/admin/SetUpRulesAndRegs';
import SetUpTicketEmail from './components/admin/SetUpTicketEmail';
import SetUpFanAuth from './components/admin/SetUpFanAuth'
import ErrorPage from './components/utils/ErrorPage';
import AuthService from './components/utils/AuthService';

function AuthenticatedAdminRoute({component: Component, isAdmin, authenticated, ...rest}) {
    var teamLocation = window.location.pathname.split('/')[1] || ""
    var pathnameWithTeam = '/' + teamLocation + '/adminlogin'
    return (
        <Route
            {...rest}
            render={(props) => authenticated === true && isAdmin
                ? <Component {...props} {...rest} /> : <Redirect to={{pathname: pathnameWithTeam, state: {from: props.location}}} /> } />
    )
}

class App extends Component {
    constructor() {
        super();
        this.setTheme = this.setTheme.bind(this);
        this.checkIfAuthenticated = this.checkIfAuthenticated.bind(this);
        const teamLocation = window.location.pathname.split('/')[1] || "";
        if (teamLocation === "etsu"){
            window.open("https://etsuscratchadmin.web.app/adminlogin", "_self");
        } else if (teamLocation === "ohio"){
            window.open("https://ohiouscratchadmin.web.app/adminlogin", "_self");
        } else if (teamLocation === "fhsu"){
            window.open("https://fhsuscratchadmin.web.app/adminlogin", "_self");
        } else if (teamLocation === "oilers"){
            window.open("https://edmontonoilersscratchadmin.web.app/adminlogin", "_self");
        } else if (teamLocation === "csurams"){
            window.open("https://csuramsscratchadmin.web.app/adminlogin", "_self");
        } else if (teamLocation === "columbusclippers"){
            window.open("https://colclippersscratchadmin.web.app/adminlogin", "_self");
        } else if (teamLocation === "wolfsburgvfl"){
            window.open("https://wolfsburgscratchadmin.web.app/adminlogin", "_self");
        } else if(teamLocation === "gcu"){
            window.open("https://gcuscratchadmin.web.app/adminlogin", "_self");
        } else if(teamLocation === "tbrowdies"){
            window.open("https://rowdiesscratchadmin.web.app/adminlogin", "_self");
        } else if(teamLocation === "vw12"){
            window.open("https://stonybrookscratchadmin.web.app/adminlogin", "_self");
        }
        localStorage.setItem('team'+teamLocation, teamLocation);
        this.Auth = new AuthService();
        this.state = {
            admin: false,
            authenticated: false,
            currentUser: null,
            loading: true
        }
    }

    setTheme(){
      const teamLocation = window.location.pathname.split('/')[1] || "";
      const currentUrl = 'https://scratchers.sqwadhq.com/' + localStorage.getItem('team'+teamLocation);
      const tenantObject = {'tenantUrl': currentUrl}
      //set up theme:
      //primary color, secondary color, Top left image, Front Logo image, background image, Scratcher Image, buy tickets link
      this.Auth.fetch(`${this.Auth.domain}services/app/game/GetTenantInfoFromTenantUrl`, {
        method: 'POST',
        headers: {
	         'Content-Type': 'application/json',
         },
        body: JSON.stringify(tenantObject),
      }).then(res => {
        const tenantVariables = res.entity;
        if(tenantVariables){
            localStorage.setItem('rules-text'+teamLocation, tenantVariables.rulesAndRegsText)
            localStorage.setItem('rules-link'+teamLocation, tenantVariables.rulesAndRegsLink)
            localStorage.setItem('scratcher-top-image'+teamLocation, tenantVariables.scratcherImage)
            localStorage.setItem('frontLogo'+teamLocation, tenantVariables.frontLogoImage)
            localStorage.setItem('primaryColor'+teamLocation, tenantVariables.primaryColor)
            localStorage.setItem('secondaryColor'+teamLocation, tenantVariables.secondaryColor)
            localStorage.setItem('ticketsLink'+teamLocation, tenantVariables.buyTicketURL)
            localStorage.setItem('topLeftLogo'+teamLocation, tenantVariables.topLeftImage)
            localStorage.setItem('TenantId'+teamLocation, tenantVariables.id)
            localStorage.setItem('backgroundImage'+teamLocation, tenantVariables.backgroundImage)
            localStorage.setItem('collectUserName'+teamLocation, tenantVariables.collectName)
            localStorage.setItem('showRewardsOutMessage'+teamLocation, tenantVariables.showRewardsOutMessage)
            localStorage.setItem('onePrizeInTimePeriod'+teamLocation, tenantVariables.onePrizeInTimePeriod)
            localStorage.setItem('collectEmail'+teamLocation, tenantVariables.collectEmail)
            localStorage.setItem('authRequired'+teamLocation, tenantVariables.authRequired)
            localStorage.setItem('anonymousLogin'+teamLocation, tenantVariables.anonymousLogin)
            localStorage.setItem('ageToVerify'+teamLocation, tenantVariables.ageToVerify)
            localStorage.setItem('collectAge'+teamLocation, tenantVariables.ageBlock)
            localStorage.setItem('optInText'+teamLocation, tenantVariables.promoText)
            localStorage.setItem('collectZipCode'+teamLocation, tenantVariables.collectZipCode)
            localStorage.setItem('blockedZipCodes'+teamLocation, tenantVariables.allowedZips)
            localStorage.setItem('collectOptIn'+teamLocation, tenantVariables.promotionCollection)
            localStorage.setItem('latitude'+teamLocation, tenantVariables.latitude)
            localStorage.setItem('longitude'+teamLocation, tenantVariables.longitude)
            localStorage.setItem('acceptableDistance'+teamLocation, tenantVariables.acceptableDistance)
            localStorage.setItem('collectDistance'+teamLocation, tenantVariables.collectDistance)
            localStorage.setItem('formattedAddress'+teamLocation, tenantVariables.formattedAddress)
            localStorage.setItem('locationPermissionsHeader'+teamLocation, tenantVariables.locationPermissionsHeader)
            localStorage.setItem('locationPermissionsBody'+teamLocation, tenantVariables.locationPermissionsBody)
            localStorage.setItem('notAcceptableLocationHeader'+teamLocation, tenantVariables.notAcceptableLocationHeader)
            localStorage.setItem('notAcceptableLocationMessage'+teamLocation, tenantVariables.notAcceptableLocationMessage)
            localStorage.setItem('canPlayOutside'+teamLocation, tenantVariables.canPlayOutside)
            localStorage.setItem('onePrizeStartTime'+teamLocation, tenantVariables.onePrizeStartTime)
            localStorage.setItem('onePrizeEndTime'+teamLocation, tenantVariables.onePrizeEndTime)
            localStorage.setItem('locationCheckFailedCanPlay'+teamLocation, tenantVariables.locationCheckFailedCanPlay)
            localStorage.setItem('blockSanitizedEmails'+teamLocation, tenantVariables.blockSanitizedEmails)
            localStorage.setItem('isMlbTeam'+teamLocation, tenantVariables.isMlbTeam)
            localStorage.setItem('expiresAtHours'+teamLocation, tenantVariables.expiresAtHours)
            localStorage.setItem('expiresAtDateTime'+teamLocation, tenantVariables.expiresAtDateTime)
          this.setState({
            tenantVariables: tenantVariables
          })
        }
      }).catch(err =>{
        console.log(err)
      })
    }

    checkIfAuthenticated() {
      if(this.Auth.loggedIn() && this.Auth.isAdmin()){
        const user = this.Auth.getProfile();
        const isAdmin = this.Auth.isAdmin();
        this.setState({
            authenticated: true,
            admin: isAdmin,
            currentUser: user,
            loading: false
        })
      } else {
        this.setState({
            authenticated: false,
            admin: false,
            currentUser: null,
            loading: false
        })
      }
    }

    componentDidMount() {
      this.setTheme()
      this.checkIfAuthenticated();
      if(window.innerWidth <= 767){
        document.addEventListener('mousedown', this.handleClick);
      }
    }

    componentWillUnmount() {
      if(window.innerWidth <= 767){
        document.removeEventListener('mousedown', this.handleClick);
      }
    }

    handleClick = (e) => {
        const sideBar = document.getElementsByClassName("admin-sidebar")[0];
        if(sideBar && !sideBar.contains(e.target)){
            sideBar.style.display = "none"
        }
    }

  render() {
    const teamLocation = window.location.pathname.split('/')[1] || "";
    const team = localStorage.getItem('team'+teamLocation);
    if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading}/>
        )
    }
    return (
      <div style={{ margin: "0 auto" }}>
                <BrowserRouter>
                    <div className="main-content">
                        <div className="workspace">
                          <Switch>

                            <Route
                                exact
                                path="/"
                                render={(props) => {
                                  return <ErrorPage {...props} />
                                }}
                                />

                          <Route
                              exact
                              path={`/${team}/setpassword`}
                              user={this.state.currentUser}
                              render={(props) => {
                                  return <AdminSetUpPassword checkIfAuthenticated={this.checkIfAuthenticated} {...props} />
                              }} />

                            <Route
                              exact
                              path={`/${team}/adminlogin`}
                              user={this.state.currentUser}
                              tenantVariables={this.state.tenantVariables}
                              render={(props) => {
                                return <AdminLogin checkIfAuthenticated={this.checkIfAuthenticated} {...props} />
                              }} />

                            <AuthenticatedAdminRoute
                                exact
                                path={`/${team}/adminHome`}
                                isAdmin={this.Auth.isAdmin()}
                                authenticated={this.state.authenticated}
                                tenantVariables={this.state.tenantVariables}
                                component={AdminHome} />

                            <AuthenticatedAdminRoute
                                exact
                                path={`/${team}/setupscratchergame`}
                                isAdmin={this.Auth.isAdmin()}
                                authenticated={this.state.authenticated}
                                currentUser={this.state.currentUser}
                                tenantVariables={this.state.tenantVariables}
                                component={SetUpScratcherGame} />

                            <AuthenticatedAdminRoute
                                exact
                                path={`/${team}/setupscratchertickets`}
                                isAdmin={this.Auth.isAdmin()}
                                authenticated={this.state.authenticated}
                                tenantVariables={this.state.tenantVariables}
                                component={SetUpScratcherTickets} />

                            <AuthenticatedAdminRoute
                                exact
                                path={`/${team}/setupteamvariables`}
                                isAdmin={this.Auth.isAdmin()}
                                authenticated={this.state.authenticated}
                                tenantVariables={this.state.tenantVariables}
                                component={SetUpTeamVariables} />

                            <AuthenticatedAdminRoute
                                exact
                                path={`/${team}/setuprulesandregs`}
                                isAdmin={this.Auth.isAdmin()}
                                authenticated={this.state.authenticated}
                                tenantVariables={this.state.tenantVariables}
                                component={SetUpRulesAndRegs} />

                            <AuthenticatedAdminRoute
                                exact
                                path={`/${team}/setupticketemail`}
                                isAdmin={this.Auth.isAdmin()}
                                authenticated={this.state.authenticated}
                                tenantVariables={this.state.tenantVariables}
                                component={SetUpTicketEmail} />

                            <AuthenticatedAdminRoute
                                exact
                                path={`/${team}/setupauthentication`}
                                isAdmin={this.Auth.isAdmin()}
                                authenticated={this.state.authenticated}
                                tenantVariables={this.state.tenantVariables}
                                component={SetUpFanAuth} />

                            <AuthenticatedAdminRoute
                              exact
                              path={`/${team}/tutorials`}
                              isAdmin={this.Auth.isAdmin()}
                              authenticated={this.state.authenticated}
                              tenantVariables={this.state.tenantVariables}
                              component={Tutorials} />

                            <Redirect to={`/${team}/adminlogin`}/>
                          </Switch>
                        </div>
                    </div>
                </BrowserRouter>
            </div>
    );
  }
}

export default App;
