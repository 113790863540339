import React, {Component} from 'react';
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import AuthService from '../utils/AuthService';
import {cleanCSVText, sanitizeEmail} from '../utils/HelpfulFunction';
import DatePicker from '../utils/DatePicker';

class AdminHome extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.state = {
            games: 0,
            users: 0,
            loading: true,
            monthCardsScratched: 0,
            monthPlayers: 0,
            monthPrizesWon: 0,
            cardsScratched: 0,
            players: 0,
            gameUsers: 0,
            prizesWon: 0,
            uniqueGameUsers: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.searchData();
        this.setState({
            loading: false
        })
        // this.Auth.fetch(this.Auth.domain + 'services/app/Report/Tenant/GetAllGames', {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     }
        // }).then(async res => {
        //     let gameCsvData = "gameId,name,lastSignIn,signUpTime,sanitizedEmail,uid,requestCardsTime,email,prizeWonId,rewardName\n"
        //     for(const i in res.entity){
        //         let game = res.entity[i];
        //         const gameData = await this.Auth.fetch(this.Auth.domain + `services/app/Game/GetPrizesAndGameUsers/${game.gameId}/0/100000`, {method: 'GET', headers: {'Content-Type': 'application/json'}});
        //         if(!gameData) continue;
        //         const gameUsers = gameData.entity;
        //         for(const t in gameUsers){
        //             const row = gameUsers[t];
        //             const gameId = row.gameId;
        //             const user = row.user;
        //             const rewardTemplate = row.rewardTemplate;
        //             const tenantUser = row.tenantUser;
        //             const email = user.email;
        //             const userId = btoa(email);
        //             const sanitizedEmail = sanitizeEmail(email)
        //             let firstName = user.firstName || "";
        //             let lastName = user.lastName || "";
        //             let timeWon = row.issuedDateTime || "";
        //             let createdTime = tenantUser.createdTime || ""
        //             let rewardName = rewardTemplate.rewardName || "";
        //             let prizeWonId = row.rewardId || "";
        //             if(firstName === 'null'){
        //                 firstName = ""
        //             }
        //             if(lastName === 'null'){
        //                 lastName = "";
        //             }
        //             let name = firstName + " " + lastName
        //             if(name === " "){
        //                 name = ""
        //             }
        //             if(timeWon){
        //                 timeWon = new Date(timeWon).getTime()
        //             }
        //             // if(createdTime){
        //             //     createdTime = new Date(createdTime).getTime()
        //             // }
        //             gameCsvData += gameId+","+name+",0,"+createdTime+","+sanitizedEmail+','+userId+','+timeWon+','+email+','+prizeWonId+','+rewardName+'\n';
        //         }
        //     }
        //     const hiddenElement = document.createElement('a');
        //     hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(gameCsvData);
        //     hiddenElement.target = '_blank';
        //     hiddenElement.download = "another.csv";
        //     hiddenElement.click();
        // })
    }

    handleChange (evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value
        });
    }

    convertDateTimeToHyphonString(date){
        if(!date){
            return date
        }
        // return date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
        return date.toISOString();
    }

    searchData(uniqueGameUsers){
        const currentUniqueUsersToggleState = !(uniqueGameUsers === null || uniqueGameUsers === false);
        let searchStart = this.convertDateTimeToHyphonString(this.state.searchStart) || "";
        let endDate = new Date(this.state.searchEnd);
        if(this.state.searchEnd){
            endDate = new Date(endDate.setDate(this.state.searchEnd.getDate() + 1));
        } else {
            endDate = this.state.searchEnd;
        }
        const searchEnd = this.convertDateTimeToHyphonString(endDate) || "";
        let formData = new FormData();
        const teamLocation = window.location.pathname.split('/')[1] || "";
        formData.append("SearchStart", searchStart)
        formData.append("SearchEnd", searchEnd);
        formData.append("UniqueGameUsers", currentUniqueUsersToggleState)
        formData.append("TenantId", localStorage.getItem('TenantId'+teamLocation));
        const domainRoot = this.Auth.domain;
        const vm = this;
        this.setState({loading:true})
        this.Auth.fetch(domainRoot + 'services/app/Report/Tenant/SearchGames?SearchStart='+searchStart+'&'+'SearchEnd='+searchEnd+'&UniqueGameUsers='+currentUniqueUsersToggleState+'&TenantId='+localStorage.getItem('TenantId'+teamLocation), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: formData,
        }).then(res => {
            if(!res){
                console.log("Error")
            } else {
                vm.setState({
                    games: res.entity.numberOfGames,
                    users: res.entity.numberOfSignUps,
                    gameUsers: res.entity.numberOfGamePlayers,
                    prizesWon: res.entity.numberOfRewards,
                    lastStartSearch:searchStart,
                    lastEndSearch: this.state.searchEnd,
                    uniqueGameUsers:res.entity.uniqueGameUsers
                })
            }
            vm.setState({
                loading: false
            })
        })
    }

    downloadData(column_string, data, title){
        let csv = "Date Downloaded,"+ new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0]+'\n';
        const searchStart = this.state.lastStartSearch || "";
        const searchEnd = this.state.lastEndSearch || "";
        if(searchStart){
            csv += "From,"+searchStart+'\n'
        }
        if(searchEnd){
            csv += "To,"+searchEnd+'\n'
        }
        csv += column_string+'\n';
        const teamLocation = window.location.pathname.split('/')[1] || "";
        for(let i in data){
            const item = data[i];
            let count = 0
            for(let t in item){
                const objectKeys = Object.keys(item).length;
                const rowContent = cleanCSVText(item[t]);
                csv += rowContent;
                if(objectKeys - 1 === count){
                    csv += "\n";
                } else {
                    csv+= ",";
                }
                count++;
            }
        }
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = title + teamLocation +".csv";
        hiddenElement.click();
        this.setState({
            loading:false
        });
    }

    getUserData(){
        const isMlbTeam = this.props.tenantVariables && this.props.tenantVariables.isMlbTeam;
        const searchStart = this.state.lastStartSearch || "";
        let searchEnd = this.state.lastEndSearch || "";
        let endDate = new Date(searchEnd);
        if(searchEnd){
            endDate = new Date(endDate.setDate(searchEnd.getDate() + 1));
        } else {
            endDate = "";
        }
        searchEnd = this.convertDateTimeToHyphonString(endDate) || "";
        let formData = new FormData();
        const teamLocation = window.location.pathname.split('/')[1] || "";
        const domainRoot = this.Auth.domain;
        const vm = this;
        this.setState({loading:true})
        this.Auth.fetch(domainRoot + 'services/app/Report/Tenant/GetTenantUsersInTimePeriodCount?SearchStart='+searchStart+'&'+'SearchEnd='+searchEnd+'&TenantId='+localStorage.getItem('TenantId'+teamLocation), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: formData,
        }).then(res => {
            if(!res){
                console.log("Error")
            } else {
                const cleanedUsers = []
                for(const user in res.users){
                    let new_user = {}
                    let first_part = res.users[user].email.split("@")[0]
                    let isnum = /^\d+$/.test(first_part);
                    if(isnum && res.users[user].email.includes('sqwadapp.co')){
                        new_user['Phone Number'] = first_part
                        new_user['Email'] = ""
                    } else if(!isnum && res.users[user].email.includes('sqwadapp.co') && isMlbTeam) {
                        new_user['Email'] = first_part
                    } else {
                        new_user['Email'] = res.users[user].email
                        new_user['Phone Number'] = "N/A"
                    }
                    if(res.users[user].phoneNumber){
                        new_user['Phone Number'] = res.users[user].phoneNumber;
                    }
                    if(!isMlbTeam) {
                        new_user['First Name'] = res.users[user].firstName
                        new_user['Last Name'] = res.users[user].lastName
                        new_user['Zip Code'] = res.users[user].zipCode
                        new_user['Opt-In'] = res.users[user].optInPromotion
                        new_user['Opt-In Two'] = res.users[user].optInPromotionTwo || false
                        new_user['Birthday'] = res.users[user].birthday
                        new_user['Signed Up'] = res.users[user].createdTime
                    }
                    cleanedUsers.push(new_user)
                }
                if(isMlbTeam){
                    this.downloadData("UID", cleanedUsers, "scratchandwin_users_")
                } else {
                    this.downloadData("Email,Phone Number,First Name, Last Name, Zip Code, Opt-In, Opt-In Two, Birthday, Signed Up", cleanedUsers, "scratchandwin_users_")
                }
            }
            vm.setState({
                loading: false
            })
        })
    }

    getGameUserData(){
        const isMlbTeam = this.props.tenantVariables && this.props.tenantVariables.isMlbTeam;
        const currentUniqueUsersToggleState = this.state.uniqueGameUsers;
        const searchStart = this.state.lastStartSearch || "";
        let searchEnd = this.state.lastEndSearch || "";
        let endDate = new Date(searchEnd);
        if(searchEnd){
            endDate = new Date(endDate.setDate(searchEnd.getDate() + 1));
        } else {
            endDate = "";
        }
        searchEnd = this.convertDateTimeToHyphonString(endDate) || "";
        let formData = new FormData();
        const teamLocation = window.location.pathname.split('/')[1] || "";
        const domainRoot = this.Auth.domain;
        const vm = this;
        this.setState({loading:true})
        this.Auth.fetch(domainRoot + 'services/app/Report/Tenant/GetGameUsersInTimePeriod?SearchStart='+searchStart+'&SearchEnd='+searchEnd+'&UniqueGameUsers='+currentUniqueUsersToggleState+'&TenantId='+localStorage.getItem('TenantId'+teamLocation), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: formData,
        }).then(res => {
            if(!res){
                console.log("Error")
            } else {
                const cleanedUsers = []
                for(const user in res.users){
                    let new_user = {}
                    let first_part = res.users[user].email.split("@")[0]
                    let isnum = /^\d+$/.test(first_part);
                    if(isnum && res.users[user].email.includes('sqwadapp.co')){
                        new_user['Phone Number'] = first_part
                        new_user['Email'] = ""
                    } else if(!isnum && res.users[user].email.includes('sqwadapp.co') && isMlbTeam) {
                        new_user['Email'] = first_part
                    } else {
                        new_user['Email'] = res.users[user].email
                        new_user['Phone Number'] = "N/A"
                    }
                    if(res.users[user].phoneNumber){
                        new_user['Phone Number'] = res.users[user].phoneNumber;
                    }
                    if(!isMlbTeam){
                        new_user['First Name'] = res.users[user].firstName
                        new_user['Last Name'] = res.users[user].lastName
                        new_user['Zip Code'] = res.users[user].zipCode
                        new_user['Opt-In'] = res.users[user].optInPromotion
                        new_user['Opt-In Two'] = res.users[user].optInPromotionTwo || false
                        new_user['Birthday'] = res.users[user].birthday
                        new_user['Signed Up'] = res.users[user].createdTime
                    }
                    cleanedUsers.push(new_user)
                }
                if(isMlbTeam){
                    this.downloadData("UID", cleanedUsers, "scratchandwin_game_users_")
                } else {
                    this.downloadData("Email,Phone Number,First Name, Last Name, Zip Code, Opt-In, Opt-In Two, Birthday, Signed Up", cleanedUsers, "scratchandwin_game_users_")
                }
            }
            vm.setState({
                loading: false
            })
        })
    }

    toStandardTime(value) {
        if (value !== null && value !== undefined){ //If value is passed in
            if(value.indexOf('AM') > -1 || value.indexOf('PM') > -1){ //If time is already in standard time then don't format.
                return value;
            }
            else {
                if(value.length == 8){ //If value is the expected length for military time then process to standard time.
                    var hour = value.substring ( 0,2 ); //Extract hour
                    var minutes = value.substring ( 3,5 ); //Extract minutes
                    var identifier = 'AM'; //Initialize AM PM identifier

                    if(hour == 12){ //If hour is 12 then should set AM PM identifier to PM
                        identifier = 'PM';
                    }
                    if(hour == 0){ //If hour is 0 then set to 12 for standard time 12 AM
                        hour=12;
                    }
                    if(hour > 12){ //If hour is greater than 12 then convert to standard 12 hour format and set the AM PM identifier to PM
                        hour = hour - 12;
                        identifier='PM';
                    }
                    return hour + ':' + minutes + ' ' + identifier; //Return the constructed standard time
                }
                else { //If value is not the expected length than just return the value as is
                    return value;
                }
            }
        }
    }

    getRewardsData(){
        const isMlbTeam = this.props.tenantVariables && this.props.tenantVariables.isMlbTeam;
        const searchStart = this.state.lastStartSearch || "";
        let searchEnd = this.state.lastEndSearch || "";
        let endDate = new Date(searchEnd);
        if(searchEnd){
            endDate = new Date(endDate.setDate(searchEnd.getDate() + 1));
        } else {
            endDate = "";
        }
        searchEnd = this.convertDateTimeToHyphonString(endDate) || "";
        let formData = new FormData();
        const teamLocation = window.location.pathname.split('/')[1] || "";
        const domainRoot = this.Auth.domain;
        const vm = this;
        this.setState({loading:true})
        this.Auth.fetch(domainRoot + 'services/app/Report/Tenant/GetPrizesInTimePeriod?SearchStart='+searchStart+'&'+'SearchEnd='+searchEnd+'&TenantId='+localStorage.getItem('TenantId'+teamLocation), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: formData,
        }).then(res => {
            if(!res){
                console.log("Error")
            } else {
                const cleanRewards = [];
                for (const prizeIndex in res) {
                    const reward = {};
                    const uniquePrize = res[prizeIndex];
                    let winningEmailAddress = uniquePrize.winningEmailAddress || "No Email Given Yet";
                    let winningEmailSent = "Not Sent";
                    let timeStamp = uniquePrize.issuedDateTime;
                    let givenOut = "No";
                    let number = "";
                    let firstName = "";
                    let lastName = "";
                    if (uniquePrize.userId) {
                        givenOut = "Yes";
                        var user = uniquePrize.user;
                        number = user.userName.split('@')[0];
                        firstName = user.firstName
                        lastName = user.lastName
                        var isnum = /^\d+$/.test(number);
                        if (!isnum) {
                            number = "N/A"
                        }
                    }
                    if (uniquePrize.winningEmailSent) {
                        winningEmailSent = "Sent"
                    }
                    if (givenOut === "Yes" && winningEmailSent === "Not Sent" && winningEmailAddress === "No Email Given Yet" && user && user.email.includes('@') && user.email.indexOf('sqwadapp.co') === -1) {
                        winningEmailAddress = user.email
                    } else if(isMlbTeam) {
                        winningEmailAddress = user.userName.split('@')[0];
                    }
                    reward['rewardName'] = uniquePrize.rewardTemplate.rewardName;
                    reward['givenOut'] = givenOut;
                    reward['redeemCode'] = uniquePrize.redeemCode || "";
                    reward['redeemPin'] = uniquePrize.redeemPin || "";
                    reward['winningEmailSent'] = winningEmailSent;
                    reward['winningEmailAddress'] = winningEmailAddress;
                    if(!isMlbTeam){
                        reward['number'] = number;
                    }
                    reward['timeStamp'] = timeStamp;
                    if(!isMlbTeam){
                        reward['firstName'] = firstName || "";
                        reward['lastName'] = lastName || "";
                    }
                    reward['isRedeemed'] = uniquePrize.isRedeemed;
                    cleanRewards.push(reward)
                }
                if(isMlbTeam){
                    this.downloadData("Reward Name,Given Out,Redeem Code,Redeem Pin,Winning Message Sent,UID,Time Won,Has been Redeemed", cleanRewards, "scratchandwin_prizes_")
                } else {
                    this.downloadData("Reward Name,Given Out,Redeem Code,Redeem Pin,Winning Email Sent,Email Address,Phone Number,Time Won,First Name,Last Name,Has been Redeemed", cleanRewards, "scratchandwin_prizes_")
                }
            }
            vm.setState({
                loading: false
            })
        })
    }

    render() {
        const isMlbTeam = this.props.tenantVariables && this.props.tenantVariables.isMlbTeam;
        const totalGames = this.state.games;
        const totalUsers = this.state.users;
        const gameUsers = this.state.gameUsers;
        const uniqueGameUsers = this.state.uniqueGameUsers;
        const prizesWon = this.state.prizesWon;
        const teamLocation = window.location.pathname.split('/')[1] || "";
        let scratcherLink = 'https://testcratchers.firebaseapp.com/' + localStorage.getItem('team'+teamLocation)
        if(process.env.NODE_ENV === "production" && window.location.hostname === 'games-admin.sqwadhq.com'){
            scratcherLink = 'https://scratchandwin.sqwadhq.com/' + localStorage.getItem('team'+teamLocation)
        } else if(process.env.NODE_ENV === "production" && window.location.hostname === 'games-admin.sqwadapp.co'){
            scratcherLink = 'https://scratchandwin.sqwadhq.com/' + localStorage.getItem('team'+teamLocation)
        }
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
                <SideMenu tenantVariables={this.props.tenantVariables}/>
                <TopMenu/>
                <div className="admin-main-panel">
                    <p className="admin-header-text" style={{marginTop:'10px'}}>Dashboard</p>
                    <form className="form-horizontal">
                        <div className="row" style={{marginLeft: 20}}>
                            <div className="form-group" style={{marginRight: 10}}>
                                <DatePicker isClearable selected={this.state.searchStart} onChange={date => this.setState({searchStart: date})}/>
                            </div>

                            <div className="form-group" style={{marginRight: 10}}>
                                <DatePicker isClearable selected={this.state.searchEnd} onChange={date => this.setState({searchEnd: date})}/>
                            </div>
                            <div>
                                <button className="btn btn-primary btn-admin" type="button" onClick={()=>this.searchData()}>Search</button>
                            </div>
                        </div>
                    </form>
                    <div className="admin-grid-container four-columns">
                        <div className="card card-styles text-xs-center">
                            <div className="card-body">
                                <blockquote className="card-bodyquote">
                                    <p>Games Created</p>
                                    <footer className="value-text">{totalGames}</footer>
                                </blockquote>
                            </div>
                        </div>
                        <div className="card card-styles text-xs-center">
                            <div className="card-body">
                                <blockquote className="card-bodyquote">
                                    <p>Accounts Created</p>
                                    <footer className="value-text">{totalUsers}</footer>
                                    {totalUsers > 0 &&
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button onClick={() => this.getUserData()}
                                                        className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                                                    <span className="fa fa-arrow-circle-down"/> Download
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </blockquote>
                            </div>
                        </div>
                        <div className="card card-styles text-xs-center">
                            <div className="card-body">
                                <blockquote className="card-bodyquote">
                                    <p>Game Users</p>
                                    <footer className="value-text">{gameUsers}</footer>
                                    {gameUsers > 0 &&
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button onClick={() => this.getGameUserData()} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                                                <span className="fa fa-arrow-circle-down"/> Download
                                            </button>
                                        </div>
                                    </div>
                                    }
                                    <div style={{marginTop:10}}>
                                        <input type="checkbox" onClick={()=> this.searchData(false)} checked={!uniqueGameUsers}/> All <input onClick={()=> this.searchData(true)} type="checkbox" checked={uniqueGameUsers}/> Unique
                                    </div>
                                </blockquote>
                            </div>
                        </div>
                        <div className="card card-styles text-xs-center">
                            <div className="card-body">
                                <blockquote className="card-bodyquote">
                                    <p>Prizes Won</p>
                                    <footer className="value-text">{prizesWon}</footer>
                                    {prizesWon > 0 &&
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button onClick={() => this.getRewardsData()}
                                                    className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                                                <span className="fa fa-arrow-circle-down"/> Download
                                            </button>
                                        </div>
                                    </div>
                                    }
                                </blockquote>
                            </div>
                        </div>
                    </div>
                    {!isMlbTeam &&
                        <div className="row" style={{width:'100%'}}>
                            <div className="col-md-12">
                                <p className="admin-header-text" style={{marginTop:'10px'}}>My Scratch & Win link: <br/><span className="linkText">{scratcherLink}</span></p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default AdminHome
