import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import AuthService from '../utils/AuthService';
import { Prompt } from "react-router-dom";
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import RichTextEditor from 'html-verify-react-rte';
import swal from 'sweetalert2';

class SetUpRulesAndRegs extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.state = {
            rulesAndRegsText: RichTextEditor.createEmptyValue(),
            supportText: RichTextEditor.createEmptyValue(),
            rulesAndRegsLink: "",
            updateHappened: false,
            loading: true
        };
        this.getRulesAndRegulations = this.getRulesAndRegulations.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      this.getRulesAndRegulations()
    }

    updateRulesAndRegulations() {
        let {rulesAndRegsText, rulesAndRegsLink, supportHeader, supportText} = this.state;
        rulesAndRegsText = rulesAndRegsText.toString('html');
        supportText = supportText.toString('html');
        const updateRulesObject = {"text": rulesAndRegsText, "link": rulesAndRegsLink, "supportHeader": supportHeader, "supportText": supportText }
        this.setState({loading:true})
        this.Auth.fetch(this.Auth.domain + 'services/app/Tenant/UpdateRulesAndRegulations', {
            method: 'POST',
            headers: {
                 'Content-Type': 'application/json',
             },
            body: JSON.stringify(updateRulesObject),
        }).then( res => {
            this.setState({loading:false})
            if(res){
              this.setState({updateHappened:false})
              swal({
                  title: 'Rules and Regs Updated!',
                  type: 'success',
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: true,
                  timer: 10000
                })
            } else {
              swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
            }
        })
    }

    handleChange (evt) {
      this.setState({
        [evt.target.name]: evt.target.value,
        updateHappened: true
      });
    }

    onChangeRichText(name, text){
        this.setState({
            updateHappened: true,
            [name]: text
        })
        // if (this.props.onChange) {
        //     // Send the changes up to the parent component as an HTML string.
        //     // This is here to demonstrate using `.toString()` but in a real app it
        //     // would be better to avoid generating a string on each change.
        //     this.props.onChange(
        //         rulesAndRegsText.toString('html')
        //     );
        // }
    }

    getRulesAndRegulations(){
      const vm = this;
      this.Auth.fetch(this.Auth.domain + 'services/app/Tenant/GetRulesAndRegulations', {
        method: 'GET',
      }).then(res => {
          console.log(res)
        if(res){
            let {text="", link="", supportHeader="", supportText=""} = res;
            supportText = RichTextEditor.createValueFromString(supportText, 'html');
            text = RichTextEditor.createValueFromString(text, 'html');
            vm.setState({
                rulesAndRegsText: text,
                rulesAndRegsLink: link,
                supportHeader: supportHeader,
                supportText: supportText,
                loading:false
            })
        }
      })
    }

    render() {
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        const isMlbTeam = this.props.tenantVariables && this.props.tenantVariables.isMlbTeam;
        if(!isMlbTeam){
            toolbarConfig.display.push('LINK_BUTTONS')
        }
        const teamLocation = window.location.pathname.split('/')[1] || ""
        return (
          <div className="admin-wrapper">
            <Prompt
              when={this.state.updateHappened}
              message="Unsaved changes, are you sure you want to leave?"
            />
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu tenantVariables={this.props.tenantVariables}/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="container-out container-left-rules">
                <div className="admin-form-box">
                  <button className="btn btn-primary btn-lg update-button" onClick={()=>this.updateRulesAndRegulations()} style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                  <span style={{display: this.state.updateHappened ? 'none' : '', paddingLeft:"5px", color:"grey" }}>No Changes</span>
                  <span style={{display: this.state.updateHappened ? '' : 'none', paddingLeft:"5px", color:"red" }}>UNSAVED CHANGES</span>
                  <div className="form-group">
                    <label htmlFor="rulesAndRegsText">{isMlbTeam?"Game Rules":"Info Text"}</label>
                    {isMlbTeam?
                          <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Paste your teams game rules below so fans can see and agree to them</p>
                          :
                          <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This text will appear on the information icon before the fan signs in.  You can put how to sign in here.<br/><strong>Example:</strong> Welcome to {teamLocation} scratchers!  To play, enter your phone number and click Let’s Play to receive your cards.  Gently swipe with your finger to reveal your prizes.  For more info click “Rule” below</p>
                    }
                    <RichTextEditor toolbarConfig={toolbarConfig} id="rulesAndRegsText" name="rulesAndRegsText" value={this.state.rulesAndRegsText} onChange={(text) => this.onChangeRichText('rulesAndRegsText', text)}/>
                  </div>
                    { !isMlbTeam &&
                        <div className="form-group">
                            <label htmlFor="rulesAndRegsLink">Rules And Regs Link</label>
                            <p className="text-muted2" style={{fontSize:'10px'}}>This is where your rules and regulations are hosted</p>
                            <input id="rulesAndRegsLink" name="rulesAndRegsLink" type="url" className="form-control" value={this.state.rulesAndRegsLink} onChange={this.handleChange} placeholder="https://ourrules.com"/>
                        </div>
                    }
                    { isMlbTeam &&
                        <>
                            <div className="form-group">
                                <label htmlFor="supportHeader">Support Header:</label>
                                <input id="supportHeader" name="supportHeader" type="text" className="form-control" value={this.state.supportHeader} onChange={this.handleChange} placeholder="NEED SUPPORT?"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="supportText">Support Message:</label>
                                <RichTextEditor className="biggerRichTextForm" toolbarConfig={toolbarConfig} id="supportText" name="supportText" value={this.state.supportText} onChange={(text) => this.onChangeRichText('supportText', text)} placeholder={"Email customerservice@website.mlb.com with your issue and we'll be in touch!"}/>
                            </div>
                        </>
                    }
                </div>
              </div>
              <div className="container-out mobile-hide" style={{width:'45%', float:'right', marginRight:'20px'}}>
                <div className="admin-form-box" style={{border:'1px solid black', backgroundColor:'white'}}>
                  <p style={{fontSize:'20px', fontWeight:'bold'}}>Why do I need rules & regulations?</p>
                  <p className="text-muted2">While each state, country, and province is different, you will want to make sure you are compliant with all contest rules and laws.  It is your responsibility to update, maintain, & host your rules and regulations tab.</p>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default SetUpRulesAndRegs;
