import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { Prompt } from "react-router-dom";
import {Capitalize, validateEmail} from '../utils/HelpfulFunction';
import AuthService from '../utils/AuthService';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';

class SetUpTicketEmail extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.state = {
          helpText: '',
          subjectLine: '',
          emailSender: '',
          updateHappend: false,
          emailBackgroundImage: '',
          teamIconImage: '',
          loading:true,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getTicketEmailVariables = this.getTicketEmailVariables.bind(this);
    }

    componentDidMount() {
      this.getTicketEmailVariables();
    }

    handleImageChange(e){
      this.setState({
        updateHappend: true,
        [e.target.name]: URL.createObjectURL(e.target.files[0])
      });
    }

    handleChange(event){
      this.setState({
        [event.target.name]: event.target.value,
        updateHappend: true,
      });
    }

    getTicketEmailVariables(){
      var domainRoot = this.Auth.domain;
      this.Auth.fetch(domainRoot + 'services/app/Tenant/GetEmailTemplate', {
        method: 'GET',
      }).then(res => {
        if(res){
          if(res.apiStatus === "tenant_email_found"){
            var teamLocation = window.location.pathname.split('/')[1] || ""
            var upperCaseTeam = Capitalize(localStorage.getItem('team'+teamLocation))
            var subjectLine = res.entity.subjectLine || upperCaseTeam + " Scratch & Win Winner!"
            var emailSender = res.entity.emailSender || "winner@rewards." + teamLocation + ".com"
            var helpText = res.entity.emailHelpText || "Having issues? Email us or contact a customer representative"
            this.setState({
              helpText: helpText,
              subjectLine: subjectLine,
              emailSender: emailSender,
              teamIconImage: res.entity.teamIconImage,
              emailBackgroundImage: res.entity.emailBackgroundImage,
              loading:false
            })
          }
        }
      })
    }

    handleSubmit(event) {
        event.preventDefault();
        const tenantVariables = this.props.tenantVariables || {};
        const isMlbTeam = tenantVariables && tenantVariables.isMlbTeam;
        const formData = new FormData();
        const helpText = this.state.helpText;
        const subjectLine = this.state.subjectLine;
        const emailSender = this.state.emailSender;
        let backgroundImage = this.backgroundImage;
        if(backgroundImage){
            backgroundImage = backgroundImage.files[0]
        }
        if(emailSender && !validateEmail(emailSender)){
            swal({
                title: 'Sender Error',
                text: "Email sender must be a real email address!",
                type: 'warning',
                confirmButtonText: 'Ok'
              })
            return;
        }
        if(isMlbTeam && subjectLine && subjectLine.length > 45){
            //this rule comes from the MLB, make sure it is changed before removing this
            swal({
                title: 'Subject Error',
                text: "Message subject cannot be longer than 45 characters",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }
        formData.append('SubjectLine', subjectLine);
        formData.append('HelpText', helpText);
        formData.append('EmailSender', emailSender);
        formData.append('BackgroundImage', backgroundImage);
        const vm = this;
        const domainRoot = this.Auth.domain;
        this.setState({loading:true})
        this.Auth.fetch(domainRoot + 'services/app/Tenant/UpdateEmailTemplate', {
            method: 'POST',
            body: formData,
        }).then( res => {
            this.setState({loading:false})
            if(res){
              if(res.apiStatus === "tenant_email_updated"){
                swal({
                    title: "Edited",
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
                vm.setState({
                    modal: false,
                    subjectLine: res.entity.subjectLine,
                    emailSender: res.entity.emailSender,
                    helpText: res.entity.emailHelpText,
                    emailBackgroundImage: res.entity.emailBackgroundImage,
                    updateHappend: false
                })
              }
            } else {
              swal({
                  title: 'Uh oh!',
                  text: 'There was some error! Check your connection and logout and log back in if the error persists',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
            }
        }).catch(err => {
        console.log(err)
        swal({
            title: 'Uh oh!',
            text: 'There was some error! Check your connection and logout and log back in if the error persists',
            type: 'error',
            confirmButtonText: 'Ok'
          })
        })
    }

    render() {
        const tenantVariables = this.props.tenantVariables || {};
        const isMlbTeam = tenantVariables && tenantVariables.isMlbTeam;
        const teamLocation = window.location.pathname.split('/')[1] || "";
        return (
          <div className="admin-wrapper">
            <Prompt
              when={this.state.updateHappend}
              message="Unsaved changes, are you sure you want to leave?"
              />
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu tenantVariables={tenantVariables}/>
            <TopMenu/>
            <div className="admin-main-panel">
              <p className="admin-header-text" style={{marginBottom:0}}>{isMlbTeam?"Message":"Email"} Branding</p>
              <p className="admin-subheader-text">This is where you can edit the {isMlbTeam?"message":"email"} sent to fans</p>
              <div className="container-out">
                <div className="admin-form-box">
                  <form onSubmit={this.handleSubmit} id="create-email-form">
                    <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:20}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                    <span style={{display: this.state.updateHappend ? 'none' : '', paddingLeft:"5px", color:"grey" }}>No Changes</span>
                    <span style={{display: this.state.updateHappend ? '' : 'none', paddingLeft:"5px", color:"red" }}>UNSAVED CHANGES</span>
                    <div className="row col-md-12">
                      <div className="form-group">
                        <label htmlFor="subjectLine">Subject Line</label>
                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is the subject line that your fans will see when they receive their winning {isMlbTeam?"messages":"emails"}</p>
                        <input id="subjectLine" name="subjectLine" className="form-control" value={this.state.subjectLine} onChange={this.handleChange} />
                      </div>
                    </div>
                      {!isMlbTeam &&
                          <>
                              <div className="row col-md-12">
                                  <div className="form-group">
                                      <label htmlFor="emailSender">Email Sender</label>
                                      <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is the address reward emails will be sent from</p>
                                      <input id="emailSender" name="emailSender" className="form-control" value={this.state.emailSender} onChange={this.handleChange} />
                                  </div>
                              </div>
                          </>
                      }
                    <div className="row">
                        {!isMlbTeam &&
                            <>
                                <div className="form-group col-md-3">
                                    <label htmlFor="helpText">Help Text</label>
                                    <p style={{fontSize: '10px', color: 'grey', fontFamily: 'Open Sans'}}>This is where you can tell fans where to contact you with issues. (Example: Having issues? Email info@{teamLocation}.com for help.)</p>
                                    <textarea style={{minHeight: '100px'}} id="helpText" name="helpText" className="form-control" value={this.state.helpText} onChange={this.handleChange}/>
                                </div>
                                <div className="form-group col-md-3" align="center">
                                    <img src={this.state.emailBackgroundImage} width="auto" height="100px" alt=""/>
                                    <br/>
                                    <label htmlFor="backgroundImage">Email Header Image<br/>(700px X 400px)</label>
                                    <div className="form-group">
                                        <input style={{display:'none'}} id="backgroundImage" name="backgroundImage" type="file" ref={input => {this.backgroundImage = input;}} onInput={e => this.handleImageChange(e)} onClick={(event)=> {event.target.value = null}}/>
                                        <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('backgroundImage').click()} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                  </form>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default SetUpTicketEmail
