import React, { Component } from 'react';
import { PulseLoader as LoadingSpinner } from 'react-spinners'

class Loading extends Component {
  render() {
    if (this.props.loading === true) {
            return (
                <div style={{
                    marginTop: '200px',
                    textAlign: 'center',
                    position: 'absolute',
                    left: '0px',
                    top: '0px',
                    width: '100%',
                }}>
                    <LoadingSpinner color='#fff' loading={true} />
                </div>
            )
        }
    }
}

export default Loading;


//
// import React from "react";
// import FadeIn from "react-fade-in";
// import Lottie from "react-lottie";
// import * as doneData from "../../constants/3536-checkbox-a_Clean_black.json";
// import * as legoData from "../../constants/6639-red-load_Top_One.json";
// import "bootstrap/dist/css/bootstrap.css";
// // import * as legoData from "../../constants/legoloading.json";
// // import * as doneData from "../../constants/doneloading.json";
//
// const defaultOptions = {
//   loop: true,
//   autoplay: true,
//   animationData: legoData,
//   rendererSettings: {
//     preserveAspectRatio: "xMidYMid slice"
//   }
// };
// const defaultOptions2 = {
//   loop: false,
//   autoplay: true,
//   animationData: doneData,
//   rendererSettings: {
//     preserveAspectRatio: "xMidYMid slice"
//   }
// };
//
// export default class Loading extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       done: undefined
//     };
//   }
//
//   render() {
//     return (
//       <div>
//           <FadeIn>
//             <div className="d-flex justify-content-center align-items-center">
//               <h1>fetching pizza</h1>
//               {!this.state.loading ? (
//                 <Lottie options={defaultOptions} height={120} width={120} />
//               ) : (
//                 <Lottie options={defaultOptions2} height={120} width={120} />
//               )}
//             </div>
//           </FadeIn>
//       </div>
//     );
//   }
// }
