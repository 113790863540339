import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import AuthService from '../utils/AuthService';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import Pagination from "react-js-pagination";
import {cleanCSVText, cleanDate} from '../utils/HelpfulFunction';
import DatePicker from '../utils/DatePicker';

class SetUpScratcherGame extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.state = {
            ticketList: [],
            rewardsAdded: [],
            attachedTickets: [],
            currentGameRewards: [],
            otherNumber: 0,
            gotRewards: false,
            csvRewards: [],
            scratcherGame: null,
            gameStartTime: new Date(),
            gameEndTime: new Date(),
            cardsPerPack: '0',
            currentGameActive: false,
            modal: false,
            players: 0,
            rewardToAdd: '',
            rewardAmount: 0,
            prizesWon: 0,
            codes: false,
            gameName: "",
            allowMoreScratchersAfterTime: false,
            convertToQrCode: false,
            codesArray: '',
            autoGame: false,
            loading: true,
            setIntervalId: null,
            wizardLocation: 'first',
            showStats: false,
            losingTickets: null,
            activePage: 1,
            totalPrizes: 0,
            hasRewardWithAutoRedemptions: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.saveReward = this.saveReward.bind(this);
        this.toggleAutoGame = this.toggleAutoGame.bind(this);
    }

    componentDidMount() {
      this.getTickets();
      this.getCurrentGame();
      this.checkTenantVariables();
      this.getCurrentGameStats();
    }

    componentWillUnmount() {
      if(this.state.setIntervalId){
        clearInterval(this.state.setIntervalId);
      }
    }

    async downloadUsers(){
        const isMlbTeam = this.props.tenantVariables && this.props.tenantVariables.isMlbTeam;
        let csv = 'Reward Name,Given Out,Redeem Code,Redeem Pin,Sent,Email Address,Phone Number,Time Won,First Name,Last Name,Has been Redeemed\n';
        if(isMlbTeam){
            csv = 'Reward Name,Given Out,Redeem Code,Redeem Pin,Sent,UID,Time Won,Has been Redeemed\n';
        }
        const vm = this;
        vm.setState({
            loading:true
        });
        const teamLocation = window.location.pathname.split('/')[1] || "";
        const query = document.getElementById('searchQuery').value;
        const currentUsersState = await this.getRewards(0, this.state.totalPrizes,query, true);
        if(currentUsersState && currentUsersState.rewards){
            currentUsersState.rewards.forEach(function(row) {
                let timeStamp = ""
                if(row.timeStamp){
                    timeStamp = cleanCSVText(row.timeStamp);
                }
                csv += cleanCSVText(row.rewardName);
                csv+= ",";
                csv += row.givenOut;
                csv+= ",";
                csv += cleanCSVText(row.redeemCode);
                csv+= ",";
                csv += row.redeemPin;
                csv+= ",";
                csv += row.winningEmailSent;
                csv+= ",";
                csv += row.winningEmailAddress;
                csv+= ",";
                if(!isMlbTeam) {
                    csv += row.number;
                    csv+= ",";
                }
                csv += timeStamp;
                csv+= ",";
                if(!isMlbTeam) {
                    csv+= cleanCSVText(row.firstName);
                    csv+= ",";
                    csv+= cleanCSVText(row.lastName);
                    csv+= ",";
                }
                csv+= row.isRedeemed ? "Yes" : "No";
                csv += "\n";
            }, this);
        }
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + "_" + teamLocation +".csv";
        hiddenElement.download = "scratcher_game_rewards_" + date_got;
        hiddenElement.click();
        vm.setState({
            loading:false
        });
    }

    updateStats(){
      this.setState({
        loading:true
      })
      this.getCurrentGameStats();
    }

    setTimers(){
      // var vm = this;
      // var intervalId = setInterval(function(){
      //   vm.getCurrentGameStats();
      // }, 60000)
      // this.setState({
      //   setIntervalId:intervalId
      // })
    }

    turnOffTimers(){
      var intervalId = this.state.setIntervalId;
      if(intervalId){
        clearInterval(this.state.setIntervalId);
      }
    }

    getRewards(skip, take, query, download){
        const vm = this;
        return new Promise(function(resolve, reject) {
            var domainRoot = vm.Auth.domain;
            vm.setState({
                gotRewards: false,
                loading: true
            })
            if ((skip !== 0 && !skip) || !take){
                skip = 0;
                take = 100;
            }
            var teamLocation = window.location.pathname.split('/')[1] || ""
            const gameId = localStorage.getItem('currentGameId' + teamLocation);
            let urlRoute = `services/app/Game/GetPrizes/${gameId}/${skip}/${take}`;
            if (query) {
                urlRoute = `services/app/Game/SearchPrizes/${gameId}/${take}/${query}`;
            }
            vm.Auth.fetch(domainRoot + urlRoute, {
                method: 'GET',
            }).then(res => {
                vm.setState({
                    loading: false,
                    gotRewards: true
                })
                if (res) {
                    if (res.apiStatus === "no_prizes_found") {
                        swal({
                            title: 'Oh uh!',
                            text: res.message,
                            type: 'warning',
                            confirmButtonText: 'Ok'
                        })
                        return resolve({error:"There was some error"});
                    } else if (res.apiStatus === "prizes_found") {
                        let hasRewardWithAutoRedemptions = false;
                        var cleanRewards = [];
                        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
                        for (var prize in res.entity) {
                            var reward = {};
                            var uniquePrize = res.entity[prize];
                            var winningEmailAddress = uniquePrize.winningEmailAddress || "None Yet";
                            var winningEmailSent = "Not Sent"
                            var timeStamp = null;
                            if (uniquePrize.issuedDateTime) {
                                var localTime = new Date(new Date(uniquePrize.issuedDateTime) - tzoffset).getTime()
                                var formatedTimeSplit = new Date(localTime).toString().split(" ")
                                var monthSplit = formatedTimeSplit[1];
                                var daySplit = formatedTimeSplit[2];
                                var yearSplit = formatedTimeSplit[3];
                                var dateString = monthSplit + " " + daySplit + " " + yearSplit
                                var time_redemeed = vm.toStandardTime(formatedTimeSplit[4])
                                timeStamp = dateString + ", " + time_redemeed
                            }
                            var givenOut = "No"
                            var number = ""
                            var firstName = ""
                            var lastName = ""
                            if (uniquePrize.userId) {
                                givenOut = "Yes";
                                var user = uniquePrize.user;
                                number = user.userName.split('@')[0];
                                firstName = user.firstName
                                lastName = user.lastName
                                var isnum = /^\d+$/.test(number);
                                if (!isnum) {
                                    if(user.phoneNumber){
                                        number = user.phoneNumber || "N/A";
                                    } else {
                                        number = "N/A";
                                    }
                                }
                            }
                            if (uniquePrize.winningEmailSent) {
                                winningEmailSent = "Sent"
                            }
                            if (givenOut === "Yes" && winningEmailSent === "Not Sent" && winningEmailAddress === "None Yet" && user && user.email.includes('@') && user.email.indexOf('sqwadapp.co') === -1) {
                                winningEmailAddress = user.email
                            }

                            if(winningEmailAddress && vm.props.tenantVariables && vm.props.tenantVariables.isMlbTeam && winningEmailAddress !== "None Yet") {
                                const splitWinningAddress = winningEmailAddress.split('@');
                                splitWinningAddress.pop()
                                winningEmailAddress = splitWinningAddress.join("@");
                            } else if(vm.props.tenantVariables && vm.props.tenantVariables.isMlbTeam && winningEmailAddress === "None Yet" && givenOut === "Yes") {
                                const splitWinningAddress = user.email.split('@');
                                splitWinningAddress.pop()
                                winningEmailAddress = splitWinningAddress.join("@");
                            }
                            if(uniquePrize.rewardTemplate.isRedeemable){
                                hasRewardWithAutoRedemptions = true;
                            }
                            reward['isRedeemable'] = uniquePrize.rewardTemplate.isRedeemable;
                            reward['rewardName'] = uniquePrize.rewardTemplate.rewardName;
                            reward['givenOut'] = givenOut;
                            reward['redeemCode'] = uniquePrize.redeemCode;
                            reward['redeemPin'] = uniquePrize.redeemPin;
                            reward['winningEmailSent'] = winningEmailSent;
                            reward['emailSentDate'] = uniquePrize.emailSentDate;
                            reward['winningEmailAddress'] = winningEmailAddress;
                            reward['number'] = number;
                            reward['timeStamp'] = timeStamp;
                            reward['firstName'] = firstName;
                            reward['lastName'] = lastName;
                            reward['isRedeemed'] = uniquePrize.isRedeemed;
                            reward['id'] = uniquePrize.id;
                            cleanRewards.push(reward)
                        }
                        if(download){
                            return resolve({rewards:cleanRewards});
                        } else {
                            vm.setState({
                                currentGameRewards: cleanRewards,
                                hasRewardWithAutoRedemptions: hasRewardWithAutoRedemptions
                            }, () => {
                                return resolve({rewards:cleanRewards});
                            })
                        }
                    } else {
                        return resolve({error:"There was some error"});
                    }
                } else {
                    swal({
                        title: 'There was some error!',
                        text: 'Something went wrong.  Try again and if the problem persists check your connection and relogin',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    })
                    return resolve({error:"There was some error"});
                }
            })
        })
    }

    getCurrentGame(){
      var domainRoot = this.Auth.domain;
      var vm = this;
      this.Auth.fetch(domainRoot + 'services/app/Game/GetCurrentGame', {
        method: 'GET',
      }).then(res => {
        if(res){
          if(res.apiStatus === "game_found"){
            var teamLocation = window.location.pathname.split('/')[1] || ""
            localStorage.setItem('currentGameId'+teamLocation, res.entity.id)
            vm.getRewards();
            vm.setState({
              currentGameActive: res.entity.isActive,
              scratcherGame: res.entity,
              loading:false
            }, () => {
              // vm.setTimers();
            })
          } else if(res.apiStatus === "game_not_found"){
            vm.setState({
              scratcherGame: null,
              loading:false
            })
          } else if(res.message === "Too Many Requests") {
              console.log("Fail Silently")
          } else {
            swal({
                title: 'Something went wrong!',
                text: 'Please try again! If the problem persists please log out and log back in',
                type: 'error',
                confirmButtonText: 'Ok'
              })
            vm.setState({
              loading:false
            })
          }
        } else {
          swal({
              title: 'Something went wrong!',
              text: 'Please try again! If the problem persists please log out and log back in',
              type: 'error',
              confirmButtonText: 'Ok'
            })
          vm.setState({
            gotRewards: true,
            loading:false
          })
        }
      }).catch(err =>{
        this.setState({
          gotRewards: true,
          loading:false
        })
        console.log(err)
      })
    }

    getCurrentGameStats(){
      var domainRoot = this.Auth.domain;
      this.Auth.fetch(domainRoot + 'services/app/Report/Tenant/GetCurrentGameStats', {
        method: 'GET',
      }).then(res => {
        this.setState({loading:false})
        if(!res){
          console.log("Error")
          return
        }
        if(res.apiStatus === "report_found"){
          this.setState({
            players: res.entity.players,
            prizesWon: res.entity.prizesWon,
            totalPrizes: res.entity.totalWinningPrizes,
            redemptions: res.entity.redemptions
          })
        }
      })
    }

    checkTenantVariables(){
      var domainRoot = this.Auth.domain;
      var vm = this;
      vm.Auth.fetch(domainRoot + 'services/app/Tenant/Get', {
        method: 'GET',
      }).then(res=>{
        if(res){
          if(res.apiStatus === "tenant_info"){
            if(!res.entity.primaryColor){
              swal({
                  title: 'Primary color is missing!',
                  text: 'Add one before activating a game',
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
            } else if(!res.entity.secondaryColor){
              swal({
                  title: 'Secondary color is missing!',
                  text: 'Add one before activating a game',
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
            } else if(!res.entity.topLeftImage){
              swal({
                  title: 'Team logo is missing!',
                  text: 'Upload the image before activating a game',
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
            } else if(!res.entity.frontLogoImage){
              swal({
                  title: 'Front logo is missing!',
                  text: 'Upload the image before activating a game',
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
            } else if(!res.entity.backgroundImage){
              swal({
                  title: 'Background image is missing!',
                  text: 'Upload the image before activating a game',
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
            } else if(!res.entity.scratcherImage){
              swal({
                  title: 'Top Scratcher Image is missing!',
                  text: 'Upload the image before activating a game',
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
            } else if(!res.entity.rulesAndRegsText){
              swal({
                  title: 'Rules and regulations text is missing!',
                  text: 'Add it before activating a game',
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
            }
          }
        }
      })
    }

    continueOpeningNewGameModel(){
      if(this.state.scratcherGame){
        if(this.state.scratcherGame.startTime){
          var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
          var localISOTime = new Date(Date.now()).getTime();
          var gameStartTime = this.state.scratcherGame.startTime;
          var reformatedStartDate = gameStartTime.split("T")[0].split("-")[1] + "/" + gameStartTime.split("T")[0].split("-")[2] + "/" + gameStartTime.split("T")[0].split("-")[0]
          gameStartTime = reformatedStartDate + " " + gameStartTime.split("T")[1]
          if(localISOTime < new Date(gameStartTime).getTime() - tzoffset){
            swal({
                title: 'Hold on!',
                text: 'The start time of the current game is in the future.  Are you sure you want to replace it?  All data associated with it will be deleted and unrecoverable.  Is that ok?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
              }).then((result)=>{
                if(result.value){
                    this.setState({loading: true});
                  var gameObject = {"gameId": this.state.scratcherGame.id}
                  var domainRoot = this.Auth.domain;
                  this.Auth.fetch(domainRoot + 'services/app/game/deletegame?gameId=' + this.state.scratcherGame.id, {
                    method: 'DELETE',
                    headers: {
                       'Content-Type': 'application/json',
                     },
                    body: JSON.stringify(gameObject),
                  }).then( res => {
                    if(res.apiStatus === "succesfully_deleted"){
                        let vm = this;
                        setTimeout(function(){
                            vm.getCurrentGame();
                            swal({
                                title: 'Successful Deletion!',
                                type: 'success',
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: true,
                                timer: 10000
                            })
                            vm.setState({
                                modal: true,
                            });
                        },3000)
                    } else {
                      swal({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in',
                        type: 'error',
                        confirmButtonText: 'Ok'
                      })
                    }
                  })
                }
              })
          } else {
            this.setState({
              modal: true,
            }, () => {
              this.turnOffTimers()
            });
          }
        } else {
          this.setState({
            modal: true,
          }, () => {
            this.turnOffTimers()
          });
        }
      } else {
        this.setState({
          modal: true,
        }, () => {
          this.turnOffTimers()
        });
      }
    }

    async openCreateGameModal(){
      const domainRoot = this.Auth.domain;
      if(this.state.currentGameActive){
          swal({
              title: 'Hold on!',
              text: 'Can only replace current game if it is inactive',
              type: 'warning',
              confirmButtonText: 'Ok'
          })
          return
      }
      this.Auth.fetch(domainRoot + 'services/app/Game/GetCurrentGame', {
        method: 'GET',
      }).then(res => {
        if(res){
          if(res.apiStatus === "game_found"){
            if(res.entity.isActive){
              swal({
                  title: 'Hold on!',
                  text: 'Can only replace current game if it is inactive',
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
                let teamLocation = window.location.pathname.split('/')[1] || ""
                localStorage.setItem('currentGameId'+teamLocation, res.entity.id);
                this.setState({
                    currentGameActive: res.entity.isActive,
                    scratcherGame: res.entity,
                    loading:false
                })
            } else {
              this.continueOpeningNewGameModel();
            }
          } else {
            this.continueOpeningNewGameModel();
          }
        } else {
            swal({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try logging out and logging back in',
                type: 'error',
                confirmButtonText: 'Ok'
            })
            return;
        }
        if(res.apiStatus === "game_found"){
          if(res.entity.isActive){
            swal({
                title: 'Hold on!',
                text: 'Can only replace current game if it is inactive',
                type: 'warning',
                confirmButtonText: 'Ok'
              })
            return;
          }
        }
      })
    }

    hide_create_game_buttons(){
      var elements = document.getElementsByClassName("create-game-modal");
      for (var i = 0; i < elements.length; i++){
        // elements[i].style.display = 'none';
        elements[i].disabled = true;
      }
    }

    getTickets(){
      var domainRoot = this.Auth.domain;
      this.Auth.fetch(domainRoot + 'services/app/Tenant/GetTenantRewardTemplates', {
        method: 'GET',
      }).then(res => {
        this.setState({
          gotRewards: true,
          loading:false
        })
        if(res){
          if(res.tenantRewardTemplates.length === 0){
            swal({
                title: 'No Prizes Created!',
                text: 'Go to Prizes tab and make some prizes!',
                type: 'warning',
                confirmButtonText: 'Ok'
              })
            this.hide_create_game_buttons();
            return;
          }
          var thereIsAFalseOne = false;
          var thereIsATrueOne = false;
          for(var template in res.tenantRewardTemplates){
            if(!res.tenantRewardTemplates[template].isWinning){
              thereIsAFalseOne = true
            } else {
              thereIsATrueOne = true
            }
          }
          if(!thereIsAFalseOne){
            swal({
                title: 'There are no losing tickets!',
                text: 'Please go to Prizes and create a losing ticket',
                type: 'warning',
                confirmButtonText: 'Ok'
              })
            this.hide_create_game_buttons();
            return;
          } else if(!thereIsATrueOne) {
            swal({
                title: 'There are no winning prizes!',
                text: 'Please go to Prizes and create a prize',
                type: 'warning',
                confirmButtonText: 'Ok'
              })
            this.hide_create_game_buttons();
            return;
          }
          this.setState({
            ticketList: res.tenantRewardTemplates || []
          })
        } else {
          swal({
              title: 'Something went wrong!',
              text: 'Check your connection and try again!',
              type: 'error',
              confirmButtonText: 'Ok'
            })
        }
      })
    }

    createGame(){
        const createGameObject = {};
        const domainRoot = this.Auth.domain;
        var allowMoreScratchersAfterTime = this.state.allowMoreScratchersAfterTime;
        let gameName = this.state.gameName || "";
        gameName = gameName.trim();
        const gameStartTime = this.state.gameStartTime;
        const gameEndTime = this.state.gameEndTime;
        var cardsPerPack = parseInt(this.state.cardsPerPack, 10);
        var rewards = this.state.rewardsAdded;
        var losingTicketsToCreate = this.state.losingTickets;
        var otherNumber = this.state.otherNumber;
        const vm = this;
        const prizeLimit = parseInt(this.state.prizeLimit, 10);
        const limitPrizesGivenOut = this.state.limitPrizesGivenOut;
        var rewardsArray = [];
        let localISOTime = (new Date(Date.now())).getTime();
        let startTimeMill = gameStartTime.getTime();
        let endTimeMill = gameEndTime.getTime();
        if(!gameName){
            swal({
                title: 'Hold on!',
                text: 'Game Name is required to make a game',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return
        }
      if(this.state.autoGame){
        if(startTimeMill === endTimeMill){
          swal({
              title: 'Hold on!',
              text: 'You have scheduled a game but the start and end times are equal! Go back to the first tab to update your times',
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          return
        } else if(startTimeMill > endTimeMill){
          swal({
              title: 'Hold on!',
              text: 'Game Start time cant be after game end time!',
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          return
        } else if(startTimeMill < localISOTime) {
          swal({
              title: 'Hold on!',
              text: 'Game Start time before now!',
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          return
        } else if(!startTimeMill || !endTimeMill){
            swal({
                title: 'Hold on!',
                text: 'You have scheduled a game but no start time or end time',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return
        }
        createGameObject.startTime = new Date(gameStartTime).toISOString();
        createGameObject.endTime = new Date(gameEndTime).toISOString();
      } else {
        createGameObject.startTime = null;
        createGameObject.endTime = null;
      }
      if(cardsPerPack < 1 || !cardsPerPack){
        swal({
            title: "You haven't added any cards",
            text: 'Cards per pack needs to be greater than 0.  Go back to the first tab to add cards',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      if(cardsPerPack > 5){
        swal({
            title: "Sorry!",
            text: 'At this time we do not allow games with more than 5 cards per pack.  Reach out to Sqwad if you have any questions.',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      if(rewards.length === 0){
        swal({
            title: "You haven't added any prizes!",
            text: 'Go to the second tab and add at least 1 prize',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      try {
        otherNumber = parseInt(otherNumber)
      } catch (e) {
        swal({
            title: "Difficulty Error",
            text: 'Please select a difficulty or make sure a number above zero is entered as a difficulty',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return
      }
      if(!losingTicketsToCreate && otherNumber == 0){
        swal({
            title: "No difficulty selected",
            text: 'Please select a difficulty before creating a game.',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      } else if (otherNumber < 0) {
        swal({
            title: "Difficulty Error",
            text: 'Make sure a number above zero is entered as a difficulty',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      var amountOfRewards = 0;
      for(var reward in rewards){
        var rewardObject = {};
        rewardObject.id = parseInt(rewards[reward].id, 10);
        rewardObject.noOfAvailableTickets = parseInt(rewards[reward].amount, 10);
        amountOfRewards = amountOfRewards + parseInt(rewards[reward].amount, 10)
        rewardObject.codes = rewards[reward].codesArray || [];
        rewardObject.hasCodes = rewards[reward].codes;
        rewardObject.convertToQrCode = rewards[reward].convertToQrCode;
        rewardsArray.push(rewardObject);
      }
      createGameObject.rewards = rewardsArray;
      createGameObject.rewardsPerPack = cardsPerPack;
      createGameObject.convertToQrCode = allowMoreScratchersAfterTime;
      createGameObject.gameName = gameName;
      if(limitPrizesGivenOut === true && prizeLimit){
          createGameObject.prizeLimit = prizeLimit;
      } else if(limitPrizesGivenOut === true && !prizeLimit){
          swal({
              title: "No Prize Limit",
              text: "Limit prizes is checked on but no limit is given! Either uncheck the prize limit or add a prize limit",
              type: 'warning',
              confirmButtonText: 'Ok'
          })
          return;
      } else {
          createGameObject.prizeLimit = 0;
      }
      var amountOfTicketsToCreate = 0
      if(losingTicketsToCreate === "easy"){
        if(amountOfRewards > 1000){
          swal({
              title: "Too many prizes",
              text: 'On easy it is best to create a game with 1000 or less prizes.  Reach out to Sqwad if you have any questions!',
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          return;
        }
        amountOfTicketsToCreate = cardsPerPack * amountOfRewards/(1/3) - amountOfRewards
      } else if(losingTicketsToCreate === "medium"){
        if(amountOfRewards > 300){
          swal({
              title: "Too many prizes",
              text: 'On medium it is best to create a game with 300 or less prizes.  Reach out to Sqwad if you have any questions!',
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          return;
        }
        amountOfTicketsToCreate = cardsPerPack * amountOfRewards/(1/30) - amountOfRewards
      } else if(losingTicketsToCreate === "hard"){
        if(amountOfRewards > 50){
          swal({
              title: "Too many prizes",
              text: 'On hard it is best to create a game with 50 or less prizes.  Reach out to Sqwad if you have any questions!',
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          return;
        }
        amountOfTicketsToCreate = cardsPerPack * amountOfRewards/(1/300) - amountOfRewards
      } else {
        amountOfTicketsToCreate = cardsPerPack * amountOfRewards/(1/otherNumber) - amountOfRewards
        if(otherNumber === 1){
          amountOfTicketsToCreate = 0
        }
        if(amountOfTicketsToCreate > 15000){
          swal({
              title: "Too many cards created",
              text: 'Cannot create a game with more than 15000 prizes.  Reach out to Sqwad if you have any questions!',
              type: 'warning',
              confirmButtonText: 'Ok'
            })
        return;
        }
      }

      createGameObject.losingTicketCount = parseInt(amountOfTicketsToCreate, 10)
      this.setState({loading:true})

      this.Auth.fetch(domainRoot + 'services/app/Game/GetCurrentGame', {
        method: 'GET',
      }).then(res => {
        if(res){
          if(res.apiStatus === "game_found"){
            if(res.entity.isActive){
                swal({
                    title: 'Game Not Created!',
                    text: 'The current game is active so it could not be replaced',
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                let teamLocation = window.location.pathname.split('/')[1] || ""
                localStorage.setItem('currentGameId'+teamLocation, res.entity.id);
                this.setState({
                    currentGameActive: res.entity.isActive,
                    scratcherGame: res.entity,
                    loading:false
                })
            } else {
              vm.Auth.fetch(domainRoot + 'services/app/Game/TenantCreateGame', {
                method: 'POST',
                headers: {
        	         'Content-Type': 'application/json',
                 },
                body: JSON.stringify(createGameObject),
              }).then(res => {
                vm.setState({loading:false})
                if(res){
                  if(res.apiStatus === 'game_created'){
                    swal({
                        title: res.message,
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                      })
                      let teamLocation = window.location.pathname.split('/')[1] || ""
                      localStorage.setItem('currentGameId'+teamLocation, res.entity.id);
                      const vm = this;
                      vm.setState({
                          currentGameActive: res.entity.isActive,
                          scratcherGame: res.entity,
                          loading:false,
                          modal:false
                      },() => {
                          vm.getRewards();
                      })
                    // window.location.reload();
                  } else{
                    var error_message = res.message;
                    if(error_message === "No rewards have been added to the game"){
                      error_message = "No prizes have been added to the game!"
                    }
                    swal({
                        title: 'Game Not Created!',
                        text: error_message,
                        type: 'warning',
                        confirmButtonText: 'Ok'
                      })
                  }
                } else {
                  swal({
                      title: 'Game Not Created!',
                      text: 'Something went wrong and the game was not created!',
                      type: 'warning',
                      confirmButtonText: 'Ok'
                    })
                }
              })
            }
          } else if(res.apiStatus === "game_not_found"){
            vm.Auth.fetch(domainRoot + 'services/app/Game/TenantCreateGame', {
              method: 'POST',
              headers: {
                 'Content-Type': 'application/json',
               },
              body: JSON.stringify(createGameObject),
            }).then(res => {
              vm.setState({loading:false})
              if(res){
                if(res.apiStatus === 'game_created'){
                  swal({
                      title: res.message,
                      type: 'success',
                      toast: true,
                      position: 'top-end',
                      showConfirmButton: false,
                      timer: 3000
                    })
                    const newGame = res.entity;
                    var teamLocation = window.location.pathname.split('/')[1] || ""
                    localStorage.setItem('currentGameId'+teamLocation, res.entity.id)
                    vm.setState({
                        currentGameActive: newGame.isActive,
                        scratcherGame: newGame,
                        loading:false,
                        modal: false
                    },() => {
                        vm.getRewards();
                    })
                } else{
                  swal({
                      title: 'Game Not Created!',
                      text: res.message,
                      type: 'warning',
                      confirmButtonText: 'Ok'
                    })

                }
              }
            })
          }
        }
      }).catch(err =>{
        console.log(err)
      })
    }

    stopGame(){
      const domainRoot = this.Auth.domain;
      const teamLocation = window.location.pathname.split('/')[1] || "";
      const gameId = localStorage.getItem('currentGameId'+teamLocation);
      const stopGameObject = {"gameId": gameId};
      const vm = this;
      this.setState({loading:true})
      this.Auth.fetch(domainRoot + 'services/app/Game/TenantEndGame', {
        method: 'POST',
        headers: {
	         'Content-Type': 'application/json',
         },
        body: JSON.stringify(stopGameObject),
      }).then(res => {
        this.setState({loading:false})
        if(res.isActive === false){
          vm.setState({
            currentGameActive: res.isActive,
          })
          swal({
              title: 'Game Stopped',
              type: 'error',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
        } else {
          swal({
              title: 'Uh oh!',
              text: 'Something went wrong, game not stopped!',
              type: 'error',
              confirmButtonText: 'Ok'
            })
        }
      }).catch(err =>{
        console.log(err)
      })
    }

    startGame() {
      if(this.state.scratcherGame.startTime && this.state.scratcherGame.endTime){
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        var localISOTime = new Date(Date.now()).getTime();
        var gameStartTime = this.state.scratcherGame.startTime;
        var reformatedStartDate = gameStartTime.split("T")[0].split("-")[1] + "/" + gameStartTime.split("T")[0].split("-")[2] + "/" + gameStartTime.split("T")[0].split("-")[0]
        gameStartTime = reformatedStartDate + " " + gameStartTime.split("T")[1]
        var gameEndTime = this.state.scratcherGame.endTime;
        var reformatedEndDate = gameEndTime.split("T")[0].split("-")[1] + "/" + gameEndTime.split("T")[0].split("-")[2] + "/" + gameEndTime.split("T")[0].split("-")[0]
        gameEndTime = reformatedEndDate + " " + gameEndTime.split("T")[1]

        var startTimePartSplit = this.state.scratcherGame.startTime.split('.')[0].split(':')
        var startTimePart = startTimePartSplit[0] + ":" + startTimePartSplit[1]
        var endTimePartSplit = this.state.scratcherGame.endTime.split('.')[0].split(':')
        var endTimePart = endTimePartSplit[0] + ":" + endTimePartSplit[1]
        if(startTimePart != endTimePart && localISOTime < (new Date(gameStartTime).getTime()- new Date().getTimezoneOffset()*60*1000)){
          swal({
              title: 'Game set to autorun!',
              text: 'Are you sure you want to do this?  Turning on the game will disable auto run',
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'YES I WANT TO RUN MANUALLY',
            }).then((result)=>{
              if(result.value){
                this.startRealGame();
              }
            })
          } else {
            this.startRealGame();
          }
      } else {
        this.startRealGame();
      }
    }

    startRealGame(){
      let domainRoot = this.Auth.domain;
      let teamLocation = window.location.pathname.split('/')[1] || "";
      let gameId = localStorage.getItem('currentGameId'+teamLocation);
      let startGameObject = {"gameId": gameId}
      let vm = this;
      this.setState({loading:true})
      this.Auth.fetch(domainRoot + 'services/app/Game/TenantStartGame', {
        method: 'POST',
        headers: {
	         'Content-Type': 'application/json',
         },
        body: JSON.stringify(startGameObject),
      }).then(res => {
        this.setState({loading:false})
        if(res.isActive === true){
            let scratcherGame = vm.state.scratcherGame || {};
            scratcherGame.startTime = null;
            scratcherGame.endTime = null;
          vm.setState({
            currentGameActive: res.isActive,
            scratcherGame: scratcherGame
          })
          swal({
              title: 'Game Started',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
        } else {
          swal({
              title: 'Uh oh!',
              text: 'Something went wrong, please try again!',
              type: 'error',
              confirmButtonText: 'Ok'
            })
        }
      }).catch(err =>{
        console.log(err)
      })
    }

    handleChange (evt) {
      const target = evt.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      if(evt.target.name === "otherNumber"){
        this.setState({
          [evt.target.name]: value
        });
        this.calculateOdds("other")
      } else {
        this.setState({ [evt.target.name]: value });
      }
    }

    toStandardTime(value) {
      if (value !== null && value !== undefined){ //If value is passed in
        if(value.indexOf('AM') > -1 || value.indexOf('PM') > -1){ //If time is already in standard time then don't format.
          return value;
        }
        else {
          if(value.length == 8){ //If value is the expected length for military time then process to standard time.
            var hour = value.substring ( 0,2 ); //Extract hour
            var minutes = value.substring ( 3,5 ); //Extract minutes
            var identifier = 'AM'; //Initialize AM PM identifier
     
            if(hour == 12){ //If hour is 12 then should set AM PM identifier to PM
              identifier = 'PM';
            }
            if(hour == 0){ //If hour is 0 then set to 12 for standard time 12 AM
              hour=12;
            }
            if(hour > 12){ //If hour is greater than 12 then convert to standard 12 hour format and set the AM PM identifier to PM
              hour = hour - 12;
              identifier='PM';
            }
            return hour + ':' + minutes + ' ' + identifier; //Return the constructed standard time
          }
          else { //If value is not the expected length than just return the value as is
            return value;
          }
        }
      }
    }

    toggle() {
      if(this.state.modal){
        console.log("TURN TIMERS ON")
        // this.setTimers()
      } else {
        this.turnOffTimers()
      }
      this.setState({
        modal: !this.state.modal,
        autoGame: false,
        cardsPerPack: 0,
        gameStartTime: new Date(),
        gameEndTime: new Date(),
        prizeLimit: null,
        codes: false,
        gameName: "",
        rewardsAdded: [],
        rewardToAdd: '',
        allowMoreScratchersAfterTime: false,
        convertToQrCode: false,
        limitPrizesGivenOut: null,
        otherNumber: 0,
        activePage: 1,
        wizardLocation: 'first'
      });
    }

    saveReward (evt) {
      this.setState({ rewardToAdd: evt.target.value });
    }

    addRewardToLocalArray(){
      var rewardAmount = this.state.rewardAmount;
      var rewardId = this.state.rewardToAdd;
      var codes = this.state.codes;
      var convertToQrCode = this.state.convertToQrCode;
      var codesArray = this.state.codesArray;
      var pinCodes = this.state.pinCodes;
      codesArray = codesArray.replace(/\n/g, " ")
      if(codesArray.trim() === "" && codes){
        swal({
            title: 'Hold on!',
            text: 'Must enter codes or uncheck codes box!',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      } else if (codes){
        codesArray = codesArray.replace(/  /g, " ")
        codesArray = codesArray.replace(/ /g, ",")
        codesArray = codesArray.replace(/,,/g, ",")
        var totalCodes = codesArray.split(',')

        if(totalCodes.length !== parseInt(rewardAmount,10)){
          swal({
              title: 'Codes not equal!',
              text: "# of codes must equal number of rewards created. In this case: " +  totalCodes.length + " does not equal " + rewardAmount,
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          return;
        }
        if(pinCodes && pinCodes.trim() !== ""){
          pinCodes = pinCodes.replace(/\n/g, " ")
          pinCodes = pinCodes.replace(/  /g, " ")
          pinCodes = pinCodes.replace(/ /g, ",")
          pinCodes = pinCodes.replace(/,,/g, ",")
          var totalPins = pinCodes.split(',')
          if(totalPins.length !== totalCodes.length){
            swal({
                title: 'Pins not equal!',
                text: "If you add pins the # of pins must equal the number of codes created. In this case: " +  totalCodes.length + " does not equal " + totalPins.length,
                type: 'warning',
                confirmButtonText: 'Ok'
              })
            return;
          } else {
            var tempArray = [];
            for(var codeIndex in totalCodes){
              var currentCode = totalCodes[codeIndex];
              var currentPin = totalPins[codeIndex];
              var newCode = currentCode + ":" + currentPin;
              tempArray.push(newCode);
            }
            totalCodes = tempArray;
          }
        }
      }

      if(!rewardId || !rewardAmount || rewardAmount < 1 || rewardId.length < 1){
        swal({
            title: 'Missing field!',
            text: "Missing reward or amount of reward.  Can't add a reward to a game without an amount or selecting a reward",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }

      for(var ticket in this.state.ticketList){
        if(parseInt(this.state.ticketList[ticket].id, 10) === parseInt(rewardId, 10)){
          var reward = this.state.ticketList[ticket]
          var rewardToAddObject = {};
          rewardToAddObject.reward = reward;
          rewardToAddObject.id = rewardId;
          rewardToAddObject.amount = rewardAmount;
          rewardToAddObject.codes = codes;
          rewardToAddObject.convertToQrCode = convertToQrCode;
          rewardToAddObject.codesArray = totalCodes;
        }
      }
      var array = this.state.rewardsAdded;
      var rewardsAddedIds = []
      for(var prize in array){
        rewardsAddedIds.push(array[prize].id)
      }
      if(rewardsAddedIds.indexOf(rewardId) === -1){
        array = array.concat(rewardToAddObject)
        this.setState({
          rewardsAdded: array,
          convertToQrCode: false,
          codes: false
        })
      } else {
        swal({
            title: 'Prize already added!',
            text: "You have already added this prize",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
      }
    }

    removeTicket(location){
      var attachedTicketArray = this.state.attachedTickets
      attachedTicketArray.splice(location, 1);
      this.setState({
        attachedTickets: attachedTicketArray,
      })
    }

    removeFromToAddArray(index){
      var rewardArray = this.state.rewardsAdded;
      rewardArray.splice(index, 1)
      this.setState({
        rewardsAdded: rewardArray
      })
    }

    resetGame(){
      var domainRoot = this.Auth.domain;
      swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will erase all rewards earned during this game! Only do this if no one has played yet.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES I WANT TO RESET THE GAME',
        }).then((result)=>{
          if(result.value){
            this.setState({loading:true})
            this.Auth.fetch(domainRoot + 'services/app/game/TenantResetGame', {
              method: 'POST',
              headers: {
      	         'Content-Type': 'application/json',
               },
            }).then(res => {
              this.setState({loading:false})
              if(res){
                if(res.apiStatus === "game_successful_reset"){
                  this.getCurrentGame();
                  this.getCurrentGameStats();
                  this.getRewards();
                } else {
                  swal({
                      title: 'There was some error!',
                      text: 'Try again and if the problem persists try logging out and logging back in',
                      type: 'error',
                      confirmButtonText: 'Ok'
                    })
                }
              } else {
                swal({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in',
                    type: 'error',
                    confirmButtonText: 'Ok'
                  })
              }
            })
          }
        })
    }

    toggleAutoGame(){
        this.setState({
            autoGame: !this.state.autoGame
        })
    }

    navButtonClicked(direction){
      var currentLocation = this.state.wizardLocation;
      if(direction === 'prev' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      } else if(direction === 'prev' && currentLocation === 'third'){
        this.setState({
          wizardLocation: 'second'
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'first'){
        this.setState({
          wizardLocation: 'second',
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'third',
        })
        this.toggleWizardLocation('third', 'first', 'second')
      } else {
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      }
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2){
      this.setState({
        wizardLocation: tabClicked
      })
      document.getElementById(tabClicked).classList.add('active');
      document.getElementById(tabClicked).classList.add('show');
      document.getElementById(otherTab1).classList.remove('active');
      document.getElementById(otherTab1).classList.remove('show');
      document.getElementById(otherTab2).classList.remove('active');
      document.getElementById(otherTab2).classList.remove('show');
      document.getElementById(tabClicked+'1').classList.add('active');
      document.getElementById(tabClicked+'1').classList.add('show');
      document.getElementById(otherTab1+'1').classList.remove('active');
      document.getElementById(otherTab1+'1').classList.remove('show');
      document.getElementById(otherTab2+'1').classList.remove('active');
      document.getElementById(otherTab2+'1').classList.remove('show');
    }

    handlePageChange(pageNumber) {
        this.getRewards(pageNumber*100 - 100, 100)
        this.setState({activePage: pageNumber});
    }

    switchStatsPrizes(){
      if(this.state.showStats){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showStats').classList.add('active');
        this.setState({
          showStats:false,
          loading: true,
        }, ()=>{
          this.getCurrentGameStats();
          // this.setTimers();
        })
      }else{
        document.getElementById('showPrizes').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
        this.setState({
          showStats:true,
        }, ()=>{
          this.getRewards();
          this.turnOffTimers();
        })
      }
    }

    calculateOdds(difficulty){
      if(difficulty === "easy"){
        document.getElementById('easyOdds').classList.add('btn-primary');
        document.getElementById('mediumOdds').classList.remove('btn-primary');
        document.getElementById('hardOdds').classList.remove('btn-primary');
      } else if(difficulty === "medium"){
        document.getElementById('easyOdds').classList.remove('btn-primary');
        document.getElementById('mediumOdds').classList.add('btn-primary');
        document.getElementById('hardOdds').classList.remove('btn-primary');
      } else if(difficulty === "hard") {
        document.getElementById('easyOdds').classList.remove('btn-primary');
        document.getElementById('mediumOdds').classList.remove('btn-primary');
        document.getElementById('hardOdds').classList.add('btn-primary');
      } else {
        difficulty = null;
        document.getElementById('easyOdds').classList.remove('btn-primary');
        document.getElementById('mediumOdds').classList.remove('btn-primary');
        document.getElementById('hardOdds').classList.remove('btn-primary');
      }

      if(difficulty){
        this.setState({
          losingTickets: difficulty,
          otherNumber: 0
        })
      } else {
        this.setState({
          losingTickets: difficulty,
        })
      }
    }

    alertAboutLoading(){
      swal({
          title: 'Rewards Still Loading',
          text: 'We are setting the data up for download, please wait',
          type: 'warning',
          confirmButtonText: 'Ok'
        })
    }

    async setRewardRedeemed(reward, index){
      if(reward.isRedeemable){
        const response = await swal({
              title: 'Hold on!',
              text: "This prizes redemption process is handled automatically by Sqwad, are you sure you want to toggle this?",
              type: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Toggle'
          })
          if(!response.value){
              return
          }
      }
      let formData = new FormData();
      formData.append('RewardId', reward.id);
      this.setState({loading:true})
      this.Auth.fetch(this.Auth.domain + 'services/app/Reward/IsRedeemed', {
        method: 'POST',
        body: formData,
      }).then(res => {
        if(res){
          if(res.apiStatus === "reward_redeemed"){
            this.getRewards()
            var currentGameRewardsArray = this.state.currentGameRewards
            currentGameRewardsArray[index].isRedeemed = !currentGameRewardsArray[index].isRedeemed;
            this.setState({
              currentGameRewards: currentGameRewardsArray
            })
            // this.getCurrentGame();
          } else {
            this.setState({loading:false})
            swal({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try logging out and logging back in',
                type: 'error',
                confirmButtonText: 'Ok'
              })
          }
        } else {
          this.setState({loading:false})
          swal({
              title: 'There was some error!',
              text: 'Try again and if the problem persists try logging out and logging back in',
              type: 'error',
              confirmButtonText: 'Ok'
            })
        }
      })
    }

    render() {
        let {scratcherGame={}, currentGameRewards, totalPrizes, otherNumber, currentGameActive, rewardsAdded=[], cardsPerPack, hasRewardWithAutoRedemptions=false, redemptions} = this.state;
        const selectedGame = scratcherGame || {};
        const teamLocation = window.location.pathname.split('/')[1] || "";
        let word_choice = "Code";
        if(teamLocation.indexOf("clevelandbrowns") !== -1){
            word_choice = "Gift Card"
        }
        let startTimeRemake = "";
        let endTimeRemake = "";
        let showTime = false;
        var totalAmountOfRewardsCreated = 0;
        var totalAmount = 0;
        for(var reward in rewardsAdded){
            totalAmountOfRewardsCreated = parseInt(totalAmountOfRewardsCreated) + parseInt(rewardsAdded[reward].amount)
        }
        if(parseInt(otherNumber) > 0 && parseInt(cardsPerPack) > 0 && parseInt(totalAmountOfRewardsCreated) > 0){
            totalAmount = parseInt(cardsPerPack) * parseInt(totalAmountOfRewardsCreated/(1/otherNumber))
            if(parseInt(otherNumber) === 1){
              totalAmount = totalAmountOfRewardsCreated
            }
        }
        var vm = this;
        const isMlbTeam = this.props.tenantVariables && this.props.tenantVariables.isMlbTeam;
        if(selectedGame.startTime && selectedGame.endTime){
            showTime = true
            if(selectedGame.startTime.indexOf("Z")===-1){
                startTimeRemake = selectedGame.startTime + "Z"
            } else {
                startTimeRemake= selectedGame.startTime
            }
            if(selectedGame.endTime.indexOf("Z")===-1){
                endTimeRemake = selectedGame.endTime + "Z"
            } else {
                endTimeRemake = selectedGame.endTime
            }
            startTimeRemake = new Date(new Date(startTimeRemake).getTime())
            endTimeRemake = new Date(new Date(endTimeRemake).getTime())
            var localTime = new Date(startTimeRemake).getTime()
            var formatedTimeSplit = new Date(localTime).toString().split(" ")
            formatedTimeSplit[4] = vm.toStandardTime(formatedTimeSplit[4])
            startTimeRemake = formatedTimeSplit.join(" ")
            localTime = new Date(endTimeRemake).getTime()
            formatedTimeSplit = new Date(localTime).toString().split(" ")
            formatedTimeSplit[4] = vm.toStandardTime(formatedTimeSplit[4])
            endTimeRemake = formatedTimeSplit.join(" ")
            endTimeRemake = cleanDate(endTimeRemake)
            startTimeRemake = cleanDate(startTimeRemake)
            if(new Date(endTimeRemake).getTime() <= new Date(startTimeRemake).getTime()){
              showTime = false;
            }
        }
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu tenantVariables={this.props.tenantVariables}/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                <div className="col-md-3 col-sm-12">
                  <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: this.state.scratcherGame && currentGameActive === true ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Scratchers Is Active</p>
                  </div>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: this.state.scratcherGame && currentGameActive === false ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Scratchers is Inactive</p>
                  </div>
                </div>
                <div className="mobile-spacer"/>
                <div className="col-md-3 col-sm-12">
                  <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: this.state.scratcherGame && currentGameActive === false ? '' : 'none', float:'left', height:'52px'}}>Start Game</button>
                  <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: this.state.scratcherGame && currentGameActive === true ? '' : 'none', float:'left', height:'52px' }}>Stop Game</button>
                </div>
                <div className="mobile-spacer"/>
                <div className="mobile-spacer"/>
                <div className="col-md-6 col-sm-12">
                  <a className="btn btn-outline-danger btn-sm reset-game-button" onClick={() => this.resetGame()} style={{display: this.state.scratcherGame ? '' : 'none'}}>Reset Game</a>
                  <button onClick={() => this.openCreateGameModal()} className="btn btn-primary btn-lg create-game-button" width="50px">New Game</button>
                </div>
              </div>
            </div>
            {selectedGame.gameName &&
                <>
                    <div style={{color: "black", marginLeft: 20, marginTop: 10, fontSize: 25}}>
                        {selectedGame.gameName}
                    </div>
                    <div style={{color: "black", marginLeft: 20, marginTop: 10, fontSize: 12}}>
                        Cards Per Pack: {selectedGame.rewardsPerPack}
                    </div>
                    {selectedGame.convertToQrCode &&
                        <div style={{color: "black", marginLeft: 20, marginTop: 10, fontSize: 12}}>
                            Can Play Every 24 hours: true
                        </div>
                    }
                    {selectedGame.prizeLimit && selectedGame.prizeLimit > 0 &&
                        <div style={{color: "black", marginLeft: 20, marginTop: 10, fontSize: 12}}>
                            Prize limit: {selectedGame.prizeLimit}
                        </div>
                    }
                </>
            }
            <div className="admin-grid-container two-columns" style={{marginTop:20}}>
              <div className="card" style={{display: showTime ? 'block' : 'none'}}>
                <div className="card-body">
                  <h4>Scheduled Start Time</h4>
                  <p>{startTimeRemake}</p>
                </div>
              </div>
              <div className="card" style={{display: showTime ? 'block' : 'none'}}>
                <div className="card-body">
                  <h4>Scheduled End Time </h4>
                  <p>{endTimeRemake}</p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card-body">
                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes()}>
                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                            <span className="d-lg-block">Stats</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes()}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-lg-block">Prizes</span>
                        </a>
                    </li>
                </ul>
                <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                  <p style={{color:'black', marginLeft:20, fontSize:20, marginTop:20}}><span className="mobile-hide">Reward Management</span>
                    <span className="gameBarExtraStyles">
                      {totalPrizes > 0 &&
                          <button onClick={()=> this.downloadUsers()} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                            <span className="fa fa-arrow-circle-down"/> Download Rewards
                          </button>
                      }
                        <div className="mobile-spacer"/>
                        <center className="mobile-show">
                        {currentGameRewards.length > 0
                          ?
                          <button style={{marginLeft:'10px', display: this.state.gotRewards && currentGameRewards.length === 0 ? 'none' : ''}} className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.getRewards()}>
                            Reload Rewards
                          </button>
                        :
                        <button style={{marginLeft:'10px', display: this.state.gotRewards }} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide" onClick={()=>this.alertAboutLoading()}>
                            <i className="fa fa-spinner fa-spin" aria-hidden="true"/> Loading
                        </button>
                        }
                      </center>
                      <span className="mobile-hide">
                      {currentGameRewards.length > 0
                        ?
                        <button style={{marginLeft:'10px', display: this.state.gotRewards && currentGameRewards.length === 0 ? 'none' : ''}} className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.getRewards()}>
                          Reload Rewards
                        </button>
                      :
                      <button style={{marginLeft:'10px', display: this.state.gotRewards }} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide" onClick={()=>this.alertAboutLoading()}>
                          <i className="fa fa-spinner fa-spin" aria-hidden="true"/> Loading
                      </button>
                      }
                      </span>
                      <div className="mobile-spacer"/>
                      <div className="mobile-spacer"/>
                      <div className="mobile-spacer"/>

                        <input type="text" id="searchQuery" name="search" placeholder="Filter Game Prizes" onChange={()=>{
                            const vm = this;
                            clearTimeout(this.timeoutId); // no-op if invalid id
                            this.timeoutId = setTimeout(async function(){
                                let input = document.getElementById('searchQuery').value;
                                await vm.getRewards(0, 100, input);
                            }, 500);
                          }
                        }/>
                    </span>
                  </p>
                    <div className="vertical-spacer"/>
                  <div className="table-responsive">
                    <table className="table table-striped" style={{color:'black', marginBottom:100}}>
                      <tbody>
                        <tr>
                            <th>Reward</th>
                            <th>{word_choice}</th>
                            <th>Time Stamp</th>
                            <th>Won?</th>
                            {!isMlbTeam &&
                                <th>Sent?</th>
                            }
                            <th>Winner {isMlbTeam? "Id": "Email"}</th>
                            {!isMlbTeam &&
                                <th>Winner Phone</th>
                            }
                            <th>Redeemed?</th>
                        </tr>
                        {
                          currentGameRewards.sort(function(x, y) {
                              return ('' + y.givenOut).localeCompare(x.givenOut);
                          }).map(function(item,i){
                              let redeemCode = item.redeemCode || "";
                              return <tr key={i}>
                                    <td style={{fontFamily:'Open Sans'}}>{item.rewardName}</td>
                                    <td style={{display: redeemCode ? 'block' : 'none', fontFamily:'Open Sans' }}>
                                      <strong>{word_choice}:</strong> {redeemCode}
                                      <br/>
                                      <strong style={{display: item.redeemPin ? '' : 'none'}}>Pin:</strong> {item.redeemPin}
                                    </td>
                                    <td style={{display: !item.redeemCode ? 'block' : 'none', fontFamily:'Open Sans' }}><strong>No {word_choice} Required</strong></td>
                                    <td style={{fontFamily:'Open Sans'}}>{item.timeStamp}</td>
                                    <td style={{fontFamily:'Open Sans'}}>{item.givenOut}</td>
                                    {!isMlbTeam &&
                                        <td style={{fontFamily: 'Open Sans'}}>{item.winningEmailSent}</td>
                                    }
                                    <td style={{fontFamily:'Open Sans'}}>{item.winningEmailAddress}</td>
                                    {!isMlbTeam &&
                                        <td style={{fontFamily: 'Open Sans'}}>{item.number}</td>
                                    }
                                    {/*{item ?*/}
                                    {/*    <td style={{fontFamily: 'Open Sans'}}>{item.isRedeemed ? "Yes" : "No"}</td>*/}
                                    {/*    :*/}
                                        <td style={{fontFamily:'Open Sans'}}>
                                            <input type="checkbox" id={i} data-switch="success" checked={item.isRedeemed} onClick={()=>this.setRewardRedeemed(item, i)} readOnly/>
                                            <label htmlFor={i} data-on-label="Yes" data-off-label="No"/>
                                        </td>
                                    {/*}*/}
                              </tr>
                          }, this)
                        }
                        <div style={{margin:10}}>
                            Page:
                            <Pagination
                                hideDisabled
                                hideFirstLastPages
                                itemClassNext={currentGameRewards.length < 100? "" : "page-item"}
                                linkClassNext={currentGameRewards.length < 100? "" : "page-link"}
                                nextPageText={currentGameRewards.length < 100? "" : "⟩"}
                                itemClassLast={"page-item"}
                                linkClassPrev={"page-link"}
                                activeLinkClass={"page-item page-link"}
                                activePage={this.state.activePage}
                                itemsCountPerPage={100}
                                totalItemsCount={this.state.totalPrizes}
                                pageRangeDisplayed={1}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div style={{display: !this.state.showStats ? 'block' : 'none'}}>
                  <div align="right">
                    <button onClick={() => this.updateStats()} className="refresh-button-styles btn btn-primary btn-lg refresh-button"><span className="fa fa-refresh"/> Refresh</button>
                  </div>
                  <div className="admin-grid-container three-columns" style={{marginTop:20}}>
                    <div className="card card-styles text-xs-center">
                        <div className="card-body">
                            <blockquote className="card-bodyquote">
                                <p>Current Game Players</p>
                                <footer className="value-text">{this.state.players}</footer>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center">
                        <div className="card-body">
                            <blockquote className="card-bodyquote">
                                <p>Current Prizes Won</p>
                                <footer className="value-text">{this.state.prizesWon}</footer>
                            </blockquote>
                        </div>
                    </div>
                      {hasRewardWithAutoRedemptions &&
                          <div className="card card-styles text-xs-center">
                              <div className="card-body">
                                  <blockquote className="card-bodyquote">
                                      <p>Redemptions</p>
                                      <footer className="value-text">{redemptions}</footer>
                                  </blockquote>
                              </div>
                          </div>
                      }
                    {/*<div className="card card-styles text-xs-center">*/}
                    {/*    <div className="card-body">*/}
                    {/*        <blockquote className="card-bodyquote">*/}
                    {/*            <p>Cards Per Pack</p>*/}
                    {/*            <footer className="value-text">{selectedGame.rewardsPerPack}</footer>*/}
                    {/*        </blockquote>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3"> Create Game</h4>
                  <div id="rootwizard">
                    <ul className="nav nav-pills bg-dark-light nav-justified form-wizard-header mb-3">
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'second', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                                <span className="fa fa-pencil-square-o"/>
                                <span className="d-none d-sm-inline"> The Basics</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'first', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                                <span className="fa fa-trophy"/>
                                <span className="d-none d-sm-inline"> Add Prizes</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                                <span className="fa fa-sign-out"/>
                                <span className="d-none d-sm-inline"> Set Difficulty</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>

                        <div className="tab-pane active show" id="first">
                            <form id="accountForm" className="form-horizontal">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mb-3">
                                            <label style={{marginBottom: 0}} htmlFor="gameName">Game Name</label>
                                            <p style={{marginBottom: 0, fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>An identifier to help you identify the game</p>
                                            <input id="gameName" name="gameName" className="form-control" value={this.state.gameName} onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="userName3">Set Auto Start/End Time?</label>
                                            <label className="switch" style={{marginTop:'10px'}}>
                                              <input type="checkbox" onClick={this.toggleAutoGame} checked={this.state.autoGame}/>
                                              <span className="slider round"/>
                                            </label>
                                        </div>
                                        <style dangerouslySetInnerHTML={{
                                            __html: [
                                                '.react-datepicker-time__input {',
                                                'width: 100% !important;',
                                                '}'
                                            ].join('\n')
                                        }}/>
                                        <div className="form-group row mb-3 form_times" style={{display: this.state.autoGame? '':'none'}}>
                                            <label className="col-md-3 col-form-label" htmlFor="password3"> Game Start Time</label>
                                            <div className="col-md-9">
                                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.gameStartTime} onChange={date => this.setState({gameStartTime: date})}/>
                                            </div>
                                        </div>

                                        <div className="form-group row mb-3 form_times" style={{display: this.state.autoGame? '':'none'}}>
                                            <label className="col-md-3 col-form-label" htmlFor="confirm3"> Game End Time</label>
                                            <div className="col-md-9">
                                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.gameEndTime} onChange={date => this.setState({gameEndTime: date})}/>
                                            </div>
                                        </div>

                                        <div className="checkbox">
                                            <label>
                                                Allow Multiple Plays (Every 24 hrs) <input value={this.state.allowMoreScratchersAfterTime} name="allowMoreScratchersAfterTime" type="checkbox" checked={this.state.allowMoreScratchersAfterTime} onChange={this.handleChange} />
                                            </label>
                                        </div>

                                        <div className="checkbox">
                                            <label>
                                                Limit Prizes Given Out In 24 hrs <input value={this.state.limitPrizesGivenOut} name="limitPrizesGivenOut" type="checkbox" checked={this.state.limitPrizesGivenOut} onChange={this.handleChange} />
                                            </label>
                                        </div>

                                        {this.state.limitPrizesGivenOut &&
                                            <div className="form-group row mb-3">
                                                <label className="col-md-3 col-form-label" htmlFor="surname3">Prize Limit:</label>
                                                <div className="col-md-9">
                                                    <input id="prizeLimit" name="prizeLimit" type="number" className="form-control" value={this.state.prizeLimit} onChange={this.handleChange} placeholder="5" />
                                                </div>
                                            </div>
                                        }

                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="confirm3"> Cards Per Pack</label>
                                            <div className="col-md-9">
                                                <input id="cardsPerPack" name="cardsPerPack" type="number" className="form-control" value={this.state.cardsPerPack} onChange={this.handleChange} required=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="tab-pane" id="second">
                            <div className="form-horizontal">
                                {/*{process.env.NODE_ENV === "development" &&*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-12">*/}
                                {/*            <div className="checkbox">*/}
                                {/*                <label>*/}
                                {/*                    Add Redemption Process <input value={this.state.redemptionProcess} name="redemptionProcess" type="checkbox" checked={this.state.redemptionProcess} onChange={this.handleChange} />*/}
                                {/*                </label>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*}*/}
                                {/*{this.state.redemptionProcess &&*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-12">*/}
                                {/*            <div className="checkbox">*/}
                                {/*                <label>*/}
                                {/*                    Add Redemption Code (To Be entered by you or your staff upon redemption) <input value={this.state.redemptionCodeActive} name="redemptionCodeActive" type="checkbox" checked={this.state.redemptionCodeActive} onChange={this.handleChange} />*/}
                                {/*                </label>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*}*/}
                                {/*{this.state.redemptionProcess && this.state.redemptionCodeActive &&*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-12">*/}
                                {/*            <div className="form-group mb-3">*/}
                                {/*                <label style={{marginBottom: 0}} htmlFor="redemptionCode">Redemption Code</label>*/}
                                {/*                <p style={{marginBottom: 0, fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>A code that you or your staff will enter when presented the reward screen by a fan</p>*/}
                                {/*                <input id="redemptionCode" name="redemptionCode" className="form-control" value={this.state.redemptionCode} onChange={this.handleChange} />*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*}*/}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="name3"> Select Reward</label>
                                            <div className="col-md-9">
                                              <select className="form-control" onChange={this.saveReward}>
                                                <option/>
                                                {
                                                  this.state.ticketList.map(function(item,index){
                                                    return(
                                                      <option value={item.id} key={index} style={{display: item.isWinning ? 'block' : 'none'}}>{item.rewardName}</option>
                                                    )
                                                  })
                                                }
                                              </select>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="surname3"> How Many Of This Reward To Give Out?</label>
                                            <div className="col-md-9">
                                                <input id="rewardAmount" name="rewardAmount" type="number" className="form-control" value={this.state.rewardAmount} onChange={this.handleChange} placeholder="5" />
                                            </div>
                                        </div>

                                        <div className="checkbox">
                                            <label>
                                                Redeem {word_choice}s? <input value={this.state.codes} name="codes" type="checkbox" checked={this.state.codes} onChange={this.handleChange} />
                                            </label>
                                        </div>

                                        {!isMlbTeam &&
                                            <div className="checkbox" style={{display: this.state.codes ? 'flex' : 'none'}}>
                                                <label>
                                                    Convert {word_choice}s To QRCode? <input value={this.state.convertToQrCode} name="convertToQrCode" type="checkbox" checked={this.state.convertToQrCode} onChange={this.handleChange} />
                                                </label>
                                            </div>
                                        }

                                        <div className="form-group row mb-3" style={{display: this.state.codes ? 'flex' : 'none' }}>
                                          <label htmlFor="codesArray" className="col-md-3 col-form-label">Enter {word_choice}s (1234,1238,1230)</label>
                                          <div className="col-md-9">
                                            <textarea value={this.state.codesArray} className="form-control" name="codesArray" onChange={this.handleChange} placeholder="1235,1238,1230"/>
                                          </div>
                                        </div>

                                        {!isMlbTeam &&
                                            <div className="form-group row mb-3"
                                                 style={{display: this.state.codes ? 'flex' : 'none'}}>
                                                <label htmlFor="codesArray" className="col-md-3 col-form-label">Enter Corresponding Pins (OPTIONAL)</label>
                                                <div className="col-md-9">
                                                    <textarea value={this.state.pinCodes} className="form-control" name="pinCodes" onChange={this.handleChange} placeholder="pin1,pin2,pin3"/>
                                                </div>
                                            </div>
                                        }

                                        <div className="form-group row mb-3" align="center">
                                          <div className="col-md-12">
                                            <button className="btn btn-primary btn-admin" onClick={()=>this.addRewardToLocalArray()}>Add Reward</button>
                                          </div>
                                        </div>

                                        <div className="form-group mb-3">
                                            <ol style={{listStylePosition: 'inside', textAlign:'left'}} className="offset-md-0 col-md-10">
                                              {
                                                rewardsAdded.map(function(item,index){
                                                  let codesText = "No"
                                                  if(item.codes){
                                                    codesText = "Yes"
                                                    if(item.convertToQrCode){
                                                      codesText = "Yes; QrCodes: Yes"
                                                    } else if(!isMlbTeam) {
                                                        codesText = codesText + "; QrCodes: No";
                                                    }
                                                  }
                                                  return(
                                                    <div key={index} className="form-group">
                                                      <li>) {item.amount} - {item.reward.rewardName}; {word_choice}s: {codesText} <span style={{float:'right'}} className="fa fa-trash-o" onClick={()=>vm.removeFromToAddArray(index)}/></li>
                                                    </div>
                                                  )
                                                })
                                              }
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="third">
                            <form id="otherForm" method="post" action="#" className="form-horizontal"/>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-center">
                                            <h2 className="mt-0">
                                                <i className="mdi mdi-check-all"/>
                                            </h2>
                                            <h3 className="mt-0">How easy do you want to make it to win prizes?</h3>

                                            <p className="w-75 mb-2 mx-auto" style={{fontFamily:'Open Sans'}}>Select Difficulty</p>

                                            <div className="row form-group">
                                              {/*<div className="col-md-2 col-sm-12">
                                                <button className="btn btn-cards-created" style={{border:"1px solid black", whiteSpace:"normal"}} id="ogOdds" onClick={()=>this.calculateOdds("original")}>
                                                  <p>Original</p>
                                                  <p>Keep the odds the same as before</p>
                                                </button>
                                              </div>*/}
                                              <div className="col-md-3 col-sm-12">
                                                <button className="btn btn-cards-created" style={{border:"1px solid black", whiteSpace:"normal"}} id="easyOdds" onClick={()=>this.calculateOdds("easy")}>
                                                  <p>Easy</p>
                                                  <p>About 1/3 players win</p>
                                                </button>
                                              </div>
                                              <div className="mobile-spacer"/>
                                              <div className="col-md-3 col-sm-12">
                                                <button className="btn btn-cards-created" style={{border:"1px solid black", whiteSpace:"normal"}} id="mediumOdds" onClick={()=>this.calculateOdds("medium")}>
                                                  <p>Medium</p>
                                                  <p>About 1/30 players win</p>
                                                </button>
                                              </div>
                                              <div className="mobile-spacer"/>
                                              <div className="col-md-3 col-sm-12">
                                                <button className="btn btn-cards-created" style={{border:"1px solid black", whiteSpace:"normal"}} id="hardOdds" onClick={()=>this.calculateOdds("hard")}>
                                                  <p>Hard</p>
                                                  <p>About 1/300 players win</p>
                                                </button>
                                              </div>
                                              <div className="mobile-spacer"/>
                                              <div className="col-md-3 col-sm-12">
                                                <center className="mobile-border-other">
                                                  <label className="form-label" htmlFor="otherNumber">Other Amount: 1/</label>
                                                  <input style={{border:"1px solid black"}} value={this.state.otherNumber} name="otherNumber" type="number" min="0" onChange={this.handleChange}/>
                                                  <p>Other Total Tickets Created: {totalAmount}</p>
                                                </center>
                                                {/*<button className="btn btn-cards-created" style={{border:"1px solid black", whiteSpace:"normal"}} id="zeroOdds" onClick={()=>this.calculateOdds("other")}>
                                                  <p>Everyone Wins</p>
                                                  <p>All prizes are given out before losers are sent</p>
                                                </button>*/}
                                              </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                        </div>

                        <ul className="list-inline wizard mb-0">
                            <li className="next list-inline-item">
                                <a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.toggle()} style={{display: this.state.wizardLocation === 'first' ? '' : 'none' }}>
                                    Close
                                </a>
                            </li>
                            <li className="previous list-inline-item" style={{display: this.state.wizardLocation === 'first' ? 'none' : '' }}>
                                <a href="#" className="btn btn-primary btn-lg previous-wizard-button" onClick={() => this.navButtonClicked('prev')}>
                                    Previous
                                </a>
                            </li>
                            <li className="next list-inline-item float-right">
                                <a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation === 'third' ? 'none' : '' }}>
                                    Next
                                </a>
                            </li>
                            <li className="next list-inline-item float-right">
                                <a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'third' ? '' : 'none' }}>
                                    Create Game
                                </a>
                            </li>
                        </ul>

                      </div>
                    </div>
                </div>
              </div>
          </Modal>
       </div>
      );
    }
}

export default SetUpScratcherGame;
