import React, { Component } from 'react'
import '../../styles/css/SideMenu.css'
import '../../styles/css/AdminMain.css'
import logoWithTextImage from '../../styles/images/sqwad_white_logo_w_text.png';
import { Link } from "react-router-dom";
import AuthService from '../utils/AuthService';

class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.logOut = this.logOut.bind(this)
    }

    closeMenu(){
      const sideBar = document.getElementsByClassName('admin-sidebar')[0]
      sideBar.style.display = "none"
    }

    logOut() {
        this.Auth.logout();
    }

    render() {
        const teamLocation = window.location.pathname.split('/')[1] || "";
        const tenantVariables = this.props.tenantVariables || {};
        const teamName = localStorage.getItem('team'+teamLocation)
        const homeLink = "/" + teamName + "/adminHome"
        const scratcherGameLink = "/" + teamName + "/setupscratchergame"
        const ticketsLink = "/" + teamName + "/setupscratchertickets"
        const teamVariables = "/" + teamName + "/setupteamvariables"
        const rulesAndRegs = "/" + teamName + "/setuprulesandregs"
        const ticketEmail = "/" + teamName + "/setupticketemail"
        const setUpAuth = "/" + teamName + "/setupauthentication"
        const tutorialsLink = "/" + teamName + "/tutorials"
        return (
          <div className="admin-sidebar">
            <div className="mobile-show close-button-container" onClick={this.closeMenu}>
              <span className="fa fa-close mobile-show close-button"/>
            </div>
            <div className="logo">
              <a href={homeLink}>
                <div className="logo-img"><img src={logoWithTextImage} height="30" alt=""/></div>
              </a>
            </div>
            <div className="nav-container">
              <ul className="nav">
                <li className="side-menu-items">
                  <Link to={homeLink}>
                    <span className="fa fa-home pre_side_item"/>
                    <span className="">Dashboard</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </Link>
                </li>
                <li className="">
                  <Link to={scratcherGameLink}>
                    <span className="fa fa-gamepad pre_side_item"/>
                    <span className="">Games</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </Link>
                </li>
                <li className="">
                  <Link to={ticketsLink}>
                    <span className="fa fa-trophy pre_side_item"/>
                    <span className="">Prizes</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </Link>
                </li>
                <li className="">
                  <Link to={teamVariables}>
                    <span className="fa fa-pencil-square-o pre_side_item"/>
                    <span className="">Game Branding</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </Link>
                </li>
                <li className="">
                  <Link to={rulesAndRegs}>
                    <span className="fa fa-legal pre_side_item"/>
                    <span className="">Rules And Regs</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </Link>
                </li>
                <li className="">
                  <Link to={ticketEmail}>
                    <span className="fa fa-envelope-open-o pre_side_item"/>
                    <span className="text">{tenantVariables.isMlbTeam?"Message":"Email"} Branding</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </Link>
                </li>
                <li className="">
                  <Link to={setUpAuth}>
                      <span className="fa fa-user-circle pre_side_item"/>
                      <span className="text">Fan Login</span>
                      <span className="fa fa-chevron-right after_fa_side"/>
                  </Link>
                </li>
                {!tenantVariables.isMlbTeam &&
                    <li className="">
                      <Link to={tutorialsLink}>
                          <span className="fa fa-question pre_side_item"/>
                          <span className="text">Help/Style Guide</span>
                          <span className="fa fa-chevron-right after_fa_side"/>
                      </Link>
                    </li>
                }
                <div className="bottom-buttons">
                  {/*<li className="" onClick={() => window.open('https://sqwadsports.zendesk.com/hc/en-us', '_blank')}>
                    <a href="#">
                      <span className="fa fa-life-ring pre_side_item"></span>
                      <span className="text">Support</span>
                      <span className="fa fa-chevron-right after_fa_side"></span>
                    </a>
                  </li>*/}
                  <li className="" onClick={this.logOut}>
                    <a href={homeLink}>
                      <span className="fa fa-sign-out pre_side_item"/>
                      <span className="text">Logout</span>
                      <span className="fa fa-chevron-right after_fa_side"/>
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </div>

        );
    }
}

export default SideMenu
