import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import AuthService from '../utils/AuthService';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import RichTextEditor from 'html-verify-react-rte';
import { validateEmail } from '../utils/HelpfulFunction';
const isValidDomain = require('is-valid-domain');

// import Geocode from "react-geocode";
// import GoogleMapReact from 'google-map-react';

const google = window.google;
let geocoder;
if(google){
    geocoder = new google.maps.Geocoder();
}

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
// console.log(process.env.REACT_APP_GOOGLE_MAPS)
// Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS);


class SetUpFanAuth extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        const teamLocation = window.location.pathname.split('/')[1] || "";
        let nameInEmail = true;
        let nameInEmailStorage = localStorage.getItem('nameInEmail'+teamLocation)
        if(nameInEmailStorage === "false" || nameInEmailStorage === "null" || !nameInEmailStorage || nameInEmailStorage === "undefined"){
            nameInEmail = false;
        }
        let collectUserName = true;
        let name = localStorage.getItem('collectUserName'+teamLocation)
        if(name === "false" || name === "null"){
            collectUserName = false;
        }
        let collectEmail = true;
        let email = localStorage.getItem('collectEmail'+teamLocation)
        if(email === "false" || email === "null"){
          collectEmail = false;
        }
        let anonymousLogin = true;
        let anonymousLoginStorage = localStorage.getItem('anonymousLogin'+teamLocation)
        if(anonymousLoginStorage === "false" || anonymousLoginStorage === "null" || !anonymousLoginStorage || anonymousLoginStorage === "undefined"){
            anonymousLogin = false;
        }
        let authRequired = true;
        let authRequiredStorage = localStorage.getItem('authRequired'+teamLocation)
        if(authRequiredStorage === "false" || authRequiredStorage === "null" || !authRequiredStorage || authRequiredStorage === "undefined"){
            authRequired = false;
        }
        let collectAge = true;
        let age_collect = localStorage.getItem('collectAge'+teamLocation)
        if(age_collect === "false" || age_collect === "null" || age_collect === "undefined"){
          collectAge = false;
        }
        let ageToVerify = localStorage.getItem('ageToVerify'+teamLocation)
        if(ageToVerify === "false" || ageToVerify === "null" || ageToVerify === "0"){
          ageToVerify = 13;
        }
        let acceptableDistance = localStorage.getItem('acceptableDistance'+teamLocation)
        if(acceptableDistance === "false" || acceptableDistance === "null" || acceptableDistance === "0"){
          acceptableDistance = null;
        }
        let formattedAddress = localStorage.getItem('formattedAddress'+teamLocation)
        if(formattedAddress === "false" || formattedAddress === "null" || formattedAddress === "0"){
          formattedAddress = null;
        }
        let blockSanitizedEmails = localStorage.getItem('blockSanitizedEmails'+teamLocation)
        if(blockSanitizedEmails === "false" || blockSanitizedEmails === "null" || blockSanitizedEmails === "0" || !blockSanitizedEmails){
            blockSanitizedEmails = false;
        }
        let collectOptInNotDefaultCheck = localStorage.getItem('collectOptInNotDefaultCheck' + teamLocation) || false;
        if(collectOptInNotDefaultCheck === "false" || collectOptInNotDefaultCheck === "null" || collectOptInNotDefaultCheck === "undefined" || !collectOptInNotDefaultCheck){
            collectOptInNotDefaultCheck = false;
        }
        let collectOptInTwoNotDefaultCheck = localStorage.getItem('collectOptInTwoNotDefaultCheck' + teamLocation) || false;
        if(collectOptInTwoNotDefaultCheck === "false" || collectOptInTwoNotDefaultCheck === "null" || collectOptInTwoNotDefaultCheck === "undefined" || !collectOptInTwoNotDefaultCheck){
            collectOptInTwoNotDefaultCheck = false;
        }
        let collectOptIn = true;
        let opt_in_collect = localStorage.getItem('collectOptIn'+teamLocation)
        if(opt_in_collect === "false" || opt_in_collect === "null" || opt_in_collect === "undefined" || !opt_in_collect){
          collectOptIn = false;
        }
        let collectOptInTwo = true;
        let opt_in_collect_two = localStorage.getItem('collectOptInTwo'+teamLocation)
        if(opt_in_collect_two === "false" || opt_in_collect_two === "null" || opt_in_collect_two === "undefined" || !opt_in_collect_two){
            collectOptInTwo = false;
        }
        let canPlayOutsideAllowed = true;
        let canPlayOutside = localStorage.getItem('canPlayOutside'+teamLocation)
        if(canPlayOutside === "false" || canPlayOutside === "null" || canPlayOutside === "undefined" || !canPlayOutside){
            canPlayOutsideAllowed = false;
        }
        let locationCheckFailedCanPlayAllowed = true;
        let locationCheckFailedCanPlay = localStorage.getItem('locationCheckFailedCanPlay'+teamLocation)
        if(locationCheckFailedCanPlay === "false" || locationCheckFailedCanPlay === "null" || locationCheckFailedCanPlay === "undefined" || !locationCheckFailedCanPlay){
            locationCheckFailedCanPlayAllowed = false;
        }
        let optInText = localStorage.getItem('optInText'+teamLocation)
        if(optInText === "false" || optInText === "null" || optInText === ""){
          optInText = "Check this box to opt-in to communication from us";
        }
        let optInTextTwo = localStorage.getItem('optInTextTwo'+teamLocation)
        if(optInTextTwo === "false" || optInTextTwo === "null" || optInTextTwo === ""){
            optInTextTwo = "Check this box to opt-in to communication from us";
        }
        let collectZipCode = localStorage.getItem('collectZipCode'+teamLocation)
        if(collectZipCode === "false" || collectZipCode === "null" || collectZipCode === "undefined"){
          collectZipCode = false;
        }
        let collectPhoneNumber = localStorage.getItem('collectPhoneNumber'+teamLocation);
        if(collectPhoneNumber === "false" || collectPhoneNumber === "null" || collectPhoneNumber === "undefined"){
            collectPhoneNumber = false;
        }
        let collectDistance = localStorage.getItem('collectDistance'+teamLocation)
        if(collectDistance === "false" || collectDistance === "null" || collectDistance === "undefined"){
          collectDistance = false;
        }
        let showRewardsOutMessage = localStorage.getItem('showRewardsOutMessage'+teamLocation)
        if(showRewardsOutMessage === "false" || showRewardsOutMessage === "null" || showRewardsOutMessage === "undefined"){
            showRewardsOutMessage = false;
        }
        let preventLoginIfNoPrizes = localStorage.getItem('preventLoginIfNoPrizes' + teamLocation)
        if(preventLoginIfNoPrizes === "false" || preventLoginIfNoPrizes === "null" || preventLoginIfNoPrizes === "undefined" || !preventLoginIfNoPrizes){
            preventLoginIfNoPrizes = false
        }
        let accessibilityOn = localStorage.getItem('accessibilityOn' + teamLocation);
        if(accessibilityOn === "false" || accessibilityOn === "null" || accessibilityOn === "undefined" || !accessibilityOn){
            accessibilityOn = false
        }
        let onePrizeInTimePeriod = localStorage.getItem('onePrizeInTimePeriod'+teamLocation)
        if(onePrizeInTimePeriod === "false" || onePrizeInTimePeriod === "null" || onePrizeInTimePeriod === "undefined"){
            onePrizeInTimePeriod = false;
        }
        let blockedZipCodes = localStorage.getItem('blockedZipCodes'+teamLocation)
        if(blockedZipCodes === "false" || blockedZipCodes === "null" || blockedZipCodes === "" || collectDistance === "undefined"){
          blockedZipCodes = "";
        }
        let notAcceptableLocationMessage = localStorage.getItem('notAcceptableLocationMessage'+teamLocation)
        if(notAcceptableLocationMessage === "false" || notAcceptableLocationMessage === "null" || notAcceptableLocationMessage === "" || notAcceptableLocationMessage === "undefined"){
            notAcceptableLocationMessage = RichTextEditor.createEmptyValue();
        } else {
            notAcceptableLocationMessage = RichTextEditor.createValueFromString(notAcceptableLocationMessage, 'html');
        }
        let notAcceptableLocationHeader = localStorage.getItem('notAcceptableLocationHeader'+teamLocation)
        if(notAcceptableLocationHeader === "false" || notAcceptableLocationHeader === "null" || notAcceptableLocationHeader === "" || notAcceptableLocationHeader === "undefined"){
            notAcceptableLocationHeader = "";
        }
        let locationPermissionsBody = localStorage.getItem('locationPermissionsBody'+teamLocation)
        if(locationPermissionsBody === "false" || locationPermissionsBody === "null" || locationPermissionsBody === "" || locationPermissionsBody === "undefined"){
          locationPermissionsBody = "We need your location in order to play! We use this information to make your experience better";
        }
        let locationPermissionsHeader = localStorage.getItem('locationPermissionsHeader'+teamLocation)
        if(locationPermissionsHeader === "false" || locationPermissionsHeader === "null" || locationPermissionsHeader === "" || locationPermissionsHeader === "undefined"){
          locationPermissionsHeader = "Location Permissions Required";
        }
        let onePrizeStartTime = localStorage.getItem('onePrizeStartTime'+teamLocation)
        if(onePrizeStartTime === "false" || onePrizeStartTime === "null" || onePrizeStartTime === "" || onePrizeStartTime === "undefined"){
            onePrizeStartTime = null;
        }
        let onePrizeEndTime = localStorage.getItem('onePrizeEndTime'+teamLocation)
        if(onePrizeEndTime === "false" || onePrizeEndTime === "null" || onePrizeEndTime === "" || onePrizeEndTime === "undefined"){
            onePrizeEndTime = null;
        }
        this.state = {
            collectUserName: collectUserName,
            collectEmail: collectEmail,
            anonymousLogin: anonymousLogin,
            authRequired: authRequired,
            collectAge: collectAge,
            collectDistance: collectDistance,
            onePrizeInTimePeriod: onePrizeInTimePeriod,
            showRewardsOutMessage: showRewardsOutMessage,
            collectOptIn: collectOptIn,
            collectOptInTwo: collectOptInTwo,
            collectOptInTwoNotDefaultCheck: collectOptInTwoNotDefaultCheck,
            collectOptInNotDefaultCheck: collectOptInNotDefaultCheck,
            collectPhoneNumber: collectPhoneNumber,
            canPlayOutside: canPlayOutsideAllowed,
            locationCheckFailedCanPlay: locationCheckFailedCanPlayAllowed,
            ageToVerify: ageToVerify,
            formattedAddress: formattedAddress,
            acceptableDistance: acceptableDistance,
            optInText: optInText,
            optInTextTwo: optInTextTwo,
            advanced: onePrizeInTimePeriod || collectDistance || showRewardsOutMessage || anonymousLogin || accessibilityOn,
            collectZipCode: collectZipCode,
            blockedZipCodes: blockedZipCodes,
            locationPermissionsBody: locationPermissionsBody,
            locationPermissionsHeader: locationPermissionsHeader,
            onePrizeStartTime: onePrizeStartTime,
            onePrizeEndTime: onePrizeEndTime,
            notAcceptableLocationMessage: notAcceptableLocationMessage,
            notAcceptableLocationHeader: notAcceptableLocationHeader,
            blockList: "",
            allowList: "",
            blockSanitizedEmails: blockSanitizedEmails,
            preventLoginIfNoPrizes: preventLoginIfNoPrizes,
            nameInEmail: nameInEmail,
            accessibilityOn: accessibilityOn,
            loading: true
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value
        });
    }

    selectName(){
      let formData = new FormData();
      var domainRoot = this.Auth.domain;
      this.setState({loading:true})
      this.Auth.fetch(domainRoot + 'services/app/Tenant/TurnOnUserFirstAndLast', {
        method: 'POST',
        body: formData,
      }).then( res => {
        if(res && res.apiStatus === "changed_collection_user"){
          this.setState({loading:false})
          var teamLocation = window.location.pathname.split('/')[1] || "";
          this.setState({
            collectUserName:res.entity,
          })
          localStorage.setItem('collectUserName'+teamLocation, res.entity)
          swal({
              title: 'Fan Auth Updated',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
        } else {
          swal({
              title: 'Hold on!',
              text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          this.setState({loading:false})
        }
      })
    }

    selectNameInEmail(){
        let formData = new FormData();
        var domainRoot = this.Auth.domain;
        this.setState({loading:true})
        this.Auth.fetch(domainRoot + 'services/app/Tenant/ToggleNameInEmail', {
            method: 'POST',
            body: formData,
        }).then( res => {
            console.log(res)
            if(res && res.apiStatus === "changed_name_in_email"){
                this.setState({loading:false})
                var teamLocation = window.location.pathname.split('/')[1] || "";
                this.setState({
                    nameInEmail:res.entity,
                })
                localStorage.setItem('nameInEmail'+teamLocation, res.entity)
                swal({
                    title: 'Fan Auth Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                swal({
                    title: 'Hold on!',
                    text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                this.setState({loading:false})
            }
        })
    }

    toggleAccessibility(){
        let formData = new FormData();
        var domainRoot = this.Auth.domain;
        this.setState({loading:true})
        this.Auth.fetch(domainRoot + 'services/app/Tenant/ToggleAccessibilityOn', {
            method: 'POST',
            body: formData,
        }).then( res => {
            if(res && res.apiStatus === "set_accessibility_successfully"){
                this.setState({loading:false})
                var teamLocation = window.location.pathname.split('/')[1] || "";
                this.setState({
                    accessibilityOn:res.entity,
                })
                localStorage.setItem('accessibilityOn'+teamLocation, res.entity)
                swal({
                    title: 'Fan Auth Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                swal({
                    title: 'Hold on!',
                    text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                this.setState({loading:false})
            }
        })
    }

    selectEmail(){
      let formData = new FormData();
      var domainRoot = this.Auth.domain;
      this.setState({loading:true})
      this.Auth.fetch(domainRoot + 'services/app/Tenant/ToggleCollectEmail', {
        method: 'POST',
        body: formData,
      }).then( res => {
        if(res && res.apiStatus === "changed_collection_email"){
          this.setState({loading:false})
          var teamLocation = window.location.pathname.split('/')[1] || "";
          this.setState({
            collectEmail:res.entity,
          })
          localStorage.setItem('collectEmail'+teamLocation, res.entity)
          swal({
              title: 'Fan Auth Updated',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
        } else {
          swal({
              title: 'Hold on!',
              text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          this.setState({loading:false})
        }
      })
    }

    selectAnonymous(){
        let formData = new FormData();
        var domainRoot = this.Auth.domain;
        this.setState({loading:true})
        this.Auth.fetch(domainRoot + 'services/app/Tenant/ToggleAnonymousLogin', {
            method: 'POST',
            body: formData,
        }).then( res => {
            if(res && res.apiStatus === "changed_anonymous_login"){
                this.setState({loading:false})
                var teamLocation = window.location.pathname.split('/')[1] || "";
                this.setState({
                    anonymousLogin:res.entity,
                })
                localStorage.setItem('anonymousLogin'+teamLocation, res.entity)
                swal({
                    title: 'Fan Auth Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                swal({
                    title: 'Hold on!',
                    text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                this.setState({loading:false})
            }
        })
    }

    selectCollectPhoneNumber(){
        let formData = new FormData();
        this.setState({loading:true})
        this.Auth.fetch(this.Auth.domain + 'services/app/Tenant/ToggleCollectPhoneNumber', {
            method: 'POST',
            body: formData,
        }).then( res => {
            this.setState({loading:false})
            if(res && res.apiStatus === "changed_phone_number"){
                const teamLocation = window.location.pathname.split('/')[1] || "";
                this.setState({
                    collectPhoneNumber:res.entity,
                })
                localStorage.setItem('collectPhoneNumber'+teamLocation, res.entity)
                swal({
                    title: 'Fan Auth Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                swal({
                    title: 'Hold on!',
                    text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    selectAuthRequired(){
        let formData = new FormData();
        var domainRoot = this.Auth.domain;
        this.setState({loading:true})
        this.Auth.fetch(domainRoot + 'services/app/Tenant/ToggleAuthRequired', {
            method: 'POST',
            body: formData,
        }).then( res => {
            if(res && res.apiStatus === "changed_auth_required"){
                this.setState({loading:false})
                var teamLocation = window.location.pathname.split('/')[1] || "";
                this.setState({
                    authRequired:res.entity,
                })
                localStorage.setItem('authRequired'+teamLocation, res.entity)
                swal({
                    title: 'Fan Auth Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                swal({
                    title: 'Hold on!',
                    text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                this.setState({loading:false})
            }
        })
    }

    selectAge(){
      let formData = new FormData();
      var domainRoot = this.Auth.domain;
      this.setState({loading:true})
      this.Auth.fetch(domainRoot + 'services/app/Tenant/ToggleCollectAge', {
        method: 'POST',
        body: formData,
      }).then( res => {
        if(res && res.apiStatus === "changed_collection_age"){
          this.setState({loading:false})
          var teamLocation = window.location.pathname.split('/')[1] || "";
          this.setState({
            collectAge:res.entity,
          })
          localStorage.setItem('collectAge'+teamLocation, res.entity)
          swal({
              title: 'Fan Auth Updated',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
        } else {
          swal({
              title: 'Hold on!',
              text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          this.setState({loading:false})
        }
      })
    }

    updateAge(){
      let formData = new FormData();
      var domainRoot = this.Auth.domain;
      if(!this.state.ageToVerify){
        swal({
            title: 'Hold on!',
            text: "Make sure to enter a minimum age to verify",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return
      }
      if(this.state.ageToVerify < 1){
        swal({
            title: 'Hold on!',
            text: "Age to verify cannot be younger than 1",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return
      }
      this.setState({loading:true})
      formData.append("AgeToCheck", this.state.ageToVerify)
      this.Auth.fetch(domainRoot + 'services/app/Tenant/SetMinimumAge', {
        method: 'POST',
        body: formData,
      }).then( res => {
        if(res && res.apiStatus === "set_collection_age"){
          this.setState({loading:false})
          var teamLocation = window.location.pathname.split('/')[1] || "";
          this.setState({
            ageToVerify:res.entity,
          })
          localStorage.setItem('ageToVerify'+teamLocation, res.entity)
          swal({
              title: 'Fan Auth Updated',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
        } else {
          swal({
              title: 'Hold on!',
              text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          this.setState({loading:false})
        }
      })
    }

    updateBlockList(){
        const blockList = this.state.blockList.trim().toLowerCase();
        let rejoinedCleanedDomainsEmails = "";
        if(blockList){
            const splitList = blockList.split(/[ ,\s]+/);
            for(const splitListIndex in splitList){
                const splitItem = splitList[splitListIndex];
                let valid;
                if(splitItem.indexOf('@') === -1){
                    valid = isValidDomain(splitItem);
                } else {
                    valid = validateEmail(splitItem);
                }
                if(!valid){
                    swal({
                        title: 'Hold on!',
                        text: "One of the items on the block list isn't a valid email or domain. The item causing an issue is: " + splitItem + ".",
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                    return
                }
            }
            rejoinedCleanedDomainsEmails = splitList.join(" ");
        }
        const domainRoot = this.Auth.domain;
        const teamLocation = window.location.pathname.split('/')[1] || "";
        let formData = new FormData();
        this.setState({loading:true});
        formData.append("TenantId", localStorage.getItem('TenantId'+teamLocation));
        formData.append("cleanEmailsDomains", rejoinedCleanedDomainsEmails);
        this.Auth.fetch(domainRoot + 'services/app/Tenant/SetBlockList', {
            method: 'POST',
            body: formData,
        }).then( res => {
            this.setState({loading:false})
            if(res && res.apiStatus === "update_block_list"){
                this.setState({
                    blockList:res.entity,
                })
                localStorage.setItem('blockList'+teamLocation, res.entity)
                swal({
                    title: 'Fan Auth Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                swal({
                    title: 'Hold on!',
                    text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    updateAllowList(){
        const allowList = this.state.allowList.trim().toLowerCase();
        let rejoinedCleanedDomainsEmails = "";
        if(allowList){
            const splitList = allowList.split(/[ ,\s]+/);
            for(const splitListIndex in splitList){
                const splitItem = splitList[splitListIndex];
                let valid;
                if(splitItem.indexOf('@') === -1){
                    valid = isValidDomain(splitItem);
                } else {
                    valid = validateEmail(splitItem);
                }
                if(!valid){
                    swal({
                        title: 'Hold on!',
                        text: "One of the items on the allow list isn't a valid email or domain. The item causing an issue is: " + splitItem + ".",
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                    return
                }
            }
            rejoinedCleanedDomainsEmails = splitList.join(" ");
        }
        const domainRoot = this.Auth.domain;
        const teamLocation = window.location.pathname.split('/')[1] || "";
        let formData = new FormData();
        this.setState({loading:true});
        formData.append("TenantId", localStorage.getItem('TenantId'+teamLocation));
        formData.append("cleanEmailsDomains", rejoinedCleanedDomainsEmails);
        this.Auth.fetch(domainRoot + 'services/app/Tenant/SetAllowList', {
            method: 'POST',
            body: formData,
        }).then( res => {
            this.setState({loading:false})
            if(res && res.apiStatus === "update_allow_list"){
                this.setState({
                    allowList:res.entity,
                })
                localStorage.setItem('allowList'+teamLocation, res.entity)
                swal({
                    title: 'Fan Auth Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                swal({
                    title: 'Hold on!',
                    text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    defaultNotChecked(defaultNotCheckedToChange){
        let formData = new FormData();
        const domainRoot = this.Auth.domain;
        this.setState({loading:true});
        formData.append("DefaultNotChanged", defaultNotCheckedToChange)
        this.Auth.fetch(domainRoot + 'services/app/Tenant/TurnOnOffDefaultCheckedOptIn', {
            method: 'POST',
            body: formData,
        }).then( res => {
            console.log(res)
            if(res && res.apiStatus === "changed_default_checked"){
                const teamLocation = window.location.pathname.split('/')[1] || "";
                this.setState({
                    [defaultNotCheckedToChange]:res.entity,
                })
                localStorage.setItem(defaultNotCheckedToChange+teamLocation, res.entity)
                swal({
                    title: 'Fan Auth Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                swal({
                    title: 'Hold on!',
                    text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
            this.setState({loading:false})
        })
    }

    selectOptIn(optInToChange){
        let formData = new FormData();
        const domainRoot = this.Auth.domain;
        this.setState({loading:true});
        formData.append("OptInToChange", optInToChange)
        this.Auth.fetch(domainRoot + 'services/app/Tenant/ToggleCollectOptIn', {
            method: 'POST',
            body: formData,
        }).then( res => {
            if(res && res.apiStatus === "changed_collection_optin"){
              const teamLocation = window.location.pathname.split('/')[1] || "";
              this.setState({
                [optInToChange]:res.entity,
              })
              localStorage.setItem(optInToChange+teamLocation, res.entity)
              swal({
                  title: 'Fan Auth Updated',
                  type: 'success',
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: true,
                  timer: 10000
                })
            } else {
              swal({
                  title: 'Hold on!',
                  text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
            }
            this.setState({loading:false})
        })
    }

    updateOptInText(nameOfOptIn){
        let formData = new FormData();
        const domainRoot = this.Auth.domain;
        const teamLocation = window.location.pathname.split('/')[1] || "";
        if(!this.state[nameOfOptIn]){
            swal({
                title: 'Hold on!',
                text: "Make sure to enter opt-in text",
                type: 'warning',
                confirmButtonText: 'Ok'
              })
              return
        }
        this.setState({loading:true})
        formData.append(nameOfOptIn, this.state[nameOfOptIn])
        formData.append("TenantId", localStorage.getItem('TenantId'+teamLocation));
        this.Auth.fetch(domainRoot + 'services/app/Tenant/SetOptInText', {
            method: 'POST',
            body: formData,
        }).then( res => {
            if(res && res.apiStatus === "set_collection_optin_text"){
              this.setState({
                [nameOfOptIn]:res.entity,
              })
              localStorage.setItem(nameOfOptIn+teamLocation, res.entity)
              swal({
                  title: 'Fan Auth Updated',
                  type: 'success',
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: true,
                  timer: 10000
                })
            } else {
              swal({
                  title: 'Hold on!',
                  text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
            }
            this.setState({loading:false})
        })
    }

    collectZipCode(){
      let formData = new FormData();
      const domainRoot = this.Auth.domain;
      this.setState({loading:true})
      this.Auth.fetch(domainRoot + 'services/app/Tenant/ToggleCollectZipCode', {
        method: 'POST',
        body: formData,
      }).then( res => {
        if(res && res.apiStatus === "changed_collection_zipcode"){
          this.setState({loading:false})
          var teamLocation = window.location.pathname.split('/')[1] || "";
          this.setState({
            collectZipCode:res.entity,
          })
          localStorage.setItem('collectZipCode'+teamLocation, res.entity)
          swal({
              title: 'Fan Auth Updated',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
        } else {
          swal({
              title: 'Hold on!',
              text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          this.setState({loading:false})
        }
      })
    }

    collectDistance(){
      let formData = new FormData();
      var domainRoot = this.Auth.domain;
      this.setState({loading:true})
      this.Auth.fetch(domainRoot + 'services/app/Tenant/ToggleCollectDistance', {
        method: 'POST',
        body: formData,
      }).then( res => {
        if(res && res.apiStatus === "changed_collection_distance"){
          this.setState({loading:false})
          var teamLocation = window.location.pathname.split('/')[1] || "";
          this.setState({
            collectDistance:res.entity,
          })
          localStorage.setItem('collectDistance'+teamLocation, res.entity)
          swal({
              title: 'Fan Auth Updated',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
        } else {
          swal({
              title: 'Hold on!',
              text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          this.setState({loading:false})
        }
      })
    }

    onChangeRichText = (notAcceptableLocationMessage) => {
        this.setState({
            notAcceptableLocationMessage,
        })
    }

    toggleShowRewardsOutMessage(){
        let formData = new FormData();
        const domainRoot = this.Auth.domain;
        this.setState({loading:true})
        this.Auth.fetch(domainRoot + 'services/app/Tenant/ToggleShowRewardsOutMessage', {
            method: 'POST',
            body: formData,
        }).then( res => {
            if(res && res.apiStatus === "changed_show_rewards_out"){
                this.setState({loading:false})
                const teamLocation = window.location.pathname.split('/')[1] || "";
                this.setState({
                    showRewardsOutMessage:res.entity,
                })
                console.log(res)
                localStorage.setItem('showRewardsOutMessage'+teamLocation, res.entity)
                swal({
                    title: 'Fan Auth Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                swal({
                    title: 'Hold on!',
                    text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                this.setState({loading:false})
            }
        })
    }

    toggleOnePrizeInTimePeriod(){
        let formData = new FormData();
        const domainRoot = this.Auth.domain;
        this.setState({loading:true})
        this.Auth.fetch(domainRoot + 'services/app/Tenant/ToggleOnePrizeInTimePeriod', {
            method: 'POST',
            body: formData,
        }).then( res => {
            if(res && res.apiStatus === "changed_one_prize"){
                this.setState({loading:false})
                const teamLocation = window.location.pathname.split('/')[1] || "";
                this.setState({
                    onePrizeInTimePeriod:res.entity,
                })
                console.log(res)
                localStorage.setItem('onePrizeInTimePeriod'+teamLocation, res.entity)
                swal({
                    title: 'Fan Auth Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                swal({
                    title: 'Hold on!',
                    text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                this.setState({loading:false})
            }
        })
    }

    toggleBlockSanitizedEmails(){
        let formData = new FormData();
        const domainRoot = this.Auth.domain;
        this.setState({loading:true})
        this.Auth.fetch(domainRoot + 'services/app/Tenant/ToggleBlockSanitizedEmails', {
            method: 'POST',
            body: formData,
        }).then( res => {
            if(res && res.apiStatus === "toggle_sanitized_emails"){
                this.setState({loading:false})
                const teamLocation = window.location.pathname.split('/')[1] || "";
                this.setState({
                    blockSanitizedEmails:res.entity,
                })
                console.log(res)
                localStorage.setItem('blockSanitizedEmails'+teamLocation, res.entity)
                swal({
                    title: 'Fan Auth Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                swal({
                    title: 'Hold on!',
                    text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                this.setState({loading:false})
            }
        })
    }

    togglePreventLoginIfNoPrizes(){
        let formData = new FormData();
        const domainRoot = this.Auth.domain;
        this.setState({loading:true})
        this.Auth.fetch(domainRoot + 'services/app/Tenant/TogglePreventLoginIfNoPrizes', {
            method: 'POST',
            body: formData,
        }).then( res => {
            if(res && res.apiStatus === "prevent_login_no_prizes"){
                this.setState({loading:false})
                const teamLocation = window.location.pathname.split('/')[1] || "";
                this.setState({
                    preventLoginIfNoPrizes:res.entity,
                })
                console.log(res)
                localStorage.setItem('preventLoginIfNoPrizes'+teamLocation, res.entity)
                swal({
                    title: 'Fan Auth Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                swal({
                    title: 'Hold on!',
                    text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                this.setState({loading:false})
            }
        })
    }

    updateOnePrizeTime(){
        const startTime = this.state.onePrizeStartTime;
        const endTime = this.state.onePrizeEndTime;
        let formData = new FormData();
        const domainRoot = this.Auth.domain;
        const teamLocation = window.location.pathname.split('/')[1] || "";
        this.setState({loading:true})
        let tenantId = localStorage.getItem("TenantId" + teamLocation);
        formData.append("onePrizeStartTime", startTime)
        formData.append("onePrizeEndTime", endTime);
        formData.append("tenantId", tenantId)
        this.Auth.fetch(domainRoot + 'services/app/Tenant/SetOnePrizeTimePeriod', {
            method: 'POST',
            body: formData,
        }).then( res => {
            if(res && res.apiStatus === "times_set_successfully"){
                this.setState({
                    onePrizeStartTime: this.state.onePrizeStartTime,
                    onePrizeEndTime: this.state.onePrizeEndTime,
                    loading:false
                })
                localStorage.setItem('onePrizeStartTime'+teamLocation, this.state.onePrizeStartTime)
                localStorage.setItem('onePrizeEndTime'+teamLocation, this.state.onePrizeEndTime)
                swal({
                    title: 'Fan Auth Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                swal({
                    title: 'Hold on!',
                    text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                this.setState({loading:false})
            }
        })
    }

    mlbUpdateMessaging(){
        let formData = new FormData();
        const domainRoot = this.Auth.domain;
        const teamLocation = window.location.pathname.split('/')[1] || "";
        this.setState({loading:true});
        const vm = this;
        let notAcceptableLocationMessage = vm.state.notAcceptableLocationMessage.toString('html');
        if(notAcceptableLocationMessage === "<p><br></p>"){
            notAcceptableLocationMessage = "";
        }
        formData.append("notAcceptableLocationMessage", notAcceptableLocationMessage)
        formData.append("notAcceptableLocationHeader", vm.state.notAcceptableLocationHeader)
        formData.append("TenantId", localStorage.getItem('TenantId'+teamLocation));
        vm.Auth.fetch(domainRoot + 'services/app/Tenant/SetProperDistanceMetrics', {
            method: 'POST',
            body: formData,
        }).then( res => {
            vm.setState({loading:false})
            if(res && res.apiStatus === "set_distance_metrics"){
                localStorage.setItem('notAcceptableLocationMessage'+teamLocation, res.entity.notAcceptableLocationMessage)
                localStorage.setItem('notAcceptableLocationHeader'+teamLocation, res.entity.notAcceptableLocationHeader)
                swal({
                    title: 'Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                swal({
                    title: 'Hold on!',
                    text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    updateDistance(){
      let formData = new FormData();
      if(!this.state.acceptableDistance || !this.state.formattedAddress){
        swal({
            title: 'Hold on!',
            text: "Please make sure all the form fields are filled out",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      if(this.state.acceptableDistance <= 0){
        swal({
            title: 'Hold on!',
            text: "Please make sure distance is greater than 0",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      const canPlayOutside = this.state.canPlayOutside || false;
      var domainRoot = this.Auth.domain;
      var teamLocation = window.location.pathname.split('/')[1] || "";
      this.setState({loading:true});
        var vm = this;
        var notAcceptableLocationMessage = vm.state.notAcceptableLocationMessage.toString('html');
        if(notAcceptableLocationMessage === "<p><br></p>"){
            notAcceptableLocationMessage = "";
        }
      geocoder.geocode({'address':this.state.formattedAddress}, function(results, status) {
        if (status === 'OK') {
          if(results.length === 1){
            var lat = results[0].geometry.location.lat();
            var lng = results[0].geometry.location.lng();
            formData.append("AcceptableDistance", vm.state.acceptableDistance)
            formData.append("Longitude", lng)
            formData.append("Latitude", lat)
            formData.append("CanPlayOutside", canPlayOutside)
            formData.append("FormattedAddress", vm.state.formattedAddress)
            formData.append("locationPermissionsBody", vm.state.locationPermissionsBody)
            formData.append("locationPermissionsHeader", vm.state.locationPermissionsHeader)
            formData.append("notAcceptableLocationMessage", notAcceptableLocationMessage)
            formData.append("notAcceptableLocationHeader", vm.state.notAcceptableLocationHeader)
            formData.append("locationCheckFailedCanPlay", vm.state.locationCheckFailedCanPlay)
            formData.append("TenantId", localStorage.getItem('TenantId'+teamLocation));
            vm.Auth.fetch(domainRoot + 'services/app/Tenant/SetProperDistanceMetrics', {
              method: 'POST',
              body: formData,
            }).then( res => {
              vm.setState({loading:false})
              if(res && res.apiStatus === "set_distance_metrics"){
                vm.setState({
                  latitude:res.entity.latitude,
                  longitude:res.entity.longitude,
                  acceptableDistance: res.entity.acceptableDistance
                })
                localStorage.setItem('latitude'+teamLocation, res.entity.latitude)
                localStorage.setItem('locationPermissionsBody'+teamLocation, res.entity.locationPermissionsBody)
                localStorage.setItem('locationPermissionsHeader'+teamLocation, res.entity.locationPermissionsHeader)
                localStorage.setItem('notAcceptableLocationMessage'+teamLocation, res.entity.notAcceptableLocationMessage)
                localStorage.setItem('notAcceptableLocationHeader'+teamLocation, res.entity.notAcceptableLocationHeader)
                localStorage.setItem('canPlayOutside'+teamLocation, res.entity.canPlayOutside)
                localStorage.setItem('locationCheckFailedCanPlay'+teamLocation, res.entity.locationCheckFailedCanPlay)
                localStorage.setItem('longitude'+teamLocation, res.entity.longitude)
                localStorage.setItem('acceptableDistance'+teamLocation, res.entity.acceptableDistance)
                swal({
                    title: 'Fan Auth Updated',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                  })
              } else {
                swal({
                    title: 'Hold on!',
                    text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                  })
              }
            })
          } else {
            vm.setState({loading:false})
            swal({
                title: 'Hold on!',
                text: "Too many locations have that address! Add more detail to get only 1 address",
                type: 'warning',
                confirmButtonText: 'Ok'
              })
          }
        } else {
          vm.setState({loading:false})
          swal({
            title: 'Hold on!',
            text: 'Finding address was not successful because ' + status,
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        }
      })
    }

    updateBlockedZips(){
      let formData = new FormData();
      var domainRoot = this.Auth.domain;
      var teamLocation = window.location.pathname.split('/')[1] || "";
      this.setState({loading:true})
      formData.append("ZipCodes", this.state.blockedZipCodes)
      formData.append("TenantId", localStorage.getItem('TenantId'+teamLocation));
      this.Auth.fetch(domainRoot + 'services/app/Tenant/SetAllowedZipCodes', {
        method: 'POST',
        body: formData,
      }).then( res => {
        if(res && res.apiStatus === "set_allowed_zip_codes"){
          this.setState({loading:false})
          var teamLocation = window.location.pathname.split('/')[1] || "";
          this.setState({
            blockedZipCodes:res.entity,
          })
          localStorage.setItem('blockedZipCodes'+teamLocation, res.entity)
          swal({
              title: 'Fan Auth Updated',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
        } else {
          swal({
              title: 'Hold on!',
              text: "Something went wrong! Check your connection and if the problem persists logout and log back in",
              type: 'warning',
              confirmButtonText: 'Ok'
            })
          this.setState({loading:false})
        }
      })
    }

    getBlockList(){
        const domainRoot = this.Auth.domain;
        const vm = this;
        this.Auth.fetch(domainRoot + 'services/app/Tenant/GetBlockList', {
            method: 'GET',
        }).then(res => {
            if(!res){
                console.log("Error")
            } else {
                if(res.entity){
                    vm.setState({
                        blockList: res.entity,
                        advanced: true
                    })
                } else {
                    vm.setState({
                        blockList: ""
                    })
                }
            }
        })
    }

    getAllowList(){
        const domainRoot = this.Auth.domain;
        const vm = this;
        this.Auth.fetch(domainRoot + 'services/app/Tenant/GetAllowList', {
            method: 'GET',
        }).then(res => {
            if(!res){
                console.log("Error")
            } else {
                if(res.entity){
                    vm.setState({
                        allowList: res.entity,
                        advanced: true
                    })
                } else {
                    vm.setState({
                        allowList: ""
                    })
                }
            }
        })
    }

    componentDidMount(){
        this.getBlockList();
        this.getAllowList();
        this.setState({
            loading:false
        })
    }

    render() {
        const collectName = this.state.collectUserName;
        const nameInEmail = this.state.nameInEmail;
        const collectEmail = this.state.collectEmail;
        const authRequired = this.state.authRequired;
        const anonymousLogin = this.state.anonymousLogin;
        const collectAge = this.state.collectAge;
        const collectOptIn = this.state.collectOptIn;
        const collectOptInNotDefaultCheck = this.state.collectOptInNotDefaultCheck;
        const collectOptInTwo = this.state.collectOptInTwo;
        const collectOptInTwoNotDefaultCheck = this.state.collectOptInTwoNotDefaultCheck;
        const optInTextTwo = this.state.optInTextTwo;
        const canPlayOutside = this.state.canPlayOutside;
        const locationCheckFailedCanPlay = this.state.locationCheckFailedCanPlay;
        const showRewardsOutMessage = this.state.showRewardsOutMessage;
        const onePrizeInTimePeriod = this.state.onePrizeInTimePeriod;
        const collectZipCode = this.state.collectZipCode;
        const collectDistance = this.state.collectDistance;
        const collectPhoneNumber = this.state.collectPhoneNumber;
        const preventLoginIfNoPrizes = this.state.preventLoginIfNoPrizes;
        const accessibilityOn = this.state.accessibilityOn;
        const acceptableDistance = parseFloat(this.state.acceptableDistance);
        const formattedAddress = this.state.formattedAddress;
        const ageValue = this.state.ageToVerify;
        const optInText = this.state.optInText;
        const locationPermissionsHeader = this.state.locationPermissionsHeader;
        const locationPermissionsBody = this.state.locationPermissionsBody;
        const notAcceptableLocationMessage = this.state.notAcceptableLocationMessage;
        const notAcceptableLocationHeader = this.state.notAcceptableLocationHeader;
        const blockedZipCodes = this.state.blockedZipCodes;
        const blockSanitizedEmails = this.state.blockSanitizedEmails;
        const teamLocation = window.location.pathname.split('/')[1] || "";
        const isMlbTeam = this.props.tenantVariables && this.props.tenantVariables.isMlbTeam;
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        if(!isMlbTeam){
            toolbarConfig.display.push('LINK_BUTTONS')
        }
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu tenantVariables={this.props.tenantVariables}/>
            <TopMenu/>
            <div className="admin-main-panel">
                {!isMlbTeam ?
                    <>
                        <div className="container-out container-left-rules">
                            <div className="admin-form-box">
                                <div className="form-group">
                                    <label htmlFor="rulesAndRegsText">Use Phone Or Email As Primary:</label>
                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check the box to select if you want new users to enter their email or phone number or neither</p>
                                    <span style={{display:authRequired?"none":""}}>
                                        <input type="checkbox" checked={collectEmail}  name="collectEmail" onClick={()=> this.selectEmail()}/> <span style={{marginRight:'10px'}}>Email</span>
                                        <input type="checkbox" checked={!collectEmail} name="collectEmail" onClick={()=> this.selectEmail()}/> <span style={{marginRight:'10px'}}>Phone</span>
                                    </span>
                                    {(authRequired || this.state.advanced) && process.env.NODE_ENV === "development" &&
                                    <>
                                        <input type="checkbox" checked={authRequired} name="authRequired" onClick={()=> this.selectAuthRequired()}/> Off
                                    </>
                                    }
                                </div>
                                {/*<button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"></span> Collect Name</button>*/}
                                <div className="form-group">
                                    <label htmlFor="rulesAndRegsText">Collect Name Of Fan</label>
                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY name field to the login flow</p>
                                    <input type="checkbox" checked={collectName} id="collectUserName" name="collectUserName" onClick={()=> this.selectName()}/>
                                </div>
                                {collectName &&
                                    <div className="form-group">
                                        <label htmlFor="rulesAndRegsText">Include Name In Email</label>
                                        <br/>
                                        <input type="checkbox" checked={nameInEmail} id="nameInEmail" name="nameInEmail" onClick={()=> this.selectNameInEmail("nameInEmail")}/>
                                    </div>
                                }
                                <div className="form-group">
                                    <label htmlFor="collectPhoneNumber">Collect Phone Number</label>
                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY phone number field to the login flow</p>
                                    <input type="checkbox" checked={collectPhoneNumber} id="collectPhoneNumber" name="collectPhoneNumber" onClick={()=> this.selectCollectPhoneNumber()}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="rulesAndRegsText">Collect Age Of Fan</label>
                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY age field to the login flow, enter the minimum age below</p>
                                    <input type="checkbox" checked={collectAge} id="collectAge" name="collectAge" onClick={()=> this.selectAge()}/>
                                    <span style={{display: collectAge ? 'block' : 'none' }}>
                                        <div>
                                          <input type="number" id="ageToVerify" name="ageToVerify" value={ageValue} onChange={this.handleChange}/>
                                        </div>
                                        <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.updateAge()}><span className="fa fa-arrow-circle-o-up"/> Update Age</button>
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="collectOptIn">Collect Opt-In</label>
                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a optional field to the login flow, enter the text below</p>
                                    <input type="checkbox" checked={collectOptIn} id="collectOptIn" name="collectOptIn" onClick={()=> this.selectOptIn("collectOptIn")}/>
                                    <span style={{display: collectOptIn ? 'block' : 'none' }}>
                                        <div>
                                            <textarea className="form-control" id="optInText" name="optInText" value={optInText} onChange={this.handleChange}/>
                                        </div>
                                        <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.updateOptInText("optInText")}><span className="fa fa-arrow-circle-o-up"/> Update Opt In Text</button>
                                    </span>
                                </div>
                                {this.state.collectOptIn &&
                                    <div className="form-group">
                                        <label htmlFor="collectOptInNotDefaultCheck">Default Not Checked</label>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to have the Opt-In field not default checked</p>
                                        <input type="checkbox" checked={collectOptInNotDefaultCheck} id="collectOptInNotDefaultCheck" name="collectOptInNotDefaultCheck" onClick={()=> this.defaultNotChecked("collectOptInNotDefaultCheck")}/>
                                    </div>
                                }
                                {this.state.collectOptIn &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="collectOptInTwo">Collect Second Opt-In</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a optional field to the login flow, enter the text below</p>
                                            <input type="checkbox" checked={collectOptInTwo} id="collectOptInTwo" name="collectOptInTwo" onClick={()=> this.selectOptIn("collectOptInTwo")}/>
                                            <span style={{display: collectOptInTwo ? 'block' : 'none' }}>
                                                <div>
                                                    <textarea className="form-control" id="optInTextTwo" name="optInTextTwo" value={optInTextTwo} onChange={this.handleChange}/>
                                                </div>
                                                <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.updateOptInText("optInTextTwo")}><span className="fa fa-arrow-circle-o-up"/> Update Opt In Text</button>
                                            </span>
                                        </div>
                                        {this.state.collectOptInTwo &&
                                            <div className="form-group">
                                                <label htmlFor="collectOptInTwoNotDefaultCheck">Default Not Checked</label>
                                                <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to have the Opt-In field not default checked</p>
                                                <input type="checkbox" checked={collectOptInTwoNotDefaultCheck} id="collectOptInTwoNotDefaultCheck" name="collectOptInTwoNotDefaultCheck" onClick={()=> this.defaultNotChecked("collectOptInTwoNotDefaultCheck")}/>
                                            </div>
                                        }
                                    </>
                                }
                                <div className="form-group">
                                    <label htmlFor="collectZipCode">Collect ZipCode</label>
                                    <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add a MANDATORY field to the login flow, enter the text below</p>
                                    <input type="checkbox" checked={collectZipCode} id="collectZipCode" name="collectZipCode" onClick={()=> this.collectZipCode()}/>
                                    <span style={{display: collectZipCode ? 'block' : 'none' }}>
                                        <div>
                                          <textarea id="blockedZipCodes" name="blockedZipCodes" value={blockedZipCodes} onChange={this.handleChange}/>
                                        </div>
                                        <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.updateBlockedZips()}><span className="fa fa-arrow-circle-o-up"/> Update Blocked Zip Codes</button>
                                    </span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="showRewardsOutMessage">Advanced</label>
                                    <br/>
                                    <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                                </div>
                                <div style={{display: this.state.advanced ? "" : "none"}}>
                                    <div className="form-group">
                                        <label htmlFor="showRewardsOutMessage">End Game When Rewards Are Out</label>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to block the user from playing when prizes are out and will show a pop up informing them of that state. This text can be changed <a
                                            href={"/"+teamLocation +"/setupteamvariables?showGameText=true"}>here</a></p>
                                        <input type="checkbox" checked={showRewardsOutMessage} id="showRewardsOutMessage" name="showRewardsOutMessage" onClick={()=> this.toggleShowRewardsOutMessage()}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="collectDistance">Turn On/Off Geolocation</label>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to add MANDATORY verification of distance from stadium</p>
                                        <input type="checkbox" checked={collectDistance} id="collectDistance" name="collectDistance" onClick={()=> this.collectDistance()}/>
                                        <span style={{display: collectDistance ? 'block' : 'none' }}>
                                            <div>
                                              <label htmlFor="formattedAddress" style={{marginTop:5,marginBottom:0}}>Address</label>
                                              <br/>
                                              <input className="form-control" type="text" id="formattedAddress" name="formattedAddress" value={formattedAddress} onChange={this.handleChange} placeholder="Address"/>
                                              <br/>
                                              <label htmlFor="locationPermissionsHeader" style={{marginTop:5,marginBottom:0}}>Requesting Location Permissions Header</label>
                                              <br/>
                                              <input className="form-control" type="text" id="locationPermissionsHeader" name="locationPermissionsHeader" value={locationPermissionsHeader} onChange={this.handleChange} placeholder="Location Permissions Header"/>
                                              <br/>
                                              <label htmlFor="locationPermissionsBody" style={{marginTop:5,marginBottom:0}}>Requesting Location Permissions Body</label>
                                              <br/>
                                              <textarea className="form-control" id="locationPermissionsBody" name="locationPermissionsBody" value={locationPermissionsBody} onChange={this.handleChange} placeholder="Location Permissions Body"/>
                                              <br/>
                                              <label htmlFor="notAcceptableLocationHeader" style={{marginTop:5,marginBottom:0}}>Not Acceptable Location Header</label>
                                              <br/>
                                              <input className="form-control" type="text" id="notAcceptableLocationHeader" name="notAcceptableLocationHeader" value={notAcceptableLocationHeader} onChange={this.handleChange} placeholder="Not Acceptable Location Header"/>
                                              <br/>
                                              <label htmlFor="notAcceptableLocationMessage" style={{marginTop:5,marginBottom:0}}>Not Acceptable Location Message</label>
                                              <br/>
                                              <RichTextEditor toolbarConfig={toolbarConfig} id="notAcceptableLocationMessage" name="notAcceptableLocationMessage" value={notAcceptableLocationMessage} onChange={this.onChangeRichText} placeholder="Not Acceptable Location Message"/>
                                              <br/>
                                              <label htmlFor="acceptableDistance" style={{marginTop:5,marginBottom:0}}>Distance (Miles)</label> <input type="checkbox" onClick={()=> this.setState({canPlayOutside:false})} checked={!canPlayOutside}/> Fans Can Play Within <input onClick={()=> this.setState({canPlayOutside:true})} type="checkbox" checked={canPlayOutside}/> Fans Can Play Outside
                                              <br/>
                                              <input className="form-control" type="number" id="acceptableDistance" name="acceptableDistance" min="0" value={acceptableDistance} onChange={this.handleChange} placeholder=""/>
                                              <br/>
                                              <label htmlFor="locationCheckFailed" style={{marginTop:5,marginBottom:0}}>If Location Check Failed:</label>
                                              <br/>
                                              <input type="checkbox" onClick={()=> this.setState({locationCheckFailedCanPlay:false})} checked={!locationCheckFailedCanPlay}/> Block From Playing <input onClick={()=> this.setState({locationCheckFailedCanPlay:true})} type="checkbox" checked={locationCheckFailedCanPlay}/> Can't Win
                                            </div>
                                            <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.updateDistance()}><span className="fa fa-arrow-circle-o-up"/> Update Distance Variables</button>
                                        </span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="onePrizeInTimePeriod">Turn On/Off One Prize</label>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to allow a fan to only win 1 prize in a given time period or for all time if time is left blank</p>
                                        <input type="checkbox" checked={onePrizeInTimePeriod} id="onePrizeInTimePeriod" name="onePrizeInTimePeriod" onClick={()=> this.toggleOnePrizeInTimePeriod()}/>
                                        <span style={{display: onePrizeInTimePeriod ? 'block' : 'none' }}>
                                            <div>
                                              <label htmlFor="onePrizeStartTime" style={{marginTop:5,marginBottom:0}}>Start Time</label>
                                              <br/>
                                              <input className="form-control" type="datetime-local" id="onePrizeStartTime" name="onePrizeStartTime" value={this.state.onePrizeStartTime} onChange={this.handleChange}/>
                                              <br/>
                                              <label htmlFor="onePrizeEndTime" style={{marginTop:5,marginBottom:0}}>End Time</label>
                                              <br/>
                                              <input className="form-control" type="datetime-local" id="onePrizeEndTime" name="onePrizeEndTime" value={this.state.onePrizeEndTime} onChange={this.handleChange}/>
                                            </div>
                                            <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.updateOnePrizeTime()}><span className="fa fa-arrow-circle-o-up"/> Update Time</button>
                                        </span>
                                    </div>
                                    {process.env.NODE_ENV === "development" &&
                                        <div className="form-group">
                                            <label htmlFor="allowAnonymousLogin">Allow Anonymous Login</label>
                                            <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Check this box to allow anonymous logins</p>
                                            <input type="checkbox" checked={anonymousLogin} id="allowAnonymousLogin" name="allowAnonymousLogin" onClick={()=> this.selectAnonymous()}/>
                                        </div>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="allowAnonymousLogin">Prevent Login If Game Inactive Or Out Of Prizes</label>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This prevents potential users from logging in when their is no game, the game is inactive, or the game is out of prizes</p>
                                        <input type="checkbox" checked={preventLoginIfNoPrizes} id="preventLoginIfNoPrizes" name="preventLoginIfNoPrizes" onClick={()=> this.togglePreventLoginIfNoPrizes()}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="blockList">Block List Emails/Domains</label>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Enter any emails or domains that should be blocked from logging in.</p>
                                        <textarea className="form-control" id="blockList" name="blockList" value={this.state.blockList} onChange={this.handleChange}/>
                                        <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.updateBlockList()}><span className="fa fa-arrow-circle-o-up"/> Update Block List</button>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="allowList">Allow List Emails/Domains</label>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Enter any emails or domains that should be allowed to login, all other emails/domains will be blocked. This will SUPERSEDE any emails/domains on the block list and let them play</p>
                                        <textarea className="form-control" id="allowList" name="allowList" value={this.state.allowList} onChange={this.handleChange}/>
                                        <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.updateAllowList()}><span className="fa fa-arrow-circle-o-up"/> Update Allow List</button>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="blockList">Sanitize Emails</label>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Toggle on to check if there is a sanitized version of the users email (ignores . and +; This could have some users use the same account)</p>
                                        <input type="checkbox" onClick={()=> this.toggleBlockSanitizedEmails()} checked={!blockSanitizedEmails}/> No Sanitize <input onClick={()=> this.toggleBlockSanitizedEmails()} type="checkbox" checked={blockSanitizedEmails}/> Sanitize
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="accessibilityOn">Accessibility</label>
                                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>Toggle on to add accessibility features to help those with disabilities (beware this will alter the experience for all users)</p>
                                        <input type="checkbox" checked={accessibilityOn} id="accessibilityOn" name="accessibilityOn" onClick={()=> this.toggleAccessibility()}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-out mobile-hide" style={{width:'45%', float:'right', marginRight:'20px'}}>
                            <div className="admin-form-box" style={{border:'1px solid black', backgroundColor:'white'}}>
                                <p style={{fontSize:'20px', fontWeight:'bold'}}>What Data Should I Gather?</p>
                                <p className="text-muted2">Think carefully about what you want to gather from your fan base.  The more you ask for, the fewer people will be willing to go through the whole logging in process.
                                    Balance that with useful parameters that you can use to help your fans get more value from your experience.</p>
                            </div>
                        </div>
                    </>
                :
                    <div className="container-out container-left-rules">
                        <div className="admin-form-box">
                            <div className="form-group">
                                <label htmlFor="collectDistance">Turn On/Off Check-In Requirement</label>
                                <br/>
                                <input type="checkbox" checked={collectDistance} id="collectDistance" name="collectDistance" onClick={()=> this.collectDistance()}/>
                                <span style={{display: collectDistance ? 'block' : 'none' }}>
                                    <div>
                                        <label htmlFor="notAcceptableLocationHeader" style={{marginTop:5,marginBottom:0}}>Not Acceptable Location Header</label>
                                        <br/>
                                        <input className="form-control" type="text" id="notAcceptableLocationHeader" name="notAcceptableLocationHeader" value={notAcceptableLocationHeader} onChange={this.handleChange} placeholder="Hold On"/>
                                        <br/>
                                        <label htmlFor="notAcceptableLocationMessage" style={{marginTop:5,marginBottom:0}}>Not Acceptable Location Message</label>
                                        <br/>
                                        <RichTextEditor toolbarConfig={toolbarConfig} id="notAcceptableLocationMessage" name="notAcceptableLocationMessage" value={notAcceptableLocationMessage} onChange={this.onChangeRichText} placeholder="Please check in on the MLB app before playing!"/>
                                    </div>
                                    <button className="btn btn-primary btn-lg update-button" style={{marginTop:5}} onClick={()=>this.mlbUpdateMessaging()}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </div>
         </div>
        );
    }
}

export default SetUpFanAuth;
