import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import AuthService from '../utils/AuthService';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';

class Tutorials extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.state = {
            loading: true
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (evt) {
      this.setState({
        [evt.target.name]: evt.target.value,
      });
    }

    componentDidMount(){
      this.setState({
        loading:false
      })
    }

    render() {
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu tenantVariables={this.props.tenantVariables}/>
            <TopMenu/>
            <div className="admin-main-panel">
                <p className="admin-header-text" style={{marginBottom:0, marginTop:'5px'}}>Download Our Image & Style Guide</p>
                <p className="admin-subheader-text" style={{marginBottom: 0}}>Is your designer looking to create a masterpiece with the activation? We have a styling guide ready and set for you to create the images for your scratch & win activation.</p>
                <a style={{marginLeft: 20, fontSize: 12}} title="Download The Style Guide (PDF)" href="https://s3-us-west-2.amazonaws.com/pick6-admin/badges/SQWAD+Scratchers+Getting+Started+2019.pdf" target="_blank">Download The Style Guide (PDF)</a>
                <p className="admin-header-text" style={{marginBottom:0, marginTop:'5px'}}>Learn The Ropes With Our Tutorials</p>
                <p className="admin-subheader-text">Learn the ropes on how to create a game, add prizes, and more with a walk-through from Nick.</p>
                <div className="admin-grid-container three-columns" style={{marginTop:20}}>
                    <div className="card">
                        <div className="card-body" align="center">
                            <p style={{marginTop:5}}>How Do I Run A Game On SQWAD Scratchers?</p>
                            <div style={{position: "relative", paddingBottom: "61.016949152542374%", height: 0}}>
                                <iframe src="https://www.loom.com/embed/cba0bd747d29442e84e1b0b12814223d"
                                        frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen
                                        style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}/>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body" align="center">
                            <p style={{marginTop:5}}>How Do I Upload A Prize On SQWAD Scratchers?</p>
                            <div style={{position: "relative", paddingBottom: "61.016949152542374%", height: 0}}>
                                <iframe src="https://www.loom.com/embed/0ed0826c91934c5db06354500a62e316"
                                        frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen
                                        style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}/>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body" align="center">
                            <p style={{marginTop:5}}>How Do I Customize Scratchers Game Branding?</p>
                            <div style={{position: "relative", paddingBottom: "61.016949152542374%", height: 0}}>
                                <iframe src="https://www.loom.com/embed/96278402d2e7446c837bc9292bb9f33e"
                                        frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen
                                        style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}/>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body" align="center">
                            <p style={{marginTop:5}}>How To Change Your Scratchers Rules & Regulations</p>
                            <div style={{position: "relative", paddingBottom: "61.016949152542374%", height: 0}}>
                                <iframe src="https://www.loom.com/embed/143580f9708241628f39e406eb486046"
                                        frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen
                                        style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}/>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body" align="center">
                            <p style={{marginTop:5}}>Changing The Email Branding On SQWAD Scratchers</p>
                            <div style={{position: "relative", paddingBottom: "61.016949152542374%", height: 0}}>
                                <iframe src="https://www.loom.com/embed/767164f3038846c7be4cac2a63b1814a"
                                        frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen
                                        style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}/>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body" align="center">
                            <p style={{marginTop:5}}>How To Change Your Fan Login Information on SQWAD Scratchers?</p>
                            <div style={{position: "relative", paddingBottom: "61.016949152542374%", height: 0}}>
                                <iframe src="https://www.loom.com/embed/abb38472bfca4610b7f154e041f2f4b2"
                                        frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen
                                        style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}/>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body" align="center">
                            <p style={{marginTop:5}}>Downloading My Game & User Info</p>
                            <div style={{position: "relative", paddingBottom: "61.016949152542374%", height: 0}}>
                                <iframe src="https://www.loom.com/embed/693b5e11398847a891bda056df28326c"
                                        frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen
                                        style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
        );
    }
}

export default Tutorials;
