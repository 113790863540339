import React, { Component } from 'react'
import '../../styles/css/SideMenu.css'
import '../../styles/css/AdminMain.css'
import '../../styles/css/TopBar.css'
import logoImage from '../../styles/images/sqwad-hand.png';

class TopBar extends Component {
    capitalize(str){
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    toggleMenu(){
      const sideBar = document.getElementsByClassName('admin-sidebar')[0]
      if(window.innerWidth <= 767){
        if(sideBar.style.display === "none" || sideBar.style.display === ""){
          sideBar.style.display = "block"
        } else {
          sideBar.style.display = "none"
        }
      }
    }

    render() {
      const teamLocation = window.location.pathname.split('/')[1] || ""
      let teamName = localStorage.getItem('team'+teamLocation)
      let teamLogo = localStorage.getItem('topLeftLogo'+teamLocation)
      if(localStorage.getItem('topLeftLogo'+teamLocation) === "null" || !localStorage.getItem('topLeftLogo'+teamLocation)){
        teamLogo = logoImage
      }
      teamName = this.capitalize(teamName);
        return (
          <div className="admin-topbar" onClick={this.toggleMenu}>
            <button className="button-menu-mobile open-left disable-btn">
                <i className="fa fa-bars"/>
            </button>
            <p className="topBarText">
              <img src={teamLogo} width="auto" height="auto" alt="Team" className="rounded-circle" style={{marginRight:'5px', maxHeight:'75px',maxWidth:'50px', padding:'1px'}}/>
              <span className="headerText">{teamName}</span>
            </p>
          </div>

        );
    }
}

export default TopBar
