import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import AuthService from '../utils/AuthService';
import logoImage from '../../styles/images/sqwad-hand.png';
import logoWithTextImageBlack from '../../styles/images/new_sqwad_logo.png';
import '../../styles/css/main.css';
import swal from 'sweetalert2';

class AdminSetUpPassword extends Component {
    constructor(props) {
        super(props);
        this.setPassword = this.setPassword.bind(this)
        this.Auth = new AuthService();
        this.state = {
            loading: false,
            redirect: false
        };
    }

    getAllUrlParams(url) {

        // get query string from url (optional) or window
        let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

        // we'll store the parameters here
        const obj = {};

        // if query string exists
        if (queryString) {

            // stuff after # is not part of query string, so get rid of it
            queryString = queryString.split('#')[0];

            // split our query string into its component parts
            const arr = queryString.split('&');

            for (let i = 0; i < arr.length; i++) {
                // separate the keys and the values
                const a = arr[i].split('=');

                // set parameter name and value (use 'true' if empty)
                let paramName = a[0];
                let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

                // (optional) keep case consistent
                paramName = paramName.toLowerCase();
                // if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

                // if the paramName ends with square brackets, e.g. colors[] or colors[2]
                if (paramName.match(/\[(\d+)?\]$/)) {

                    // create key if it doesn't exist
                    const key = paramName.replace(/\[(\d+)?\]/, '');
                    if (!obj[key]) obj[key] = [];

                    // if it's an indexed array e.g. colors[2]
                    if (paramName.match(/\[\d+\]$/)) {
                        // get the index value and add the entry at the appropriate position
                        const index = /\[(\d+)\]/.exec(paramName)[1];
                        obj[key][index] = paramValue;
                    } else {
                        // otherwise add the value to the end of the array
                        obj[key].push(paramValue);
                    }
                } else {
                    // we're dealing with a string
                    if (!obj[paramName]) {
                        // if it doesn't exist, create property
                        obj[paramName] = paramValue;
                    } else if (obj[paramName] && typeof obj[paramName] === 'string'){
                        // if property does exist and it's a string, convert it to an array
                        obj[paramName] = [obj[paramName]];
                        obj[paramName].push(paramValue);
                    } else {
                        // otherwise add the property
                        obj[paramName].push(paramValue);
                    }
                }
            }
        }

        return obj;
    }

    componentDidMount(){
        if(this.Auth.loggedIn() && this.Auth.isAdmin()){
            this.setState({
                redirect: true
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }

    setPassword(event) {
        event.preventDefault()
        const teamLocation = window.location.pathname.split('/')[1] || "";
        const email = this.emailInput.value;
        const password = this.passwordInput.value;
        // this.setState({loading:true})
        const params = this.getAllUrlParams();
        if(params.userid && params.code){
            this.setState({loading:true})
            const vm = this;
            const domainRoot = this.Auth.domain;
            const tenantObject = {tenant: "HERE"};
            this.Auth.fetch(`${domainRoot}UserSetPassword?userEmail=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&code=${encodeURIComponent(params.code)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(tenantObject),
            }).then(res => {
                console.log(res)
                if(res && res.apiStatus === "password_set"){
                    this.Auth.login(email, password).then(res => {
                        this.setState({loading:false})
                        if(res.tenantId){
                            swal({
                                title: 'Uh Oh!',
                                text: "Looks like the url is wrong or this tenant has been shut down",
                                type: 'warning',
                                confirmButtonText: 'Ok'
                            })
                            return;
                        }
                        if(res.login_failure){
                            swal({
                                title: 'Uh Oh!',
                                text: res.login_failure[0],
                                type: 'warning',
                                confirmButtonText: 'Ok'
                            })
                            return;
                        }
                        this.props.checkIfAuthenticated();
                        this.Auth.fetch(`${domainRoot}SendWelcomeEmail?userEmail=${encodeURIComponent(email)}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(tenantObject),
                        })
                        const teamName = localStorage.getItem('team'+teamLocation);
                        this.props.history.push("/" + teamName + "/adminHome")
                    })
                        .catch(err => {
                            swal({
                                title: 'Uh oh!',
                                text: err,
                                type: 'error',
                                confirmButtonText: 'Ok'
                            })
                        })
                } else if(res && res.apiStatus === "email_not_found") {
                    vm.setState({
                        loading:false
                    })
                    swal({
                        title: 'Email Not Found',
                        text: "Make sure the email you entered was correct and try again",
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    vm.setState({
                        loading:false,
                    })
                    swal({
                        title: 'Unknown Error',
                        text: "An unknown error occurred.  Check your internet connection and try again",
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                }
            })
        } else {
            swal({
                title: 'Missing Information',
                text: "Not all the information we expected was there. Please recheck your link in the email or ask for another one",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
        }
    }

    render() {
        const params = this.getAllUrlParams();
        const teamLocation = window.location.pathname.split('/')[1] || ""
        const teamName = localStorage.getItem('team'+teamLocation);
        if (this.state.redirect === true) {
            const linkToGoTo = "/" + teamName + "/adminHome"
            return <Redirect to={linkToGoTo} />
        }
        return (
            <div className="auth-fluid">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
              <div className="auth-fluid-form-box">
                <div className="align-items-center d-flex h-100">
                    <div className="card-body">

                        <div className="auth-brand text-center text-lg-left" style={{marginBottom:'50px'}}>
                            <a href="/">
                                <span><img src={logoWithTextImageBlack} alt="" height="auto" width="200px"/></span>
                            </a>
                        </div>

                        <h4 className="mt-0" style={{fontWeight:'bolder', fontFamily:'Roboto'}}>Welcome</h4>
                        <p className="text-muted2 mb-4" style={{fontWeight:'bold', fontFamily:'Roboto'}}>Enter your email address and set your password to access account.</p>

                        <form onSubmit={(event) => { this.setPassword(event) }} ref={(form) => { this.loginForm = form }} style={{fontWeight:'bold', fontFamily:'Roboto'}}>
                            <div className="form-group">
                                <label htmlFor="email">Email address</label>
                                <input defaultValue={params.email} className="form-control" type="email" name="email" ref={(input) => { this.emailInput = input }} placeholder="Enter your email"/>
                            </div>
                            <div className="form-group">
                                {/*<a href="pages-recoverpw-2.html" className="text-muted float-right"><small>Forgot your password?</small></a>*/}
                                <label htmlFor="password">Password</label>
                                <input className="form-control" name="password" type="password" ref={(input) => { this.passwordInput = input }} placeholder="Set your password"/>
                            </div>
                            <div className="form-group mb-0 text-center">
                                <button className="btn btn-primary btn-block" type="submit" style={{backgroundColor:"#ff1f3e", borderColor:"#ff1f3e", fontWeight:'bold', fontFamily:'Roboto'}}>Create Account </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <div className="auth-fluid-right text-center mobile-hide">
                <div className="auth-user-testimonial">
                    {/*<h2 className="mb-3" style={{fontWeight:'bolder', fontFamily:'Roboto'}}>Scratchers Tip Of The Month </h2>*/}
                    {/*<p className="lead" style={{fontWeight:'bold', fontFamily:'Roboto'}}>Plug the phone numbers collected into Facebook, Instagram, & Snapchat ads for ticketing retargeting (5x-8x returns)*/}
                    {/*</p>*/}
                    <img src={logoImage} width="200px"/>
                </div>
            </div>
        </div>
        );
    }
}

export default AdminSetUpPassword
