import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AuthService from '../utils/AuthService';
import TopMenu from '../admin/TopBar';
import swal from 'sweetalert2';
import '../../styles/css/AdminMain.css';
import Dropzone from 'react-dropzone';
import RichTextEditor from 'html-verify-react-rte';
import {PhotoshopPicker} from "react-color";
import DatePicker from "../utils/DatePicker";
// import {cleanDate} from "../utils/HelpfulFunction";

class SetUpScratcherTickets extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        this.state = {
            ticketList: [],
            showLosing: true,
            showWinning: false,
            losingTicketList: [],
            winningTicketList: [],
            editingTicket: false,
            imageUrl: null,
            uploadedPercent : 0,
            alreadyLosingTicket: false,
            colorToShow: "#ffffff",
            colorToUpdate: "",
            rewardButtonColor:"",
            rewardButtonTextColor: "",
            colorPickerShow: false,
            modal: false,
            rewardName: "",
            rewardLink: "",
            vendorName: "",
            uniqueOutsideCampaignId: "",
            rewardDescription: RichTextEditor.createEmptyValue(),
            editTicketId: "",
            losingTicket: false,
            editTicketEmailImage: '',
            editTicketPictureUnderScratcher: '',
            revealImagePreview: {},
            emailImagePreview: {},
            loading:true,
            files: []
        };
        this.toggle = this.toggle.bind(this);
        this.toggleLosing = this.toggleLosing.bind(this);
        this.getTickets = this.getTickets.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.switchTicketsShowing = this.switchTicketsShowing.bind(this);
    }

    componentDidMount() {
      this.getTickets();
    }

    onDrop(files, rejected, myArgument) {
      if(rejected.length > 0){
        swal({
            title: 'Image cannot be uploaded',
            text: 'Make sure the image is less than 5mbs and it is an accepted file type',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      const nameToUpdate = myArgument + "Preview"
      const fileToUpdate = files[0]
      this.setState({
        [nameToUpdate]: ({
          fileToUpdate,
          preview: URL.createObjectURL(fileToUpdate)
        })
      });
    }

    componentWillUnmount() {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(this.state.revealImagePreview.preview);
      URL.revokeObjectURL(this.state.emailImagePreview.preview);
    }

    handleChange(evt){
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value
        });
    }

    onChangeDescription = (rewardDescription) => {
      this.setState({
        rewardDescription,
      })
    }

    openColorPicker(colorToChange){
        var oldColor = this.state[colorToChange];
        this.setState({
            colorPickerShow: true,
            colorToShow: oldColor,
            colorToUpdate: colorToChange
        })
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        })
    };

    acceptPicker(){
        var colorToChange = this.state.colorToUpdate;
        this.setState({
            [colorToChange]: this.state.colorToShow,
            updateHappend: true,
            colorPickerShow: false,
        })
    }

    dismissPicker(){
        this.setState({
            colorPickerShow: false,
        })
    }

    getTickets(){
      const domainRoot = this.Auth.domain;
      this.Auth.fetch(domainRoot + 'services/app/Tenant/GetTenantRewardTemplates', {
        method: 'GET',
      }).then(res => {
        if(res){
          var thereIsAFalseOne = false;
          var losingTicketList = [];
          var winningTicketList = [];
          for(var template in res.tenantRewardTemplates){
            if(!res.tenantRewardTemplates[template].isWinning){
              thereIsAFalseOne = true
              losingTicketList.push(res.tenantRewardTemplates[template])
            } else {
              winningTicketList.push(res.tenantRewardTemplates[template])
            }
          }
          this.setState({
            alreadyLosingTicket: thereIsAFalseOne,
            ticketList: res.tenantRewardTemplates || [],
            losingTicketList: losingTicketList,
            winningTicketList: winningTicketList,
            loading:false
          })
        } else {
            swal({
                title: 'There was some error!',
                text: 'Something went wrong.  Try again and if the problem persists check your connection and relogin',
                type: 'error',
                confirmButtonText: 'Ok'
            })
            this.setState({
                loading:false
            })
        }
      })
    }

    switchTicketsShowing(){
      if(this.state.showWinning){
        document.getElementById('showWinningButton').classList.remove('active');
        document.getElementById('showLosingButton').classList.add('active');
        this.setState({
          showWinning:false,
          showLosing:true
        })
      }else{
        document.getElementById('showLosingButton').classList.remove('active');
        document.getElementById('showWinningButton').classList.add('active');
        this.setState({
          showWinning:true,
          showLosing:false
        })
      }
    }

    createEditTicket(){
        let formData = new FormData();
        const isMlbTeam = this.props.tenantVariables.isMlbTeam;
        let rewardName = this.state.rewardName || "";
        if(rewardName.length > 50){
            swal({
                title: 'Oh uh!',
                text: 'Prize name cannot be longer than 50 characters!',
                type: 'warning',
                confirmButtonText: 'Ok'
              })
            return;
        }
        let rewardLink = this.state.rewardLink || "";
        let isRedeemable = this.state.isRedeemable || false;
        if(isRedeemable){
            rewardLink = "";
        }
        let rewardButtonColor = this.state.rewardButtonColor || "";
        let rewardButtonTextColor = this.state.rewardButtonTextColor || "";
        let vendorName = this.state.vendorName || "";
        let rewardButtonText = this.state.rewardButtonText || "";
        let uniqueOutsideCampaignId = this.state.uniqueOutsideCampaignId || "";
        let rewardDescription = this.state.rewardDescription.toString('html');
        if(rewardDescription === "<p><br></p>"){
            rewardDescription = "";
        }
        if(isMlbTeam) {
            if(rewardButtonText && rewardButtonText.length > 15){
                swal({
                    title: 'Oh uh!',
                    text: 'Button text cannot be longer than 15 characters!',
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            }
            let cleanRewardDescription = rewardDescription.replace(/(<([^>]+)>)/gi, "");
            if(cleanRewardDescription || vendorName) {
                let failedCheck = false;
                if(cleanRewardDescription && vendorName) {
                    const stringLength = vendorName.length + cleanRewardDescription.length;
                    if(stringLength > 469) {
                        failedCheck = true;
                    }
                } else if(cleanRewardDescription && cleanRewardDescription.length > 469) {
                    failedCheck = true;
                } else if(vendorName && vendorName.length > 469) {
                    failedCheck = true;
                }
                if(failedCheck){
                    swal({
                        title: 'Oh uh!',
                        text: 'The combination of Vendor Name and Template Text cannot be greater than 469 characters',
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                    return;
                }
            }
        }
        let expiresAtHours = this.state.expiresAtHours;
        let expiresAtDateTime = this.state.expiresAtDateTime;
        if(expiresAtDateTime){
            if(typeof expiresAtDateTime !== "string"){
                const timeZoneOffsetHours = new Date().getTimezoneOffset()/60;
                expiresAtDateTime.setHours(expiresAtDateTime.getHours() - timeZoneOffsetHours);
                expiresAtDateTime = expiresAtDateTime.toISOString();
            }
        }
        let revealFile = this.state.revealImagePreview.fileToUpdate;
        let emailImage = this.state.emailImagePreview.fileToUpdate;
        let teamLocation = window.location.pathname.split('/')[1] || ""
        let tenantId = parseInt(localStorage.getItem('TenantId'+teamLocation),10)
        let vm = this;
        let domainRoot = this.Auth.domain;
        if(!this.state.editingTicket && !this.state.losingTicket){
            if(!rewardName || !rewardDescription || !revealFile){
              swal({
                  title: 'Not all fields filled out!',
                  text: 'Please enter them and try again',
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
              return;
            }
        }
        if(this.state.editingTicket){
            if(!rewardName || !rewardDescription){
              swal({
                  title: 'Not all fields filled out!',
                  text: 'Please enter them and try again',
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
              return;
            }
        }
        if(this.state.losingTicket){
            if(!revealFile){
              swal({
                  title: 'Not all fields filled out!',
                  text: 'Please enter them and try again',
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
              return;
            }
        }

        formData.append('RewardName', rewardName.trim());
        formData.append('RevealImage', revealFile);
        formData.append('EmailImage', emailImage);
        formData.append('RewardLink', rewardLink.trim());
        formData.append('RewardButtonColor', rewardButtonColor.trim());
        formData.append('RewardButtonTextColor', rewardButtonTextColor.trim());
        formData.append('RewardButtonText', rewardButtonText.trim());
        formData.append('Description', rewardDescription);
        formData.append('VendorName', vendorName);
        formData.append('UniqueOutsideCampaignId', uniqueOutsideCampaignId.trim());
        formData.append('ExpiresAtHours', expiresAtHours);
        formData.append('ExpiresAtDateTime', expiresAtDateTime);
        formData.append('TenantId', tenantId);
        formData.append('IsRedeemable', isRedeemable);
        this.setState({loading:true})
        if(!this.state.losingTicket && !this.state.editingTicket){
            this.Auth.fetch(domainRoot + 'services/app/Reward/Template/Create', {
              method: 'POST',
              body: formData,
            }).then( res => {
              this.setState({loading:false})
              if(res.apiStatus === "reward_template_updated"){
                swal({
                    title: 'Winning Ticket Created!',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                  })
                vm.getTickets();
                vm.setState({
                    modal: false,
                    rewardName: '',
                    rewardLink: '',
                    rewardButtonColor:'',
                    rewardButtonTextColor:"",
                    rewardButtonText:"",
                    rewardDescription: RichTextEditor.createEmptyValue(),
                    vendorName: "",
                    editTicketPictureUnderScratcher: '',
                    uniqueOutsideCampaignId: '',
                    expiresAtHours: null,
                    expiresAtDateTime: null,
                    editTicketEmailImage: '',
                    revealImagePreview: {},
                    emailImagePreview: {},
                    editTicketId: '',
                    losingTicket: false,
                    editingTicket: false,
                    isRedeemable: false
                })
              } else {
                swal({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in',
                    type: 'error',
                    confirmButtonText: 'Ok'
                  })
                console.log(res)
              }
            }).catch(err => {
              swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
            })
        } else if(this.state.losingTicket && !this.state.editingTicket) {
            if(this.state.alreadyLosingTicket){
              swal({
                  title: 'A losing ticket already exists!',
                  text: 'Please delete it to create a new one',
                  type: 'warning',
                  confirmButtonText: 'Ok'
                })
              this.setState({loading:false})
              return
            }
            this.Auth.fetch(domainRoot + 'services/app/Reward/Template/CreateLosing', {
              method: 'POST',
              body: formData,
            }).then( res => {
              this.setState({loading:false})
              if(res.apiStatus === "reward_template_updated"){
                swal({
                    title: 'Losing Ticket Created!',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                  })
                vm.getTickets();
                vm.setState({
                    modal: false,
                    rewardName: '',
                    rewardLink: '',
                    rewardButtonColor:'',
                    rewardButtonTextColor:"",
                    rewardButtonText:"",
                    rewardDescription: RichTextEditor.createEmptyValue(),
                    vendorName: "",
                    editTicketPictureUnderScratcher: '',
                    uniqueOutsideCampaignId: '',
                    editTicketEmailImage: '',
                    revealImagePreview: {},
                    emailImagePreview: {},
                    expiresAtHours: null,
                    expiresAtDateTime: null,
                    editTicketId: '',
                    losingTicket: false,
                    editingTicket: false,
                    isRedeemable: false
                })
              } else {
                swal({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in',
                    type: 'error',
                    confirmButtonText: 'Ok'
                  })
              }
            }).catch(err => {
              this.setState({loading:false})
              swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
            })
        } else if(this.state.editingTicket){
            var id = this.state.editTicketId;
            formData.append('id', id);
            this.Auth.fetch(domainRoot + 'services/app/Reward/Template/Update', {
              method: 'PUT',
              body: formData,
            }).then( res => {
              this.setState({loading:false})
              if(res.apiStatus === "reward_template_updated"){
                swal({
                    title: 'Winning ticket edited!',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                  })
                vm.getTickets();
                vm.setState({
                    modal: false,
                    rewardName: '',
                    rewardLink: '',
                    rewardButtonColor:'',
                    rewardButtonTextColor:"",
                    rewardButtonText:"",
                    rewardDescription: RichTextEditor.createEmptyValue(),
                    vendorName: "",
                    editTicketPictureUnderScratcher: '',
                    editTicketEmailImage: '',
                    expiresAtHours: null,
                    expiresAtDateTime: null,
                    uniqueOutsideCampaignId: '',
                    editTicketId: '',
                    revealImagePreview: {},
                    emailImagePreview: {},
                    losingTicket: false,
                    editingTicket: false,
                    isRedeemable: false
                })
              } else {
                this.setState({loading:false})
                swal({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in',
                    type: 'error',
                    confirmButtonText: 'Ok'
                  })
              }
            }).catch(err => {
              this.setState({loading:false})
              swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
            })
        }
    }

    editTicket(event){
      event.preventDefault();
      var array = this.state.winningTicketList;
      var ticket = false;
      var targetTicketId = event.currentTarget.getAttribute('value');
      for(var item in array){
        if(parseInt(array[item].id, 10) === parseInt(targetTicketId, 10)){
          ticket = array[item]
        }
      }
      console.log(ticket)
      if(!ticket){
        swal({
            title: 'There was some error!',
            text: 'Try again and if the problem persists try logging out and logging back in',
            type: 'error',
            confirmButtonText: 'Ok'
          })
      }
      let advanced = false;
      if(ticket.uniqueOutsideCampaignId || ticket.expiresAtHours || ticket.expiresAtDateTime){
          advanced = true
      }
      // let startTimeRemake;
      //   if(ticket.expiresAtDateTime){
      //       if(ticket.expiresAtDateTime.indexOf("Z")===-1){
      //           startTimeRemake = ticket.expiresAtDateTime + "Z"
      //       } else {
      //           startTimeRemake= ticket.expiresAtDateTime
      //       }
      //       startTimeRemake = new Date(new Date(startTimeRemake).getTime());
      //       const localTime = new Date(startTimeRemake).getTime();
      //       const formattedTimeSplit = new Date(localTime).toString().split(" ");
      //       formattedTimeSplit[4] = toStandardTime(formattedTimeSplit[4])
      //       startTimeRemake = formattedTimeSplit.join(" ")
      //       startTimeRemake = this.cleanDate(startTimeRemake)
      //   }
      this.setState({
            modal: true,
            rewardName: ticket.rewardName,
            uniqueOutsideCampaignId: ticket.uniqueOutsideCampaignId,
            isRedeemable: ticket.isRedeemable,
            rewardLink: ticket.rewardLink,
            rewardButtonColor:ticket.rewardButtonColor || "#fc2e2e",
            rewardButtonTextColor:ticket.rewardButtonTextColor || "#ffffff",
            rewardButtonText:ticket.rewardButtonText || (ticket.isRedeemable ? "Store Redeem" : "PRIZE LINK"),
            rewardDescription: RichTextEditor.createValueFromString(ticket.description, 'html'),
            vendorName: ticket.vendorName || "",
            editTicketPictureUnderScratcher: ticket.pictureUnderScratcher,
            editTicketEmailImage: ticket.emailImage,
            expiresAtHours: ticket.expiresAtHours,
            expiresAtDateTime: ticket.expiresAtDateTime,
            losingTicket: !ticket.isWinning,
            editTicketId: ticket.id,
            advanced: advanced,
            editingTicket: true
      });
    }

    deleteTicket(e){
      e.preventDefault();
      var deleteTicketObject = {}
      var rewardId = e.currentTarget.getAttribute('value');
      var domainRoot = this.Auth.domain;
      swal({
          title: 'Delete Ticket?',
          text: 'Are you sure you want to do this?  You will no longer be able to use this ticket in any new games',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
        }).then((result)=>{
          if(result.value){
            this.Auth.fetch(domainRoot + 'services/app/Reward/Template/Delete/'+ rewardId , {
              method: 'POST',
              headers: {
                 'Content-Type': 'application/json',
               },
              body: JSON.stringify(deleteTicketObject),
            }).then( res => {
              this.getTickets();
              if(res){
                swal({
                    title: 'Successful Deletion!',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                  })
              }
            }).catch(err => {
              swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
            })
          }
        })
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        losingTicket: false,
        editingTicket: false,
        advanced: false,
        rewardName: '',
        rewardLink: '',
        isRedeemable: false,
        rewardButtonColor:'',
        rewardButtonTextColor:"",
        rewardButtonText:"",
        rewardDescription: RichTextEditor.createEmptyValue(),
        vendorName: "",
        editTicketPictureUnderScratcher: '',
        uniqueOutsideCampaignId: '',
        expiresAtHours: null,
        expiresAtDateTime: null,
        editTicketEmailImage: '',
        revealImagePreview: {},
        emailImagePreview: {},
        editTicketId: ''
      });
    }

    toggleLosing() {
      this.setState({
        modal: !this.state.modal,
        losingTicket: true,
        editingTicket: false,
      });
    }

    render() {
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        const losingTicketList = this.state.losingTicketList;
        const winningTicketList = this.state.winningTicketList;
        const emailImagePreview = this.state.emailImagePreview;
        const revealImagePreview = this.state.revealImagePreview;
        const teamLocation = window.location.pathname.split('/')[1] || "";
        const tenantVariables = this.props.tenantVariables || {};
        let expiresAtDateTime;
        if(this.state.expiresAtDateTime){
            expiresAtDateTime = new Date(this.state.expiresAtDateTime)
        }
        const isMlbTeam = tenantVariables.isMlbTeam;
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu tenantVariables={this.props.tenantVariables}/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="card">
                <div className="card-body">
                  <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                        <li className="nav-item" onClick={()=> this.switchTicketsShowing()}>
                            <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showLosingButton">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-lg-block">Losing Card</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={()=> this.switchTicketsShowing()}>
                            <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showWinningButton">
                                <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                <span className="d-lg-block">Prizes</span>
                            </a>
                        </li>
                    </ul>
                    <div style={{display: !this.state.showWinning ? 'block' : 'none'}}>
                      <p className="admin-header-text" style={{marginBottom:0}}>Losing ticket</p>
                      <p className="admin-subheader-text">This is what fans will see when they scratch a losing ticket</p>
                      <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                        {
                          losingTicketList.map(function(item,i){
                            return <div key={i} className="card">
                                <div className="card-body" align="center">
                                <p>
                                  <img width='80%' height='auto' src={item.pictureUnderScratcher} alt=""/>
                                </p>
                                <button className="btn btn-primary btn-lg delete-button" style={{ marginTop: 5}} onClick={this.deleteTicket.bind(this)} value={item.id}><span className="fa fa-trash-o"/> Delete</button>
                              </div>
                              </div>
                          }, this)
                        }
                        </div>
                      <button className="btn btn-primary btn-lg create-prize-button" style={{display: !this.state.alreadyLosingTicket ? 'block' : 'none',fontSize:20,marginLeft:20,marginTop:10 }} onClick={this.toggleLosing}>Add Losing Ticket</button>
                    </div>
                    <div style={{display: this.state.showWinning ? 'block' : 'none'}}>
                      <p className="admin-header-text" style={{marginBottom:0}}>Prizes</p>
                      <p className="admin-subheader-text">These are prizes fans will reveal when they scratch a winning ticket</p>
                      <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginLeft:20}} onClick={this.toggle}>Add Prize</button>
                      <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                        {
                          winningTicketList.map(function(item,i){
                            return <div key={i} className="card">
                                <div className="card-body" align="center">
                                  <p style={{marginTop:5}}>{item.rewardName}</p>
                                  <p>
                                    <img width="80%" height="auto" src={item.pictureUnderScratcher} alt=""/>
                                  </p>
                                  <button className="btn btn-primary btn-lg edit-button" style={{ marginRight:5, marginBottom:10}} onClick={this.editTicket.bind(this)} value={item.id}><span className="fa fa-ellipsis-v"/> Edit</button>
                                  <button className="btn btn-primary btn-lg delete-button" style={{marginBottom:10}} onClick={this.deleteTicket.bind(this)} value={item.id}><span className="fa fa-trash-o"/> Delete</button>
                                </div>
                              </div>
                          }, this)
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
              <ModalHeader toggle={this.toggle} style={{display: !this.state.losingTicket ? 'block' : 'none' }}>Add Prize</ModalHeader>
              <ModalHeader toggle={this.toggle} style={{display: this.state.losingTicket ? 'block' : 'none' }}>Add Losing Ticket</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                        <div className="form-group" style={{display: !this.state.losingTicket ? '' : 'none' }}>
                          <label htmlFor="rewardName">Prize Name</label>
                          <input id="rewardName" name="rewardName" type="text" className="form-control" value={this.state.rewardName} onChange={this.handleChange} placeholder="My Sweet Prize"/>
                        </div>

                        {/*{!isMlbTeam &&*/}
                        {/*    <>*/}
                        {/*        <div className="form-group" style={{display: !this.state.losingTicket ? '' : 'none' }}>*/}
                        {/*            <label htmlFor="rewardLink">Prize Link Optional</label> <span style={{display: teamLocation === "nickdemo" ? "":"none"}}>OR <label htmlFor="showRewardsOutMessage"> Sqwad Redeem Process</label><input type="checkbox" checked={this.state.isRedeemable} id="isRedeemable" name="isRedeemable" onChange={this.handleChange}/></span>*/}
                        {/*            {!this.state.isRedeemable &&*/}
                        {/*                <input id="rewardLink" name="rewardLink" type="url" className="form-control" value={this.state.rewardLink} onChange={this.handleChange} placeholder="http://my_sweet_prize_link.com"/>*/}
                        {/*            }*/}
                        {/*        </div>*/}
                        {/*    </>*/}
                        {/*}*/}
                        <div className="form-group" style={{display: !this.state.losingTicket ? '' : 'none' }}>
                            {!isMlbTeam &&
                                <label htmlFor="rewardLink">Prize Link Optional</label>
                            }
                            <span style={{display: teamLocation === "nickdemo" || isMlbTeam || teamLocation === "fhsu" || teamLocation === "ccheckers" ? "":"none"}}>
                                {!isMlbTeam &&
                                    <span> OR</span>
                                }
                                <label htmlFor="showRewardsOutMessage">
                                    Sqwad Redeem Process
                                </label>
                                <input type="checkbox" checked={this.state.isRedeemable} id="isRedeemable" name="isRedeemable" onChange={this.handleChange}/>
                            </span>
                            {!this.state.isRedeemable && !isMlbTeam &&
                                <input id="rewardLink" name="rewardLink" type="url" className="form-control" value={this.state.rewardLink} onChange={this.handleChange} placeholder="http://my_sweet_prize_link.com"/>
                            }
                        </div>

                        <div className="form-group" style={{display: !this.state.losingTicket && (this.state.rewardLink || isMlbTeam || this.state.isRedeemable) ? '' : 'none' }}>
                            <label htmlFor="rewardButtonText">Button Text</label>
                            <input id="rewardButtonText" name="rewardButtonText" type="text" className="form-control" value={this.state.rewardButtonText} onChange={this.handleChange} placeholder={this.state.isRedeemable && !isMlbTeam?"Store Redeem":"GET MY PRIZE!"}/>
                        </div>
                        {!isMlbTeam &&
                            <>
                                <div className="form-inline" style={{display: !this.state.losingTicket && (this.state.rewardLink || this.state.isRedeemable) ? '' : 'none' }}>
                                    <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("rewardButtonColor")}/>
                                    <div style={{backgroundColor: this.state.rewardButtonColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                    <label htmlFor="rewardButtonColor" style={{marginRight: 10}}>Button Color</label>
                                    <input id="rewardButtonColor" name="rewardButtonColor" type="text" className="form-control" value={this.state.rewardButtonColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                </div>
                                <div className="form-inline" style={{display: !this.state.losingTicket && (this.state.rewardLink || this.state.isRedeemable) ? '' : 'none' }}>
                                    <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("rewardButtonTextColor")}/>
                                    <div style={{backgroundColor: this.state.rewardButtonTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                    <label htmlFor="rewardButtonTextColor" style={{marginRight: 10}}>Button Text Color</label>
                                    <input style={{marginRight:10}} id="rewardButtonTextColor" name="rewardButtonTextColor" type="text" className="form-control" value={this.state.rewardButtonTextColor} onChange={this.handleChange} placeholder="#000"/>
                                </div>
                            </>
                        }
                        {isMlbTeam &&
                            <div style={{display: !this.state.losingTicket ? '' : 'none' }}>
                                <div className="form-group">
                                    <label htmlFor="vendorName">Vendor Name</label>
                                    <input id="vendorName" name="vendorName" type="text" className="form-control" value={this.state.vendorName} onChange={this.handleChange} placeholder="Your sponsors name"/>
                                </div>
                            </div>
                        }
                        <div className="form-group" style={{display: !this.state.losingTicket ? '' : 'none' }}>
                            <label htmlFor="rewardDescription">{isMlbTeam? "Template Text" : "Prize Description"}: </label>
                            {isMlbTeam && <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This text should finish the phrase: You have an update from </p>}
                            <RichTextEditor toolbarClassName={isMlbTeam && "hide_toolbar_rich_text"} toolbarConfig={toolbarConfig} id="rewardDescription" name="rewardDescription" type="text" value={this.state.rewardDescription} onChange={this.onChangeDescription} placeholder={isMlbTeam?"your favorite team":"My Sweet Prize Description"}/>
                        </div>
                        {(isMlbTeam || process.env.REACT_APP_API_LINK === "https://dev-games.sqwadhq.com/api/" || teamLocation === "orlandosolarbears" || teamLocation === "nickdemo" || process.env.REACT_APP_API_LINK === "http://localhost:9284/api/") && !this.state.losingTicket &&
                            <>
                                <div className="form-group">
                                    <label htmlFor="showRewardsOutMessage">Advanced</label>
                                    <br/>
                                    <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                                </div>
                                <div style={{display: this.state.advanced && !isMlbTeam ? "" : "none"}}>
                                    <div className="form-group" style={{display: !this.state.losingTicket ? '' : 'none' }}>
                                        <label htmlFor="uniqueOutsideCampaignId">Unique Id For An Outside System</label>
                                        <input id="uniqueOutsideCampaignId" name="uniqueOutsideCampaignId" type="text" className="form-control" value={this.state.uniqueOutsideCampaignId} onChange={this.handleChange} placeholder="Unique Id"/>
                                    </div>
                                </div>
                                {this.state.advanced && isMlbTeam && !this.state.losingTicket &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="expiresAtHours">Hours Until Expire</label>
                                            <input id="expiresAtHours" name="expiresAtHours" step="1" type="number" className="form-control" value={this.state.expiresAtHours} onChange={this.handleChange} placeholder="720"/>
                                        </div>
                                        OR/AND
                                        <div className="form-group row mb-3 form_times">
                                            <label className="col-md-4 col-form-label" htmlFor="expiresAtDateTime">
                                                Date Until Expire (WARNING: messages will not send after this time has passed)
                                            </label>
                                            <div className="col-md-8">
                                                <div style={{cursor:"pointer", display: this.state.expiresAtDateTime?"":"none", textAlign:"left"}} onClick={()=>this.setState({expiresAtDateTime: null})}>
                                                    ❌
                                                </div>
                                                <DatePicker showTimeInput dateFormat="Pp" selected={expiresAtDateTime} onChange={date => this.setState({expiresAtDateTime: date})}/>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        }
                        <div className="form-group" align="center">
                          <label htmlFor="rewardRevealImage" style={{width:'100%'}}>Reveal Image</label>
                          <img src={this.state.editTicketPictureUnderScratcher} width="auto" height="100px" style={{display: this.state.editingTicket ? '' : 'none' }} alt=""/>
                            <Dropzone
                              className="dropzone dz-clickable"
                              accept="image/*"
                              onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'revealImage')}}
                              multiple={false}
                              maxSize={5242880}>
                              <div className="dz-message needsclick">
                                <span className="fa fa-cloud-upload text-muted" style={{display: revealImagePreview.preview ? 'none' : ''}}/>
                                <h3 style={{display: revealImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                                <img
                                  style={{display: revealImagePreview.preview ? '' : 'none'}}
                                  src={revealImagePreview.preview}
                                  width="100px"
                                  height="100auto"
                                  alt="Drop your image here"
                                />
                              </div>
                            </Dropzone>
                        </div>
                        <div className="form-group" align="center" style={{display: !this.state.losingTicket ? 'block' : 'none' }}>
                            <label htmlFor="rewardEmailImage" style={{width:'100%'}}>Prize {isMlbTeam ? "Message" : "Email"} Image</label>
                            <img src={this.state.editTicketEmailImage} width="auto" height="100px" style={{display: this.state.editingTicket ? '' : 'none' }} alt=""/>
                            <Dropzone
                                className="dropzone dz-clickable"
                                accept="image/*"
                                onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'emailImage')}}
                                multiple={false}
                                maxSize={5242880}>
                                <div className="dz-message needsclick">
                                    <span className="fa fa-cloud-upload text-muted" style={{display: emailImagePreview.preview ? 'none' : ''}}/>
                                    <h3 style={{display: emailImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                                    <img
                                        style={{display: emailImagePreview.preview ? '' : 'none'}}
                                        src={emailImagePreview.preview}
                                        width="100px"
                                        height="auto"
                                        alt="Drop your image here"
                                    />
                                </div>
                            </Dropzone>
                        </div>
                        <div className="form-group text-center">
                          <button className="btn btn-primary btn-lg submit-button" onClick={()=> this.createEditTicket()}>Submit</button>
                        </div>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
              <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
            </Modal>
         </div>
        );
    }
}

export default SetUpScratcherTickets
