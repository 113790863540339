import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { Prompt } from "react-router-dom";
import AuthService from '../utils/AuthService';
import RichTextEditor from 'html-verify-react-rte';
import { Modal } from 'reactstrap';
import '../../styles/css/AdminMain.css';
import { PhotoshopPicker } from 'react-color';
import swal from 'sweetalert2';

class SetUpTeamVariables extends Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
        let showGameText = this.getURLParameter("showGameText");
        let showMajorOne = true;
        let showGameTextOne = true;
        if(showGameText){
            showMajorOne = false;
            showGameTextOne = false;
        }
        this.state = {
            showTabOneGameText: showGameTextOne,
            showTabTwoGameText: !showGameTextOne,
            showTabOneMajor: showMajorOne,
            showTabTwoMajor: !showMajorOne,
            primaryColor: "",
            secondaryColor: "",
            topLeftImage: "",
            updateHappend: false,
            frontLogoImage: "",
            backgroundImage: "",
            scratcherImage: "",
            winningMessageImage: "",
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false,
            winningHeader: "",
            winningYouHeader: "",
            winningMessage: RichTextEditor.createEmptyValue(),
            losingMessage: RichTextEditor.createEmptyValue(),
            thankYouMessage: RichTextEditor.createEmptyValue(),
            losingHeader: "",
            thankYouHeader: "",
            noPrizesLeftHeader: "",
            noPrizesLeftBody:RichTextEditor.createEmptyValue(),
            buyTicketURL: "",
            pageTitle: "",
            loading:true
        };
        this.getTenantInfo = this.getTenantInfo.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      this.getTenantInfo()
    }

    getURLParameter(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
    }

    updateTenantTheme() {
      let formData = new FormData();
      const domainRoot = this.Auth.domain;
      /////////TO DO: MAKE SURE CORRECT NUMBER OF DIGITS
      if(this.state.primaryColor.indexOf('#') === -1 || this.state.secondaryColor.indexOf('#') === -1){
        swal({
            title: 'Hold on!',
            text: "Colors need to be in hex format!",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      formData.append('TopLeftImage', this.topLeftImage.files[0])
      formData.append('FrontLogoImage', this.frontLogoImage.files[0])
      formData.append('BackgroundImage', this.backgroundImage.files[0])
      formData.append('ScratcherImage', this.scratcherImage.files[0])
      formData.append('WinningMessageImage', this.winningMessageImage.files[0])
      formData.append('PrimaryColor', this.state.primaryColor)
      formData.append('SecondaryColor', this.state.secondaryColor)
      formData.append('WinningMessageText', this.state.winningMessage.toString('html'))
      formData.append('LosingMessageText', this.state.losingMessage.toString('html'))
      formData.append('winningHeader', this.state.winningHeader)
      formData.append('UnderThankYouMessage', this.state.thankYouMessage.toString('html'))
      formData.append('ThankYouMessage', this.state.thankYouHeader);
      formData.append('RewardsOutBody', this.state.noPrizesLeftBody.toString('html'));
      formData.append('RewardsOutHeader', this.state.noPrizesLeftHeader)
      formData.append('losingHeader', this.state.losingHeader)
      formData.append('buyTicketURL', this.state.buyTicketURL)
        formData.append('pageTitle', this.state.pageTitle)
      this.setState({loading:true})
      this.Auth.fetch(domainRoot + 'services/app/Tenant/UpdateTenantTheme', {
        method: 'POST',
        body: formData,
      }).then( res => {
        this.setState({loading:false})
        if(res){
          this.setState({
            updateHappend: false,
          })
          swal({
              title: 'Styles Updated',
              type: 'success',
              toast: true,
              position: 'top-end',
              showConfirmButton: true,
              timer: 10000
            })
          this.getTenantInfo()
        } else {
          swal({
              title: 'Uh oh!',
              text: 'There was some error! Check your connection and logout and log back in if the error persists',
              type: 'error',
              confirmButtonText: 'Ok'
            })
        }
      })
    }

    handleImageChange(e){
      this.setState({
        updateHappend: true,
        [e.target.name]: URL.createObjectURL(e.target.files[0])
      });
    }

    onChangeWinning = (winningMessage) => {
      this.setState({
        winningMessage,
        updateHappend: true,
      })
    }

    onChangeLosing = (losingMessage) => {
      this.setState({
        losingMessage,
        updateHappend: true,
      })
    }

    onChangeThankYou = (thankYouMessage) => {
      this.setState({
        thankYouMessage,
        updateHappend: true,
      })
    }

    onChangeNoPrizes = (noPrizesLeftBody) => {
        this.setState({
            noPrizesLeftBody,
            updateHappend: true,
        })
    }

    handleChange (evt) {
      this.setState({
        [evt.target.name]: evt.target.value,
        updateHappend: true
      });
    }

    openColorPicker(colorToChange){
      var oldColor = this.state[colorToChange];
      this.setState({
        colorPickerShow: true,
        colorToShow: oldColor,
        colorToUpdate: colorToChange
      })
    }

    handleChangeComplete = (color) => {
      this.setState({
        colorToShow: color.hex
      })
    };

    acceptPicker(){
      var colorToChange = this.state.colorToUpdate;
      this.setState({
        [colorToChange]: this.state.colorToShow,
        updateHappend: true,
        colorPickerShow: false,
      })
    }

    dismissPicker(){
      var colorToChange = this.state.colorToUpdate;
      this.setState({
        colorPickerShow: false,
      })
    }

    toggleTwoTabs(element, whichToggle){
        const tabOne = "showTabOne" + whichToggle;
        const tabTwo = "showTabTwo" + whichToggle;
        if(element === "first"){
            // document.getElementById('showsecond' + whichToggle).classList.remove('active');
            // document.getElementById('showfirst' + whichToggle).classList.add('active');
            this.setState({
                [tabOne]: true,
                [tabTwo]: false
            })
        }else {
            // document.getElementById('showfirst' + whichToggle).classList.remove('active');
            // document.getElementById('showsecond' + whichToggle).classList.add('active');
            this.setState({
                [tabOne]: false,
                [tabTwo]: true
            })
        }
    }

    getTenantInfo(){
      const domainRoot = this.Auth.domain;
      this.Auth.fetch(domainRoot + 'services/app/Tenant/Get', {
        method: 'GET',
      }).then(res => {
        if(!res){
          return;
        }
        if(res.apiStatus === "tenant_info"){
            const currentTenantVariables = res.entity;
            const isMlbTeam = currentTenantVariables && currentTenantVariables.isMlbTeam;
            const primaryColor = currentTenantVariables.primaryColor || "";
            let noPrizesLeftHeader = currentTenantVariables.rewardsOutHeader || "Out Of Prizes!";
            let noPrizesLeftBody = currentTenantVariables.rewardsOutBody || "Sorry, we’ve run out of prizes. Come back next time to try again!";
            let winningMessage = currentTenantVariables.winningMessageText;
            if(isMlbTeam && !winningMessage){
                winningMessage = "Check your MLB app inbox for your prize and thanks for playing!";
            } else if(!winningMessage) {
                winningMessage = "You've won some awesome prizes. Enter your email below & we'll send them your way!";
            }
          let losingMessage = currentTenantVariables.losingMessageText || "You didn't win anything but come back next game and try again!";
          let thankYouMessage = currentTenantVariables.underThankYouMessage || "Play again soon!";
          let thankYouHeader = currentTenantVariables.thankYouMessage || "Thank You!";
          let winningHeader = currentTenantVariables.winningHeader || "🎉 YOU'VE WON! 🎉";
          let losingHeader = currentTenantVariables.losingHeader || "😭 SORRY! 😭";
          let secondaryColor = currentTenantVariables.secondaryColor || "";
          let topLeftImage = currentTenantVariables.topLeftImage || "";
          let frontLogoImage = currentTenantVariables.frontLogoImage || "";
          let backgroundImage = currentTenantVariables.backgroundImage || "";
          let scratcherImage = currentTenantVariables.scratcherImage || "";
          let winningMessageImage = currentTenantVariables.winningMessageImage || "";
          let buyTicketURL = currentTenantVariables.buyTicketURL || "";
          let pageTitle = currentTenantVariables.pageTitle || "";
          winningMessage = RichTextEditor.createValueFromString(winningMessage, 'html');
          losingMessage = RichTextEditor.createValueFromString(losingMessage, 'html');
          thankYouMessage = RichTextEditor.createValueFromString(thankYouMessage, 'html');
          noPrizesLeftBody = RichTextEditor.createValueFromString(noPrizesLeftBody, 'html');
          this.setState({
            primaryColor: primaryColor,
            secondaryColor: secondaryColor,
            topLeftImage: topLeftImage,
            frontLogoImage: frontLogoImage,
            backgroundImage: backgroundImage,
            scratcherImage: scratcherImage,
            winningMessage: winningMessage,
            losingMessage: losingMessage,
            winningHeader: winningHeader,
            losingHeader: losingHeader,
            buyTicketURL: buyTicketURL,
            thankYouHeader: thankYouHeader,
            thankYouMessage: thankYouMessage,
            noPrizesLeftBody: noPrizesLeftBody,
            noPrizesLeftHeader: noPrizesLeftHeader,
            pageTitle: pageTitle,
            winningMessageImage: winningMessageImage,
            loading:false
          })
        }
      })
    }

    render() {
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        const losingMessage = this.state.losingMessage;
        const winningMessage = this.state.winningMessage;
        const thankYouMessage = this.state.thankYouMessage;
        const noPrizesLeftBody = this.state.noPrizesLeftBody;
        const isMlbTeam = this.props.tenantVariables && this.props.tenantVariables.isMlbTeam;
        let defaultWinningMessagePlaceholder = "You've won some awesome prizes. Enter your email below & we'll send them your way!"
        if(isMlbTeam){
            defaultWinningMessagePlaceholder = "Check your MLB app inbox for your prize and thanks for playing!";
        }
        if(!isMlbTeam){
            toolbarConfig.display.push('LINK_BUTTONS')
        }
        return (
          <div className="admin-wrapper">
            <Prompt
              when={this.state.updateHappend}
              message="Unsaved changes, are you sure you want to leave?"
              />
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu tenantVariables={this.props.tenantVariables}/>
            <TopMenu/>
            <div className="admin-main-panel">
                {/*
                <p style={{float:'right', color:'black', fontSize:'25px', textAlign:'center', marginRight: 20}}>Help 👇<br/><a href="#">Image sizes & specs PDF</a></p>
                */}
                <p className="admin-header-text" style={{marginBottom:0, marginTop:'5px'}}>Game Branding</p>
                <p className="admin-subheader-text">This is where you can edit the game branding on your scratchers activations</p>
                <div className="container-out">
                  <div className="admin-form-box" style={{marginTop:'0px', paddingTop:'5px'}}>
                    <button className="btn btn-primary btn-lg update-button" onClick={()=>this.updateTenantTheme()} style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                    <span style={{display: this.state.updateHappend ? 'none' : '', paddingLeft:"5px", color:"grey" }}>No Changes</span>
                    <span style={{display: this.state.updateHappend ? '' : 'none', paddingLeft:"5px", color:"red" }}>UNSAVED CHANGES</span>
                    <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                        <li className="nav-item" onClick={()=> this.toggleTwoTabs('first', 'Major')}>
                            <a data-toggle="tab" aria-expanded="false" className={this.state.showTabOneMajor ? "nav-link active" : "nav-link"} id="showfirstMajor">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-lg-block">Game Color/Images</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={()=> this.toggleTwoTabs('second', 'Major')}>
                            <a data-toggle="tab" aria-expanded="true" className={this.state.showTabTwoMajor ? "nav-link active" : "nav-link"} id="showsecondMajor">
                                <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                <span className="d-lg-block">Game Text</span>
                            </a>
                        </li>
                    </ul>
                    <div style={{display: this.state.showTabOneMajor ? 'block' : 'none'}}>
                      <div className="form-inline">
                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("primaryColor")}/>
                        <div style={{backgroundColor: this.state.primaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                        <label htmlFor="primaryColor" style={{marginRight: 10}}>Button Color</label>
                        <input id="primaryColor" name="primaryColor" type="text" className="form-control" value={this.state.primaryColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                        <div className="mobile-spacer"/>
                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("secondaryColor")}/>
                        <div style={{backgroundColor: this.state.secondaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                          <label htmlFor="secondaryColor" style={{marginRight: 10}}>Button Text Color</label>
                        <input style={{marginRight:10}} id="secondaryColor" name="secondaryColor" type="text" className="form-control" value={this.state.secondaryColor} onChange={this.handleChange} placeholder="#000"/>
                      </div>
                      <div className="admin-grid-container four-columns team-variables-image-container">
                        <div className="form-group" style={{textAlign:'center', margin:20}}>
                          <img src={this.state.backgroundImage} width="160" height="auto" alt=""/>
                          <br/>
                          <label htmlFor="backgroundImage">Background Image<br/>(750px X 1334px)</label>
                          <div className="form-group">
                            <input style={{display:'none'}} id="backgroundImage" name="backgroundImage" type="file" ref={input => {this.backgroundImage = input; }} onInput={e => this.handleImageChange(e)} onClick={(event)=> { event.target.value = null}}/>
                            <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('backgroundImage').click()} />
                          </div>
                        </div>
                        <div className="form-group" style={{textAlign:'center', margin:20}}>
                          <img src={this.state.frontLogoImage} width="150" height="auto" alt=""/>
                          <br/>
                          <label htmlFor="frontLogoImage">Front Logo<br/>(565px X 620px)</label>
                          <div className="form-group">
                            <input style={{display:'none'}} id="frontLogoImage" name="frontLogoImage" type="file" ref={input => {this.frontLogoImage = input; }} onInput={e => this.handleImageChange(e)} onClick={(event)=> { event.target.value = null}}/>
                            <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('frontLogoImage').click()} />
                          </div>
                        </div>
                        <div className="form-group" style={{float:'left', textAlign:'center', margin:20}}>
                          <img src={this.state.scratcherImage} width="140" height="auto" alt=""/>
                          <br/>
                          <label htmlFor="scratcherImage">Top Scratcher Image<br/>(280px X 380px)</label>
                          <div className="form-group">
                            <input style={{display:'none'}} id="scratcherImage" name="scratcherImage" type="file" ref={input => {this.scratcherImage = input; }} onInput={e => this.handleImageChange(e)} onClick={(event)=> { event.target.value = null}}/>
                            <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('scratcherImage').click()} />
                          </div>
                        </div>
                        <div className="form-group" style={{float:'left', textAlign:'center', margin:20}}>
                          <img src={this.state.topLeftImage} width="100" height="auto" alt=""/>
                          <br/>
                          <label htmlFor="topLeftImage">Team Logo<br/>(200px X 200px)</label>
                          <div className="form-group">
                            <input style={{display:'none'}} id="topLeftImage" name="topLeftImage" type="file" ref={input => {this.topLeftImage = input; }} onInput={e => this.handleImageChange(e)} onClick={(event)=> { event.target.value = null}}/>
                            <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('topLeftImage').click()} />
                          </div>
                        </div>
                      </div>
                        <div className="admin-grid-container four-columns team-variables-image-container" style={{display: !isMlbTeam && "none"}}>
                            <div className="form-group" style={{textAlign:'center', margin:20}}>
                                <img src={this.state.winningMessageImage} width="160" height="auto" alt=""/>
                                <br/>
                                <label htmlFor="winningMessageImage">Winning Message Image<br/>(200px X 200px)</label>
                                <div className="form-group">
                                    <input style={{display:'none'}} id="winningMessageImage" name="winningMessageImage" type="file" ref={input => {this.winningMessageImage = input; }} onInput={e => this.handleImageChange(e)} onClick={(event)=> { event.target.value = null}}/>
                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('winningMessageImage').click()} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display: this.state.showTabTwoMajor ? 'block' : 'none'}}>
                        <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{display: this.state.showTabTwoMajor ? '': 'none'}}>
                            <li className="nav-item" onClick={()=> this.toggleTwoTabs("first", "GameText")}>
                                <a href="#" aria-expanded="false" className={this.state.showTabOneGameText ? "nav-link active" : "nav-link"} id="showfirstGameText">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Winning/Losing Message</span>
                                </a>
                            </li>
                            <li className="nav-item" onClick={()=> this.toggleTwoTabs("second", "GameText")}>
                                <a href="#" aria-expanded="true" className={this.state.showTabTwoGameText ? "nav-link active" : "nav-link"} id="showsecondGameText">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Other Messages</span>
                                </a>
                            </li>
                        </ul>
                        <div style={{display: this.state.showTabOneGameText ? 'block' : 'none'}}>
                            <div className="form-group">
                                <label htmlFor="winningHeader">Winning Header:</label>
                                <input id="winningHeader" name="winningHeader" type="text" className="form-control" value={this.state.winningHeader} onChange={this.handleChange} placeholder="Winner!"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="winningMessage">Winning Message:</label>
                                <RichTextEditor toolbarConfig={toolbarConfig} id="winningMessage" name="winningMessage" type="text" value={winningMessage} onChange={this.onChangeWinning} placeholder={defaultWinningMessagePlaceholder}/>
                                <p style={{fontSize:'10px', margin:'5px', height:'50px'}}>
                                Renders text as: <span style={{fontSize:'20px', margin:'5px'}} dangerouslySetInnerHTML={{ __html:winningMessage.toString("html")}}/>
                                </p>
                            </div>
                            <div className="form-group">
                                <label htmlFor="losingHeader">Losing Header:</label>
                                <input id="losingHeader" name="losingHeader" type="text" className="form-control" value={this.state.losingHeader} onChange={this.handleChange} placeholder="Not A Winner!"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="losingMessage">Losing Message:</label>
                                <RichTextEditor toolbarConfig={toolbarConfig} id="losingMessage" name="losingMessage" type="text" value={losingMessage} onChange={this.onChangeLosing} placeholder="You didn't win... but try again and you might next time!"/>
                                <p style={{fontSize:'10px', margin:'5px'}}>
                                  Renders text as: <span style={{fontSize:'20px', margin:'5px'}} dangerouslySetInnerHTML={{ __html:losingMessage.toString("html")}}/>
                                </p>
                            </div>
                        </div>
                        <div style={{display: this.state.showTabTwoGameText ? 'block' : 'none'}}>
                            <div className="form-group">
                                <label htmlFor="pageTitle">Page Title:</label>
                                <input id="pageTitle" name="pageTitle" type="text" className="form-control" value={this.state.pageTitle} onChange={this.handleChange} placeholder="Scratch & Win"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="thankYouHeader">Thank You Main:</label>
                                <input id="thankYouHeader" name="thankYouHeader" type="text" className="form-control" value={this.state.thankYouHeader} onChange={this.handleChange} placeholder="Thank You!"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="thankYouBelow">Below Thank You:</label>
                                <RichTextEditor toolbarConfig={toolbarConfig} id="thankYouBelow" name="thankYouBelow" type="text" value={thankYouMessage} onChange={this.onChangeThankYou} placeholder="Come back and play again!"/>
                                <p style={{fontSize:'10px', margin:'5px', height:'50px'}}>
                                Renders text as: <span style={{fontSize:'20px', margin:'5px'}} dangerouslySetInnerHTML={{ __html:thankYouMessage.toString("html")}}/>
                                </p>
                            </div>
                            <div className="form-group">
                                <label htmlFor="noPrizesLeftHeader">No Prizes Left Header:</label>
                                <input id="noPrizesLeftHeader" name="noPrizesLeftHeader" type="text" className="form-control" value={this.state.noPrizesLeftHeader} onChange={this.handleChange} placeholder="No Prizes Left!"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="noPrizesLeftBody">No Prizes Left Body:</label>
                                <RichTextEditor toolbarConfig={toolbarConfig} id="noPrizesLeftBody" name="noPrizesLeftBody" type="text" value={noPrizesLeftBody} onChange={this.onChangeNoPrizes} placeholder="Come back and play again!"/>
                                <p style={{fontSize:'10px', margin:'5px', height:'50px'}}>
                                    Renders text as: <span style={{fontSize:'20px', margin:'5px'}} dangerouslySetInnerHTML={{ __html:noPrizesLeftBody.toString("html")}}/>
                                </p>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <Modal isOpen={this.state.colorPickerShow} toggle={() => this.state.colorPickerShow} id="anotherModal">
              <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
            </Modal>
         </div>
        );
    }
}

export default SetUpTeamVariables;
